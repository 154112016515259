import { Fragment, useEffect, useRef, useState } from "react"
import { Dialog, Transition } from "@headlessui/react"
import { Button } from "./Button";
import { DragDropTextArea } from "./DragDropTextArea";

type NewExampleModalProps = {
    open: boolean;
    onAddExample: (name: string, input_text: string, comment: string) => void;
    onClose: () => void;
}

export function NewExampleModal(props: NewExampleModalProps) {
    const { open, onAddExample, onClose } = props;

    const nameRef = useRef(null)

    const [name, setName] = useState<string>("");
    const [comment, setComment] = useState<string>("");
    const [input_text, setInputText] = useState<string>("");

    const is_valid = name.length > 0 && input_text.length > 0;

    const updateText = (text: string, file_name?: string) => {
        setInputText(text);
        if (file_name) {
            setName(file_name);
        }
    }

    const onSubmit = () => {
        onAddExample(name, input_text, comment)
        setName("");
        setInputText("");
        setComment("");
    };

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={nameRef} onClose={() => onClose()}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:p-6">
                                <div className="sm:flex sm:items-start">
                                    <div className="text-center sm:ml-4 sm:mt-0 sm:text-left w-full">
                                        <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                            New Template Example
                                        </Dialog.Title>
                                        <div className="mt-8 grid grid-cols-4 items-center w-full">
                                            <div className="text-sm text-gray-500">Name</div>
                                            <div className="col-span-3">
                                                <input type="text"
                                                    ref={nameRef}
                                                    className="border border-gray-300 rounded-md w-full px-3 py-2 text-gray-900"
                                                    value={name}
                                                    onChange={(e) => setName(e.target.value)} />
                                            </div>
                                        </div>
                                        <div className="mt-5 grid grid-cols-4 items-center w-full">
                                            <div className="text-sm text-gray-500">Comment</div>
                                            <div className="col-span-3">
                                                <input type="text"
                                                    className="border border-gray-300 rounded-md w-full px-3 py-2 text-gray-900"
                                                    value={comment}
                                                    onChange={(e) => setComment(e.target.value)} />
                                            </div>
                                        </div>
                                        <div className="mt-5 items-center w-full">
                                            <div className="col-span-3 mt-2">
                                                <DragDropTextArea
                                                    name="input_text"
                                                    rows={15}
                                                    text={input_text}
                                                    setText={updateText}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-8 sm:flex justify-center">
                                    <Button highlight={true} text="Add" disabled={!is_valid} onClick={onSubmit} />
                                    <Button text="Cancel" onClick={() => onClose()} />
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

type EditExampleModalProps = {
    open: boolean;
    init_comment: string;
    onUpdateExample: (comment: string) => void;
    onClose: () => void;
}

export function EditExampleModal(props: EditExampleModalProps) {
    const { open, init_comment, onUpdateExample, onClose } = props;

    const nameRef = useRef(null)

    const [comment, setComment] = useState<string>(init_comment);

    useEffect(() => {
        setComment(init_comment);
    }, [init_comment]);

    const onSubmit = () => {
        onUpdateExample(comment)
        setComment("");
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            onUpdateExample(comment);
        }
    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={nameRef} onClose={() => onClose()}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:p-6">
                                <div className="sm:flex sm:items-start">
                                    <div className="text-center sm:ml-4 sm:mt-0 sm:text-left w-full">
                                        <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                            Edit Template Example
                                        </Dialog.Title>
                                        <div className="mt-5 grid grid-cols-4 items-center w-full">
                                            <div className="text-sm text-gray-500">Comment</div>
                                            <div className="col-span-3">
                                                <input
                                                    type="text"
                                                    value={comment}
                                                    className="border border-gray-300 rounded-md w-full px-3 py-2 text-gray-900"
                                                    onKeyDown={handleKeyDown}
                                                    onChange={(e) => setComment(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-8 sm:flex justify-center">
                                    <Button highlight={true} text="Update" onClick={onSubmit} />
                                    <Button text="Cancel" onClick={() => onClose()} />
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
