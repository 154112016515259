import {
    Fragment,
    useEffect,
    useState
} from "react";
import { useSelector } from "react-redux";

import {
    useParams,
    useNavigate
} from "react-router-dom";

import CodeMirror from "@uiw/react-codemirror";
import { javascript } from "@codemirror/lang-javascript";

import { TbTablePlus } from "react-icons/tb";
import {
    ArrowTopRightOnSquareIcon,
    ChevronDownIcon,
    ChevronUpIcon,
    ClipboardDocumentIcon,
    DocumentTextIcon,
    RocketLaunchIcon
} from "@heroicons/react/24/outline";

import * as t from "../lib/types";
import * as c from "../lib/consts";
import * as ce from "../lib/backend/extractions.types.generated";
import {
    Backend,
    BackendObj,
    IContextCheckExistingExampleIn,
    IContextCheckNewExampleIn,
    IContextCommitExample,
    IContextUpdateNewExample
} from "../lib/backend";
import {
    selectEnv,
    selectIsSidebarLarge,
    selectMemberships,
    selectUser
} from "../lib/scraper.slice";
import {
    classNames,
    deepCopy,
    flattenScrapeDocuments,
    newUuid,
    prettySmartDateTime,
    setDocumentTitle,
    sleep,
    stringToScrapeDocuments
} from "../lib/utils";
import {
    IInitTemplate,
    DEV_EXAMPLE,
    WIZARD_CUSTOMS_DECLARATION,
    WIZARD_INVOICE,
    WIZARD_ORDER_CONFIRMATION,
    WIZARD_PURCHASE_ORDER,
    WIZARD_RESUME,
    WIZARD_RFQ,
    WIZARD_BANK_STATEMENT,
    WIZARD_SALES_QUOTATION,
    WIZARD_VENDOR_EVALUATION,
    WIZARD_SALES_REPORT,
    WIZARD_ANNUAL_REPORT,
    WIZARD_BILL_OF_LADING,
    WIZARD_DELIVERY_NOTE,
    WIZARD_SHIPPING_LABEL,
    WIZARD_BOM,
    WIZARD_QUALITY_CONTROL,
    WIZARD_WORK_ORDER,
    WIZARD_LONG_CONTRACT,
    WIZARD_SHORT_CONTRACT,
    WIZARD_DEMAND_FORECAST_REPORT
} from "../lib/wizard_templates";

import { Button, ButtonGroup } from "../components/Button";
import { CopyTextbox } from "../components/CopyTextbox";
import { FieldsTable } from "../components/FieldsTable";
import { LoadingSpinner } from "../components/LoadingSpinner";
import { OrgPill } from "../components/OrgPill";
import { TemplateFacts } from "../components/TemplateFacts";
import { Tabs } from "../components/Tabs";
import { Dropdown } from "../components/Dropdown";
import {
    FullScreen,
    FullScreenText
} from "../components/FullScreen";
import {
    WizardButtonIcon,
    WizardDocumentButtonImage
} from "../components/WizardButton";
import {
    EditExampleModal,
    NewExampleModal
} from "../components/NewExampleModal";
import { LongText } from "../components/LongText";
import { ConfirmModal } from "../components/ConfirmModal";
import {
    ExampleScrapeTable,
    ExampleDiffScrapeTable
} from "../components/ItemTables";
import { EvalMetrics } from "../components/Metrics";
import { HierarchicalFieldsTable } from "../components/HierarchicalFieldsTable";
import { IScrapeDocument } from "../lib/types";

export const DEFAULT_NEW_PARAMS:
    ce.IExtractParams &
    {
        // fields for template
        context_name: string;
        field: t.IContextField;
        facts: t.IContextFact[];
        postprocess: t.IContextPostprocess;
        output_type: ce.ContextOutputType;
    }
    = {
    context_name: "",
    field: { uuid: "", name: "", datatype: "string", type: "extract" },
    facts: [],
    postprocess: {},
    output_type: c.CONTEXT_OUTPUT_TYPES.array,

    prompt_output_format: "tsv",
    remove_duplicate_records: false,
    default_decimal_separator: c.DEFAULT_DECIMAL_SEPARATOR,
    detect_decimal_separator: true,
    try_auto_heal: false,
    extraction_strategy: "standard",
    preprocess_excel_strategy: "without_col_names",
    preprocess_ocr_strategy: "simple",
    max_partial_responses: 5,
    models_overrides: {},
    admin_prompts: {},
    orientation_segments_strategy: "only_main"
}

interface INewExample {
    name: string;
    input_documents: IScrapeDocument[];
    comment: string;
    records?: t.IRecord[];
    extraction_info: t.IExtractionInfo;
}

interface IExistingExample {
    item_uuid: string;
    name: string;
    input_documents: IScrapeDocument[];
    comment: string;
    new_records?: t.IRecord[];
    old_records?: t.IRecord[];
    diffs?: t.IExampleEvalDiff[];
    metrics?: t.IEvalMetrics;
    metrics_per_field?: { field_name: string, metrics: t.IEvalMetrics }[];
    is_modified: boolean; // internal parameters monitoring if we updated example in the session
    extraction_info: t.IExtractionInfo;
    created_at: number;
}

type NewTemplateProps = {
    init_template?: IInitTemplate;
}

function NewTemplate(props: NewTemplateProps) {
    const navigate = useNavigate();

    const is_sidebar_large = useSelector(selectIsSidebarLarge);
    const env = useSelector(selectEnv);
    const user = useSelector(selectUser);
    const memberships = useSelector(selectMemberships);

    // default is personal org, if not available, use first org
    const default_org_uuid =
        memberships.find((membership) => membership.org.type === c.ORG_TYPES.personal)?.org.uuid ||
        memberships[0].org.uuid ||
        "";
    // get list of admin or editor orgs, since only admin can create or edit templates
    const admin_orgs = memberships.filter((m) => (m.role === c.ORG_ROLES.admin || m.role === c.ORG_ROLES.editor)).map((m) => m.org);
    // check if we have a non-personal org, to make it worthwhile to show org selector
    const is_business_orgs = admin_orgs.some((org) => org.type === c.ORG_TYPES.business);

    // check if user is admin
    const is_admin = user.role === c.USER_ROLES.admin;

    // parse parameters from URL and props
    const { context_uuid } = useParams<{ context_uuid: string | undefined }>();
    const { init_template } = props;

    const [is_init, setIsInit] = useState<boolean>(true);
    const [selected_tab_key, setSelectedTab] = useState<string>("basic_info");
    const [is_edit_context_valid, setIsEditContextValid] = useState<boolean | undefined>(undefined);
    const [org_uuid, setOrgUuid] = useState<string>(default_org_uuid);
    const [context_name, setContextName] = useState<string>("");
    const [fields, setFields] = useState<t.IContextField[]>([{ ...deepCopy(DEFAULT_NEW_PARAMS.field), uuid: newUuid() }]);
    const [facts, setFacts] = useState<t.IContextFact[]>([]);
    const [postprocess, setPostprocess] = useState<t.IContextPostprocess>({});
    const [output_type, setOutputType] = useState<ce.ContextOutputType>(DEFAULT_NEW_PARAMS.output_type);
    const [prompt_output_format, setPromptOutputFormat] = useState<"json" | "tsv" | "hierarchical">(DEFAULT_NEW_PARAMS.prompt_output_format);
    const [remove_duplicate_records, setRemoveDuplicateRecords] = useState<boolean>(false);
    const [try_auto_heal, setTryAutoHeal] = useState<boolean>(false);
    const [default_decimal_separator, setDefaultDecimalSeparator] = useState<"," | ".">(DEFAULT_NEW_PARAMS.default_decimal_separator);
    const [detect_decimal_separator, setDetectDecimalSeparator] = useState<boolean>(DEFAULT_NEW_PARAMS.detect_decimal_separator);
    const [extraction_strategy, setExtractionStrategy] = useState<"standard" | "prepend_header_page">(DEFAULT_NEW_PARAMS.extraction_strategy);
    const [preprocess_excel_strategy, setPreprocessExcelStrategy] = useState<t.IPreprocessExcelStrategies>(DEFAULT_NEW_PARAMS.preprocess_excel_strategy);
    const [preprocess_ocr_strategy, setPreprocessOcrStrategy] = useState<t.IPreprocessOcrStrategies>(DEFAULT_NEW_PARAMS.preprocess_ocr_strategy);
    const [orientation_segments_strategy, setOrientationSegmentsStrategy] = useState<ce.IPreprocessOcrOrientationStrategies>(DEFAULT_NEW_PARAMS.orientation_segments_strategy);
    const [max_partial_responses, setMaxPartialResponses] = useState<number>(DEFAULT_NEW_PARAMS.max_partial_responses);
    const [models_overrides, setModelsOverrides] = useState<ce.IOverrideModelNames>({});
    const [admin_prompts, setAdminPrompts] = useState<t.IAdminPrompts>({});
    const [enable_admin_after_scrape_system, setEnableAdminAfterScrapeSystem] = useState<boolean>(false);
    const [enable_admin_after_scrape_user, setEnableAdminAfterScrapeUser] = useState<boolean>(false);
    const [enable_admin_after_partial_scrape_system, setEnableAdminAfterPartialScrapeSystem] = useState<boolean>(false);
    const [enable_admin_after_partial_scrape_user, setEnableAdminAfterPartialScrapeUser] = useState<boolean>(false);
    const [is_new_example_open, setIsNewExampleOpen] = useState<boolean>(false);
    const [is_edit_example_open, setIsEditExampleOpen] = useState<number>(-1);
    const [is_delete_example_open, setIsDeleteExampleOpen] = useState<number>(-1);
    const [new_examples, setNewExamples] = useState<INewExample[]>([]);
    const [existing_examples, setExistingExamples] = useState<IExistingExample[]>([]);
    const [is_existing_example_deleted, setIsExistingExampleDeleted] = useState<boolean>(false);
    const [eval_metrics, setEvalMetrics] = useState<t.IEvalMetrics | undefined>(undefined);
    const [eval_metrics_per_field, setEvalMetricsPerField] = useState<{ field_name: string, metrics: t.IEvalMetrics }[] | undefined>(undefined);
    const [full_screen_text, setFullScreenText] = useState<string | undefined>(undefined);
    const [context_email_address, setContextEmailAddress] = useState<string>("");
    const [is_processing, setIsProcessing] = useState<boolean>(false);
    const [is_committing, setIsCommitting] = useState<boolean>(false);
    const [save_error_message, setSaveErrorMessage] = useState<string | undefined>(undefined);
    const [check_error_message, setCheckErrorMessage] = useState<string | undefined>(undefined);
    const [lookup_tables, setLookupTables] = useState<t.ILookupTableBase[]>([]);
    const [models, setModels] = useState<t.IModel[]>([]);
    const [show_admin_details, setShowAdminDetails] = useState<boolean>(false);

    const tabs = [
        { name: "Template", key: "basic_info" },
        { name: "Postprocess", key: "postprocess", only_admins: true },
        { name: "Details", key: "details" },
        { name: "Evaluate", key: "examples" }
    ];

    useEffect(() => {
        if (context_uuid !== undefined) {
            Backend.getContext({ context_uuid }).then((context) => {
                if (context === undefined) {
                    setIsEditContextValid(false);
                } else {
                    setIsEditContextValid(true);
                    setContextName(context.name);
                    setOrgUuid(context.org_uuid);
                    setFacts(context.facts);
                    setFields(context.fields);
                    setPostprocess(context.postprocess);
                    setOutputType(`${context.output_type}`);
                    setPromptOutputFormat(context.extract_params.prompt_output_format);
                    setRemoveDuplicateRecords(context.extract_params.remove_duplicate_records);
                    setDefaultDecimalSeparator(context.extract_params.default_decimal_separator);
                    setDetectDecimalSeparator(context.extract_params.detect_decimal_separator);
                    setExtractionStrategy(context.extract_params.extraction_strategy);
                    setTryAutoHeal(context.extract_params.try_auto_heal);
                    setPreprocessExcelStrategy(context.extract_params.preprocess_excel_strategy);
                    setPreprocessOcrStrategy(context.extract_params.preprocess_ocr_strategy);
                    setOrientationSegmentsStrategy(context.extract_params.orientation_segments_strategy);
                    setMaxPartialResponses(context.extract_params.max_partial_responses);
                    setModelsOverrides(context.extract_params.models_overrides);
                    setAdminPrompts(context.extract_params.admin_prompts);
                    setEnableAdminAfterScrapeSystem(context.extract_params.admin_prompts.admin_after_scrape_system !== undefined);
                    setEnableAdminAfterScrapeUser(context.extract_params.admin_prompts.admin_after_scrape_user !== undefined);
                    setEnableAdminAfterPartialScrapeSystem(context.extract_params.admin_prompts.admin_after_partial_scrape_system !== undefined);
                    setEnableAdminAfterPartialScrapeUser(context.extract_params.admin_prompts.admin_after_partial_scrape_user !== undefined);
                    setNewExamples([]);
                    setExistingExamples(context.examples.map((example): IExistingExample => ({
                        item_uuid: example.item.uuid,
                        name: example.item.name,
                        input_documents: example.item.documents,
                        comment: example.comment,
                        new_records: example.records,
                        is_modified: false,
                        extraction_info: example.extraction_info,
                        created_at: example.item.created_at
                    })));
                    setContextEmailAddress(context.email_address);
                }
            });
        } else {
            setIsEditContextValid(false);
            setContextName(DEFAULT_NEW_PARAMS.context_name);
            setOrgUuid(default_org_uuid);
            setFacts(deepCopy(DEFAULT_NEW_PARAMS.facts));
            setFields([{ ...deepCopy(DEFAULT_NEW_PARAMS.field), uuid: newUuid() }]);
            setPostprocess(DEFAULT_NEW_PARAMS.postprocess);
            setOutputType(DEFAULT_NEW_PARAMS.output_type);
            setPromptOutputFormat(DEFAULT_NEW_PARAMS.prompt_output_format);
            setRemoveDuplicateRecords(DEFAULT_NEW_PARAMS.remove_duplicate_records);
            setTryAutoHeal(DEFAULT_NEW_PARAMS.try_auto_heal);
            setDefaultDecimalSeparator(DEFAULT_NEW_PARAMS.default_decimal_separator);
            setDetectDecimalSeparator(DEFAULT_NEW_PARAMS.detect_decimal_separator);
            setExtractionStrategy(DEFAULT_NEW_PARAMS.extraction_strategy);
            setMaxPartialResponses(DEFAULT_NEW_PARAMS.max_partial_responses);
            setModelsOverrides(DEFAULT_NEW_PARAMS.models_overrides);
            setAdminPrompts(DEFAULT_NEW_PARAMS.admin_prompts);
            setNewExamples([]);
            setExistingExamples([]);
        }
    }, [context_uuid, default_org_uuid]);

    useEffect(() => {
        if (context_uuid === undefined && init_template !== undefined && is_init) {
            setContextName(deepCopy(init_template.context_name));
            setFields(deepCopy(init_template.fields));
            setOutputType(deepCopy(init_template.output_type ?? DEFAULT_NEW_PARAMS.output_type));
            setNewExamples(deepCopy(init_template.examples ?? []));
            setExistingExamples([]);
        }
    }, [context_uuid, init_template, is_init]);

    useEffect(() => {
        if (context_uuid === undefined) {
            setDocumentTitle("New Template", env);
        } else if (is_edit_context_valid) {
            setDocumentTitle(`Edit Template - ${context_name}`, env);
        }
    }, [context_uuid, context_name, is_edit_context_valid, env]);

    useEffect(() => {
        Backend.getLookupTables().then((tables) => {
            setLookupTables(tables);
        });

    }, [org_uuid]);

    useEffect(() => {
        BackendObj.extractions.getModels({})
            .then(({ models }) => {
                setModels(models);
            });
    }, []);

    const is_edit = context_uuid !== undefined;

    const trimFields = () => {
        const new_fields = fields.filter((field) => field.name.length > 0);
        let is_modified = false;
        for (const field of new_fields) {
            // trim field name
            const field_name = field.name.trim();
            is_modified = is_modified || field.name !== field_name;
            field.name = field_name;
            // trim format if we have it
            if (field.extract && field.extract.format) {
                const field_format = field.extract.format.trim();
                is_modified = is_modified || field.extract.format !== field_format;
                field.extract.format = field_format;
            }
        }
        if (is_modified) {
            setIsInit(false);
        }
        setFields(new_fields);
        return new_fields;
    };

    const onCheck = async () => {
        setCheckErrorMessage(undefined);
        setIsProcessing(true);
        try {
            const job_uuid = await Backend.evaluateContextStart({
                context_name,
                facts,
                fields: trimFields(),
                postprocess,
                extract_params: {
                    prompt_output_format,
                    remove_duplicate_records,
                    default_decimal_separator,
                    detect_decimal_separator,
                    extraction_strategy,
                    try_auto_heal,
                    preprocess_excel_strategy,
                    preprocess_ocr_strategy,
                    orientation_segments_strategy,
                    max_partial_responses,
                    models_overrides,
                    admin_prompts
                },
                output_type,
                new_examples: new_examples.map((example): IContextCheckNewExampleIn => ({ name: example.name, input_documents: example.input_documents })),
                existing_examples: existing_examples.map((example) => ({ item_uuid: example.item_uuid })),
                org_uuid
            });
            while (true) {
                const check_result = await Backend.evaluateContextGet({ job_uuid });
                if (check_result.status === "done") {
                    // only remember metrics if we had at least one existing example
                    if (existing_examples.length > 0) {
                        setEvalMetrics(check_result.metrics);
                        setEvalMetricsPerField(check_result.metrics_per_field);
                    } else {
                        setEvalMetrics(undefined);
                        setEvalMetricsPerField(undefined);
                    }
                    // add results to new examples
                    if (check_result.new_examples) {
                        const check_new_examples = [...new_examples];
                        for (let i = 0; i < new_examples.length; i++) {
                            check_new_examples[i].records = check_result.new_examples[i].records;
                        }
                        setNewExamples(check_new_examples);
                    }
                    // add results to existing examples
                    if (check_result.existing_examples) {
                        const check_existing_examples = [...existing_examples];
                        for (let i = 0; i < existing_examples.length; i++) {
                            check_existing_examples[i].old_records = check_result.existing_examples[i].old_records;
                            check_existing_examples[i].new_records = check_result.existing_examples[i].new_records;
                            check_existing_examples[i].diffs = check_result.existing_examples[i].diffs;
                            check_existing_examples[i].metrics = check_result.existing_examples[i].metrics;
                            check_existing_examples[i].metrics_per_field = check_result.existing_examples[i].metrics_per_field;
                            check_existing_examples[i].is_modified = true;
                        }
                        setExistingExamples(check_existing_examples);
                    }
                    setIsProcessing(false);
                    break;
                } else if (check_result.status === "error") {
                    setCheckErrorMessage(check_result.message);
                    setIsProcessing(false);
                    break;
                }
                await sleep(1000);
            }
        } catch (err: any) {
            setCheckErrorMessage(`Failed to check template.`);
            setIsProcessing(false);
        }
    };

    const onCommit = async () => {
        setSaveErrorMessage(undefined);
        // avoid double commit
        if (is_committing) { return; }
        setIsCommitting(true);

        try {
            // create template
            const commit_result = await Backend.commitContext({
                context_name,
                org_uuid,
                facts,
                fields: trimFields(),
                postprocess,
                extract_params: {
                    prompt_output_format,
                    remove_duplicate_records,
                    default_decimal_separator,
                    detect_decimal_separator,
                    extraction_strategy,
                    try_auto_heal,
                    preprocess_excel_strategy,
                    preprocess_ocr_strategy,
                    orientation_segments_strategy,
                    max_partial_responses,
                    models_overrides,
                    admin_prompts
                },
                output_type,
                examples: new_examples.map((example): IContextCommitExample => ({
                    name: example.name,
                    input_documents: example.input_documents,
                    comment: example.comment,
                    records: example.records,
                    extraction_info: example.extraction_info
                })),
            });
            // redirect to edit page for the new context
            setIsInit(true);
            setIsCommitting(false);
            setIsEditContextValid(true);
            navigate(`/template/${commit_result.uuid}/edit`);
        } catch (err: any) {
            setSaveErrorMessage(`Failed to save template.`);
            setIsCommitting(false);
        }
    };

    const onUpdate = async () => {
        setSaveErrorMessage(undefined);
        // make sure we have valid context uuid
        if (context_uuid === undefined) { return; }
        // avoid double commit
        if (is_committing) { return; }
        setIsCommitting(true);
        try {
            await Backend.updateContext({
                context_uuid,
                context_name,
                org_uuid,
                facts,
                fields: trimFields(),
                postprocess,
                extract_params: {
                    prompt_output_format,
                    remove_duplicate_records,
                    default_decimal_separator,
                    detect_decimal_separator,
                    extraction_strategy,
                    try_auto_heal,
                    preprocess_excel_strategy,
                    preprocess_ocr_strategy,
                    orientation_segments_strategy,
                    max_partial_responses,
                    models_overrides,
                    admin_prompts
                },
                output_type
            });
            // mark done
            setIsCommitting(false);
            setIsInit(true);
        } catch (err: any) {
            setSaveErrorMessage(`Failed to update template.`);
            setIsCommitting(false);
        }
    };

    const onUpdateExamples = async () => {
        setSaveErrorMessage(undefined);
        // make sure we have valid context uuid
        if (context_uuid === undefined) { return; }
        // avoid double commit
        if (is_committing) { return; }
        setIsCommitting(true);
        try {
            const examples = await Backend.updateContextAllExamples({
                context_uuid,
                new_examples: new_examples.map((example): IContextUpdateNewExample => ({
                    name: example.name,
                    input_documents: example.input_documents,
                    comment: example.comment,
                    records: example.records,
                    extraction_info: example.extraction_info
                })),
                existing_examples: existing_examples.map((example) => ({
                    item_uuid: example.item_uuid,
                    comment: example.comment,
                    records: example.new_records,
                    extraction_info: example.extraction_info
                }))
            });
            // move new examples to existing examples
            // we update all, overwrite existing examples
            setExistingExamples(examples.map((example): IExistingExample => ({
                item_uuid: example.item.uuid,
                name: example.item.name,
                input_documents: example.item.documents,
                comment: example.comment,
                new_records: example.records,
                is_modified: false,
                extraction_info: example.extraction_info,
                created_at: example.item.created_at
            })));
            setIsExistingExampleDeleted(false);
            setNewExamples([]);
            // mark done
            setIsCommitting(false);
        } catch (err: any) {
            setSaveErrorMessage(`Failed to update template.`);
            setIsCommitting(false);
        }
    }

    const handleMaxPartialResponseChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsInit(false);
        const int_value = parseInt(e.target.value, 10);
        // hardcoded upper limit
        if (!isNaN(int_value) && 0 < int_value && int_value <= 20) {
            setMaxPartialResponses(int_value);
        } else {
            setMaxPartialResponses(DEFAULT_NEW_PARAMS.max_partial_responses);
        }
    };

    const handleModelNameChange = (model_name: string, field_name: "default" | "default_fast" | "scrape_extract" | "scrape_heal" | "scrape_summarize" | "scrape_focused_summarize" | "decimal_separator") => {
        setIsInit(false);
        setModelsOverrides((prev) => {
            return { ...prev, [field_name]: (model_name === "/") ? undefined : model_name };
        });
    };

    const handleEnableAdminPromptChange = (e: React.ChangeEvent<HTMLInputElement>, prompt_type: "admin_after_scrape_system" | "admin_after_scrape_user" | "admin_after_partial_scrape_system" | "admin_after_partial_scrape_user") => {
        setIsInit(false);
        // if we disable, clear the prompt
        if (!e.target.checked) {
            setAdminPrompts({ ...admin_prompts, [prompt_type]: undefined });
        } else {
            setAdminPrompts({ ...admin_prompts, [prompt_type]: "" });
        }
        if (prompt_type === "admin_after_scrape_system") {
            setEnableAdminAfterScrapeSystem(e.target.checked)
        } else if (prompt_type === "admin_after_scrape_user") {
            setEnableAdminAfterScrapeUser(e.target.checked)
        } else if (prompt_type === "admin_after_partial_scrape_system") {
            setEnableAdminAfterPartialScrapeSystem(e.target.checked)
        } else if (prompt_type === "admin_after_partial_scrape_user") {
            setEnableAdminAfterPartialScrapeUser(e.target.checked)
        }
    }

    const handleNewExample = (name: string, input_text: string, comment: string) => {
        const input_documents = stringToScrapeDocuments(input_text);
        setNewExamples([{ name, input_documents, comment, extraction_info: {} }, ...new_examples]);
        setIsNewExampleOpen(false);
    }

    const getExampleComment = (): string => {
        if (is_edit_example_open < 0) {
            return "";
        } else if (is_edit_example_open < new_examples.length) {
            return new_examples[is_edit_example_open].comment;
        } else if ((is_edit_example_open - new_examples.length) < existing_examples.length) {
            return existing_examples[is_edit_example_open - new_examples.length].comment;
        } else {
            console.log("Example index to large", new_examples.length, existing_examples.length, is_edit_example_open);
            return "";
        }
    }

    const handleTestSingleExample = async (example_idx: number) => {
        const test_new_example: IContextCheckNewExampleIn[] = [];
        const test_existing_example: IContextCheckExistingExampleIn[] = [];
        if (0 <= example_idx && example_idx < new_examples.length) {
            // single test of new example
            test_new_example.push({
                name: new_examples[example_idx].name,
                input_documents: new_examples[example_idx].input_documents
            });
        } else if ((example_idx - new_examples.length) < existing_examples.length) {
            // single test of existing example
            test_existing_example.push({
                item_uuid: existing_examples[example_idx - new_examples.length].item_uuid
            });
        } else {
            console.log("Example index to large", new_examples.length, existing_examples.length, example_idx);
        }
        // if we have examples to test, do it
        if (new_examples.length > 0 || existing_examples.length > 0) {
            setCheckErrorMessage(undefined);
            setIsProcessing(true);
            try {
                const job_uuid = await Backend.evaluateContextStart({
                    context_name,
                    facts,
                    fields: trimFields(),
                    postprocess,
                    extract_params: {
                        prompt_output_format,
                        remove_duplicate_records,
                        default_decimal_separator,
                        detect_decimal_separator,
                        extraction_strategy,
                        try_auto_heal,
                        preprocess_excel_strategy,
                        preprocess_ocr_strategy,
                        orientation_segments_strategy,
                        max_partial_responses,
                        models_overrides,
                        admin_prompts
                    },
                    output_type,
                    new_examples: test_new_example,
                    existing_examples: test_existing_example,
                    org_uuid
                });
                while (true) {
                    const check_result = await Backend.evaluateContextGet({ job_uuid });
                    if (check_result.status === "done") {
                        // overall metrics no longer valid
                        setEvalMetrics(undefined);
                        setEvalMetricsPerField(undefined);
                        // update example with results
                        if (0 <= example_idx && example_idx < new_examples.length) {
                            if (check_result.new_examples && check_result.new_examples.length > 0) {
                                const check_new_examples = [...new_examples];
                                check_new_examples[example_idx].records = check_result.new_examples[0].records;
                                check_new_examples[example_idx].extraction_info = check_result.new_examples[0].extraction_info;
                                setNewExamples(check_new_examples);
                            }
                        } else if ((example_idx - new_examples.length) < existing_examples.length) {
                            if (check_result.existing_examples && check_result.existing_examples.length > 0) {
                                const check_existing_examples = [...existing_examples];
                                check_existing_examples[example_idx - new_examples.length].old_records = check_result.existing_examples[0].old_records;
                                check_existing_examples[example_idx - new_examples.length].new_records = check_result.existing_examples[0].new_records;
                                check_existing_examples[example_idx - new_examples.length].diffs = check_result.existing_examples[0].diffs;
                                check_existing_examples[example_idx - new_examples.length].metrics = check_result.existing_examples[0].metrics;
                                check_existing_examples[example_idx - new_examples.length].metrics_per_field = check_result.existing_examples[0].metrics_per_field;
                                check_existing_examples[example_idx - new_examples.length].is_modified = true;
                                check_existing_examples[example_idx - new_examples.length].extraction_info = check_result.existing_examples[0].extraction_info;
                                setExistingExamples(check_existing_examples);
                            }
                        }
                        setIsProcessing(false);
                        break;
                    } else if (check_result.status === "error") {
                        setCheckErrorMessage(check_result.message);
                        setIsProcessing(false);
                        break;
                    }
                    await sleep(1000);
                }
            } catch (err: any) {
                setCheckErrorMessage(`Failed to check example.`);
                setIsProcessing(false);
            }
        }
    }

    const handleSaveSingleNewExample = async (example_idx: number) => {
        setSaveErrorMessage(undefined);
        // make sure we have valid context uuid
        if (context_uuid === undefined) { return; }
        // avoid double commit
        if (is_committing) { return; }
        // if valid index, save example
        if (0 <= example_idx && example_idx < new_examples.length) {
            setIsCommitting(true);
            try {
                const examples = await Backend.updateContextAllExamples({
                    context_uuid,
                    new_examples: [{
                        name: new_examples[example_idx].name,
                        input_documents: new_examples[example_idx].input_documents,
                        comment: new_examples[example_idx].comment,
                        records: new_examples[example_idx].records,
                        extraction_info: new_examples[example_idx].extraction_info
                    }]
                });
                // move new example to the top of existing examples
                if (examples.length > 0) {
                    setNewExamples([
                        ...new_examples.slice(0, example_idx),
                        ...new_examples.slice(example_idx + 1)
                    ]);
                    setExistingExamples([
                        {
                            item_uuid: examples[0].item.uuid,
                            name: examples[0].item.name,
                            input_documents: examples[0].item.documents,
                            comment: examples[0].comment,
                            new_records: examples[0].records,
                            is_modified: false,
                            extraction_info: examples[0].extraction_info,
                            created_at: examples[0].item.created_at
                        },
                        ...existing_examples
                    ]);
                }
                setIsCommitting(false);
            } catch (err: any) {
                setSaveErrorMessage(`Failed to save example.`);
                setIsCommitting(false);
            }
        }
    }

    const handleSaveSingleExistingExample = async (example_idx: number) => {
        // make sure we have valid context uuid
        if (context_uuid === undefined) { return; }
        // avoid double commit
        if (is_committing) { return; }
        // if valid index, save example
        if (0 <= example_idx && example_idx < existing_examples.length) {
            setSaveErrorMessage(undefined);
            setIsCommitting(true);
            try {
                await Backend.updateContextExample({
                    context_uuid,
                    item_uuid: existing_examples[example_idx].item_uuid,
                    comment: existing_examples[example_idx].comment,
                    field_name_uuid_pairs: fields.map((field) => ({ name: field.name, uuid: field.uuid })),
                    records: existing_examples[example_idx].new_records,
                    extraction_info: existing_examples[example_idx].extraction_info
                });
                // mark done
                const _existing_examples = [...existing_examples];
                _existing_examples[example_idx].is_modified = false;
                setExistingExamples(_existing_examples);
                setIsCommitting(false);
            } catch (err: any) {
                setSaveErrorMessage(`Failed to update example.`);
                setIsCommitting(false);
            }
        }
    }

    const handleUpdateExampleComment = (comment: string) => {
        if (is_edit_example_open < 0) {
            // nothing to do
        } else if (is_edit_example_open < new_examples.length) {
            new_examples[is_edit_example_open].comment = comment;
            setNewExamples([...new_examples]);
        } else if ((is_edit_example_open - new_examples.length) < existing_examples.length) {
            existing_examples[is_edit_example_open - new_examples.length].comment = comment;
            existing_examples[is_edit_example_open - new_examples.length].is_modified = true;
            setExistingExamples([...existing_examples]);
        } else {
            console.log("Example index to large", new_examples.length, existing_examples.length, is_edit_example_open);
        }
        setIsEditExampleOpen(-1);
    }

    const handleDeleteExample = (result: boolean) => {
        if (result) {
            if (is_delete_example_open < 0) {
                // nothing to do
            } else if (is_delete_example_open < new_examples.length) {
                setNewExamples([
                    ...new_examples.slice(0, is_delete_example_open),
                    ...new_examples.slice(is_delete_example_open + 1)
                ]);
            } else if ((is_delete_example_open - new_examples.length) < existing_examples.length) {
                setExistingExamples([
                    ...existing_examples.slice(0, is_delete_example_open - new_examples.length),
                    ...existing_examples.slice(is_delete_example_open - new_examples.length + 1)
                ]);
                setIsExistingExampleDeleted(true);
            } else {
                console.log("Example index to large", new_examples.length, existing_examples.length, is_delete_example_open);
            }
        }
        // once we deleted example we must also remove metrics, since they are no longer relevant
        setEvalMetrics(undefined);
        setEvalMetricsPerField(undefined);
        // close confirmation dialog
        setIsDeleteExampleOpen(-1);
    }

    const handleCopyToClipboard = (text: string) => {
        navigator.clipboard.writeText(text);
    }

    // if we open in edit mode, check that we have valid context and that it is loaded
    if (is_edit) {
        if (is_edit_context_valid === undefined) {
            return <div className={classNames("hidden lg:fixed lg:right-0 lg:inset-y-0 lg:flex lg:flex-row", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
                <LoadingSpinner />
            </div>;
        }

        if (!is_edit_context_valid) {
            return <div className="px-6 py-4">
                <div className="py-10">
                    <h2 className="text-base font-semibold leading-7 text-gray-900">Invalid Template</h2>
                    <p className="mt-3 max-w-3xl text-sm leading-6 text-gray-600">
                        Template does not exist.
                    </p>
                </div>
            </div>;
        }
    }

    // helpers
    const is_context_name_invalid = context_name.length === 0;
    const is_fields_invalid = fields.length === 0 || fields.every((field) => field.name.length === 0);
    const model_names = models.map((model) => model.name);

    // prepare Save template tooltip:
    let save_tooltip: string | undefined = undefined;
    if (is_context_name_invalid) {
        save_tooltip = "Please provide a name for the template";
    } else if (fields.length === 0) {
        save_tooltip = "Please provide at least one field";
    } else if (fields.some((field) => field.name.length === 0)) {
        save_tooltip = "Please provide a name for each field";
    }

    const tooltip = (save_tooltip !== undefined) ? save_tooltip :
        (is_edit && !is_init) ? "Unsaved changes to template" :
            undefined;

    return <Fragment>
        <div className={classNames("z-50 h-16 bg-white border-b-gray-200 border-b lg:fixed lg:right-0", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
            <div className="px-10 py-4 lg:max-w-5xl">
                <div className="flex flex-row items-center">
                    <h2 className="text-xl font-semibold leading-7 text-gray-900 sm:truncate sm:text-2xl sm:tracking-tight">
                        {!is_edit && "Create a Template"}
                        {is_edit && "Edit Template"}
                    </h2>
                    <div className="h-8 flex items-end ml-6">
                        {tooltip !== undefined && <span className="text-sm text-gray-600">{tooltip}</span>}
                    </div>
                    <div className="flex-grow" />
                    {!is_edit && <Button text="Create"
                        highlight={true}
                        disabled={is_context_name_invalid || is_fields_invalid || is_committing}
                        loading={is_committing}
                        tooltip={save_tooltip}
                        onClick={onCommit} />}
                    {is_edit && is_init && <Button text="Extract" href={`/extraction/new/${context_uuid}`} />}
                    {is_edit && <Button text="Save Changes"
                        highlight={true}
                        disabled={is_context_name_invalid || is_fields_invalid || is_committing}
                        loading={is_committing}
                        tooltip={save_tooltip}
                        onClick={onUpdate} />}
                </div>
            </div>
        </div>

        <div className="px-8 py-6 max-w-5xl lg:pt-24">
            <Tabs tabs={tabs} selected_tab_key={selected_tab_key} setSelectedTab={setSelectedTab} />
        </div>

        {selected_tab_key === "basic_info" && <Fragment>
            <div className="px-10 max-w-5xl">
                {is_business_orgs && <div className="sm:grid sm:grid-cols-4 max-w-5xl sm:items-start sm:gap-4 py-6">
                    <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                        Organization
                    </label>
                    <div className="flex gap-2 mt-2 sm:col-span-3 sm:mt-0">
                        {admin_orgs.map((org, idx) => (<OrgPill key={idx} name={org.name} type={org.type} selected={org.uuid === org_uuid} onClick={() => setOrgUuid(org.uuid)} />))}
                    </div>
                </div>}

                <div className="sm:grid sm:grid-cols-4 max-w-5xl sm:items-start sm:gap-4 pb-6">
                    <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                        Name
                    </label>
                    <div className="mt-2 sm:col-span-3 sm:mt-0">
                        <div className="flex w-full max-w-3xl rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-sky-600">
                            <input
                                id="title"
                                name="title"
                                type="text"
                                placeholder="Please provide a name for the template"
                                className="block flex-1 border-0 bg-transparent p-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 text-sm sm:leading-6"
                                value={context_name}
                                onChange={(e) => { setIsInit(false); setContextName(e.target.value); }}
                            />
                        </div>
                    </div>
                </div>

                {is_edit && <div className="sm:grid sm:grid-cols-4 max-w-5xl sm:items-start sm:gap-4 pb-6">
                    <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                        Email connector
                    </label>
                    <div className="mt-2 sm:col-span-3 sm:mt-0 text-sky-600">
                        <CopyTextbox text={context_email_address} email_pretty_name={context_name} is_email={true} />
                    </div>
                </div>}
            </div>
            <div className="px-10">

                <div className="sm:gap-4 sm:pt- sm:pb-6">
                    <p className="mt-1 max-w-3xl text-sm leading-6 text-gray-600">
                        Define the columns you want to extract from the example and their formatting instructions.
                    </p>

                    <div className="mt-6 space-y-8 mb-12 sm:space-y-0 sm:pb-0 outer-div">
                        {[c.CONTEXT_OUTPUT_TYPES.array, c.CONTEXT_OUTPUT_TYPES.object].includes(output_type) && <FieldsTable
                            fields={fields}
                            disabled={is_processing || is_committing}
                            is_editable={true}
                            show_settings={true}
                            lookup_tables={lookup_tables}
                            setFields={(f) => { setIsInit(false); setFields(f); }} />}
                        {output_type === c.CONTEXT_OUTPUT_TYPES.hierarchical && <HierarchicalFieldsTable
                            fields={fields}
                            disabled={is_processing || is_committing || !is_admin}
                            lookup_tables={lookup_tables}
                            setFields={(f: any) => { setIsInit(false); setFields(f); }} />}
                    </div>
                </div>

                {save_error_message !== undefined &&
                    <div className="sm:grid sm:grid-cols-4 max-w-5xl sm:items-start">
                        <div />
                        <div className="mx-1 sm:col-span-3 max-w-3xl text-sm text-red-600">
                            Error: {save_error_message}
                        </div>
                    </div>}
            </div>
        </Fragment>}

        {selected_tab_key === "postprocess" && <div className="px-10 max-w-5xl">
            <div className="w-full shadow border">
                <CodeMirror
                    value={postprocess.formula || ""}
                    height="800px"
                    theme="light"
                    extensions={[javascript()]}
                    readOnly={!is_admin}
                    onChange={(value) => value !== "" ? setPostprocess({ formula: value }) : setPostprocess({})} />
            </div>
        </div>}

        {selected_tab_key === "details" && <form>
            <div className="px-10 max-w-5xl">
                <TemplateFacts facts={facts} setFacts={(facts) => { setIsInit(false); setFacts(facts); }} />

                <div className="sm:grid sm:grid-cols-4 max-w-5xl sm:items-start sm:gap-4 sm:py-6 border-t border-gray-900/10">
                    <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                        How many rows do you expect?
                    </label>
                    <div className="mt-2 sm:col-span-3 sm:mt-0">
                        <div className="flex w-full max-w-xs rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-sky-600">
                            <Dropdown
                                values={
                                    is_admin || (output_type === c.CONTEXT_OUTPUT_TYPES.hierarchical) ?
                                        ["Many", "One", "Hierarchical"] : ["Many", "One"]
                                }
                                ids={c.context_output_types}
                                selected={output_type}
                                disabled={is_processing || is_committing || (!is_admin && (output_type === c.CONTEXT_OUTPUT_TYPES.hierarchical))}
                                onChange={(id) => { setIsInit(false); setOutputType(id as ce.ContextOutputType); }} />
                        </div>
                    </div>

                    {output_type === c.CONTEXT_OUTPUT_TYPES.array && <Fragment>
                        <label htmlFor="remove_duplicate_records" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-0.5">
                            Remove duplicate records
                        </label>
                        <div className="mt-2 sm:col-span-3 sm:mt-0">
                            <input
                                id="remove_duplicate_records"
                                aria-describedby="remove_duplicate_records"
                                name="remove_duplicate_records"
                                type="checkbox"
                                className="h-4 w-4 rounded border-gray-300 text-sky-600 focus:ring-sky-600 disabled:text-gray-200"
                                checked={remove_duplicate_records}
                                disabled={is_processing || is_committing}
                                onChange={(e) => { setIsInit(false); setRemoveDuplicateRecords(e.target.checked); }}
                            />
                        </div>
                    </Fragment>}
                </div>

                <div className="sm:grid sm:grid-cols-4 max-w-5xl sm:items-start sm:gap-4 sm:pt-3 sm:pb-6 border-t border-gray-900/10">
                    <label htmlFor="detect_decimal_separator" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-0.5">
                        Detect decimal separator
                    </label>
                    <div className="mt-2 sm:col-span-3 sm:mt-0">
                        <input
                            id="detect_decimal_separator"
                            aria-describedby="detect_decimal_separator"
                            name="detect_decimal_separator"
                            type="checkbox"
                            className="h-4 w-4 rounded border-gray-300 text-sky-600 focus:ring-sky-600 disabled:text-gray-200"
                            checked={detect_decimal_separator}
                            disabled={is_processing || is_committing}
                            onChange={(e) => { setIsInit(false); setDetectDecimalSeparator(e.target.checked); }}
                        />
                    </div>
                    <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                        {detect_decimal_separator && "Fallback decimal separator"}
                        {!detect_decimal_separator && "Decimal separator"}
                    </label>
                    <div className="mt-2 sm:col-span-3 sm:mt-0">
                        <div className="flex w-full max-w-xs rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-sky-600">
                            <Dropdown
                                ids={[",", "."]}
                                values={["Decimal comma (example: 1,5)", "Decimal dot (example: 1.5)"]}
                                selected={default_decimal_separator}
                                disabled={is_processing || is_committing}
                                onChange={(id) => {
                                    setIsInit(false);
                                    if (id === "," || id === ".") {
                                        setDefaultDecimalSeparator(id as "," | ".");
                                    }
                                }} />
                        </div>
                    </div>
                </div>


                {show_admin_details && is_admin && <div className="sm:grid sm:grid-cols-4 max-w-5xl sm:items-start sm:gap-4 sm:pt-6 border-t border-gray-900/10 text-sm font-bold leading-6 text-gray-400">
                    Admin settings
                </div>}

                {show_admin_details && is_admin && <div className="sm:grid sm:grid-cols-4 max-w-5xl sm:items-start sm:gap-4 sm:py-6">
                    <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-400 sm:pt-1.5">
                        Extraction strategy
                    </label>
                    <div className="mt-2 sm:mt-0">
                        <div className="flex w-full max-w-xs rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-sky-600">
                            <Dropdown
                                ids={["standard", "prepend_header_page"]}
                                values={["Standard extraction", "Prepend header pages"]}
                                selected={extraction_strategy}
                                disabled={is_processing || is_committing}
                                onChange={(id) => {
                                    setIsInit(false);
                                    if (id === "standard" || id === "prepend_header_page") {
                                        setExtractionStrategy(id as "standard" | "prepend_header_page");
                                    }
                                }} />
                        </div>
                    </div>

                    <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-400 sm:pt-1.5">
                        Excel preprocessing strategy
                    </label>
                    <div className="mt-2 sm:mt-0">
                        <div className="flex w-full max-w-xs rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-sky-600">
                            <Dropdown
                                ids={["col_names_sparse", "col_names_dense_zero", "col_names_dense_empty", "without_col_names"]}
                                values={["Column names (sparse)", "Column names (dense with zero)", "Column names (dense with empty)", "Without column names"]}
                                selected={preprocess_excel_strategy}
                                disabled={is_processing || is_committing}
                                onChange={(id) => {
                                    setIsInit(false);
                                    if (["col_names_sparse", "col_names_dense_zero", "col_names_dense_empty", "without_col_names"].includes(id)) {
                                        setPreprocessExcelStrategy(id as t.IPreprocessExcelStrategies);
                                    }
                                }} />
                        </div>
                    </div>

                    <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-400 sm:pt-1.5">
                        OCR preprocessing strategy
                    </label>
                    <div className="mt-2 sm:mt-0">
                        <div className="flex w-full max-w-xs rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-sky-600">
                            <Dropdown
                                ids={["simple", "fix_rotation"]}
                                values={["Simple", "Fix smaller rotations"]}
                                selected={preprocess_ocr_strategy}
                                disabled={is_processing || is_committing}
                                onChange={(id) => {
                                    setIsInit(false);
                                    if (["simple", "fix_rotation"].includes(id)) {
                                        setPreprocessOcrStrategy(id as t.IPreprocessOcrStrategies);
                                    }
                                }} />
                        </div>
                    </div>

                    <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-400 sm:pt-1.5">
                        Maximum partial responses
                    </label>
                    <div className="mt-2 sm:mt-0">
                        <div className="flex w-full max-w-xs rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-sky-600">
                            <input
                                type="text"
                                className="block flex-1 border-0 bg-transparent p-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 text-sm sm:leading-6"
                                value={max_partial_responses}
                                disabled={is_processing || is_committing}
                                onChange={handleMaxPartialResponseChange}
                            />
                        </div>
                    </div>

                    <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-400 sm:pt-1.5">
                        Prompt output format
                    </label>
                    <div className="mt-2 sm:mt-0">
                        <div className="flex w-full max-w-xs rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-sky-600">
                            <Dropdown
                                values={["JSON", "TSV"]}
                                ids={["json", "tsv"]}
                                selected={prompt_output_format}
                                disabled={is_processing || is_committing}
                                onChange={(id) => {
                                    setIsInit(false);
                                    setPromptOutputFormat(id as "tsv" | "json");
                                    setTryAutoHeal(id === "tsv" && try_auto_heal);
                                }} />
                        </div>
                    </div>

                    <label htmlFor="try_auto_heal" className="block text-sm font-medium leading-6 text-gray-400 sm:pt-1.5">
                        Try auto-heal
                    </label>
                    <div className="mt-2 sm:mt-0">
                        <input
                            id="try_auto_heal"
                            aria-describedby="try_auto_heal"
                            name="try_auto_heal"
                            type="checkbox"
                            className="h-4 w-4 rounded border-gray-300 text-sky-600 focus:ring-sky-600 disabled:text-gray-200"
                            checked={try_auto_heal}
                            disabled={is_processing || is_committing}
                            onChange={(e) => { setTryAutoHeal(e.target.checked); }}
                        />
                    </div>

                    <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-400 sm:pt-1.5">
                        Rotation-segment handling strategy
                    </label>
                    <div className="mt-2 sm:mt-0">
                        <div className="flex w-full max-w-xs rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-sky-600">
                            <Dropdown
                                ids={["as_is", "only_main", "segment"]}
                                values={["Keep rotation as they are", "Only keep the main rotations", "Segment texts by rotation"]}
                                selected={orientation_segments_strategy}
                                disabled={is_processing || is_committing || preprocess_ocr_strategy !== "fix_rotation"}
                                onChange={(id) => {
                                    setIsInit(false);
                                    if (["as_is", "only_main", "segment"].includes(id)) {
                                        setOrientationSegmentsStrategy(id as ce.IPreprocessOcrOrientationStrategies);
                                    }
                                }} />
                        </div>
                    </div>
                </div>}

                {show_admin_details && is_admin && <div className="sm:grid sm:grid-cols-4 max-w-5xl sm:items-start sm:gap-4 sm:pt-6 border-t border-gray-900/10 text-sm font-bold leading-6 text-gray-400">
                    Model overrides
                </div>}

                {show_admin_details && is_admin && <div className="sm:grid sm:grid-cols-6 max-w-5xl sm:items-start sm:gap-4 sm:py-6">
                    <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-400 sm:pt-1.5">
                        Extract
                    </label>
                    <div className="mt-2 sm:mt-0 sm:col-span-2">
                        <div className="flex w-full max-w-xs rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-sky-600">
                            <Dropdown
                                values={["/", ...model_names]}
                                selected={models_overrides.scrape_extract || "/"}
                                disabled={is_processing || is_committing}
                                onChange={(model_name) => { handleModelNameChange(model_name, "scrape_extract"); }} />
                        </div>
                    </div>

                    <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-400 sm:pt-1.5">
                        Heal
                    </label>
                    <div className="mt-2 sm:mt-0 sm:col-span-2">
                        <div className="flex w-full max-w-xs rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-sky-600">
                            <Dropdown
                                values={["/", ...model_names]}
                                selected={models_overrides.scrape_heal || "/"}
                                disabled={is_processing || is_committing}
                                onChange={(model_name) => { handleModelNameChange(model_name, "scrape_heal"); }} />
                        </div>
                    </div>

                    <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-400 sm:pt-1.5">
                        Summarize
                    </label>
                    <div className="mt-2 sm:mt-0 sm:col-span-2">
                        <div className="flex w-full max-w-xs rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-sky-600">
                            <Dropdown
                                values={["/", ...model_names]}
                                selected={models_overrides.scrape_summarize || "/"}
                                disabled={is_processing || is_committing}
                                onChange={(model_name) => { handleModelNameChange(model_name, "scrape_summarize"); }} />
                        </div>
                    </div>

                    <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-400 sm:pt-1.5">
                        Focused Summarize
                    </label>
                    <div className="mt-2 sm:mt-0 sm:col-span-2">
                        <div className="flex w-full max-w-xs rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-sky-600">
                            <Dropdown
                                values={["/", ...model_names]}
                                selected={models_overrides.scrape_focused_summarize || "/"}
                                disabled={is_processing || is_committing}
                                onChange={(model_name) => { handleModelNameChange(model_name, "scrape_focused_summarize"); }} />
                        </div>
                    </div>

                    <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-400 sm:pt-1.5">
                        Decimal Separator
                    </label>
                    <div className="mt-2 sm:mt-0 sm:col-span-2">
                        <div className="flex w-full max-w-xs rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-sky-600">
                            <Dropdown
                                values={["/", ...model_names]}
                                selected={models_overrides.decimal_separator || "/"}
                                disabled={is_processing || is_committing}
                                onChange={(model_name) => { handleModelNameChange(model_name, "decimal_separator"); }} />
                        </div>
                    </div>

                    <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-400 sm:pt-1.5">
                        Default
                    </label>
                    <div className="mt-2 sm:mt-0 sm:col-span-2">
                        <div className="flex w-full max-w-xs rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-sky-600">
                            <Dropdown
                                values={["/", ...model_names]}
                                selected={models_overrides.default || "/"}
                                disabled={is_processing || is_committing}
                                onChange={(model_name) => { handleModelNameChange(model_name, "default"); }} />
                        </div>
                    </div>

                    <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-400 sm:pt-1.5">
                        Default fast
                    </label>
                    <div className="mt-2 sm:mt-0 sm:col-span-2">
                        <div className="flex w-full max-w-xs rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-sky-600">
                            <Dropdown
                                values={["/", ...model_names]}
                                selected={models_overrides.default_fast || "/"}
                                disabled={is_processing || is_committing}
                                onChange={(model_name) => { handleModelNameChange(model_name, "default_fast"); }} />
                        </div>
                    </div>

                </div>}

                {show_admin_details && is_admin && <div className="sm:grid sm:grid-cols-4 max-w-5xl sm:items-start sm:gap-4 sm:pt-6 border-t border-gray-900/10 text-sm font-bold leading-6 text-gray-400">
                    Prompt overrides
                </div>}

                {show_admin_details && is_admin && <div className="sm:grid sm:grid-cols-6 max-w-5xl sm:items-start sm:gap-4 sm:py-6">
                    <div className="sm:col-span-6 flex items-center space-x-2">
                        <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-400 sm:pt-1.5">
                            After scrape system
                        </label>
                        <input
                            id="enable_admin_after_scrape_system"
                            aria-describedby="enable_admin_after_scrape_system"
                            name="enable_admin_after_scrape_system"
                            type="checkbox"
                            className="h-4 w-4 rounded border-gray-300 text-sky-600 focus:ring-sky-600 disabled:text-gray-200"
                            checked={enable_admin_after_scrape_system}
                            onChange={(e) => { handleEnableAdminPromptChange(e, "admin_after_scrape_system") }}
                        />
                    </div>
                    {enable_admin_after_scrape_system && <div className="sm:col-span-6">
                        <textarea
                            id="admin_after_scrape_system"
                            name="admin_after_scrape_system"
                            rows={10}
                            className="block w-full max-w-4xl p-2 rounded-md border-0 py-1.5 text-gray-900 shadow-lg ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
                            value={admin_prompts.admin_after_scrape_system}
                            disabled={is_processing || is_committing}
                            onChange={(e) => { setIsInit(false); setAdminPrompts({ ...admin_prompts, admin_after_scrape_system: e.target.value }) }}
                        />
                    </div>}
                    <div className="sm:col-span-6 flex items-center space-x-2">
                        <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-400 sm:pt-1.5">
                            After scrape user
                        </label>
                        <input
                            id="enable_admin_after_scrape_user"
                            aria-describedby="enable_admin_after_scrape_user"
                            name="enable_admin_after_scrape_user"
                            type="checkbox"
                            className="h-4 w-4 rounded border-gray-300 text-sky-600 focus:ring-sky-600 disabled:text-gray-200"
                            checked={enable_admin_after_scrape_user}
                            onChange={(e) => { handleEnableAdminPromptChange(e, "admin_after_scrape_user") }}
                        />
                    </div>
                    {enable_admin_after_scrape_user && <div className="sm:col-span-6">
                        <textarea
                            id="admin_after_scrape_user"
                            name="admin_after_scrape_user"
                            rows={10}
                            className="block w-full max-w-4xl p-2 rounded-md border-0 py-1.5 text-gray-900 shadow-lg ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
                            value={admin_prompts.admin_after_scrape_user}
                            disabled={is_processing || is_committing}
                            onChange={(e) => { setIsInit(false); setAdminPrompts({ ...admin_prompts, admin_after_scrape_user: e.target.value }) }}
                        />
                    </div>}
                    <div className="sm:col-span-6 flex items-center space-x-2">
                        <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-400 sm:pt-1.5">
                            After partial scrape system
                        </label>
                        <div className="mt-2 sm:mt-0">
                            <input
                                id="enable_admin_after_partial_scrape_system"
                                aria-describedby="enable_admin_after_partial_scrape_system"
                                name="enable_admin_after_partial_scrape_system"
                                type="checkbox"
                                className="h-4 w-4 rounded border-gray-300 text-sky-600 focus:ring-sky-600 disabled:text-gray-200"
                                checked={enable_admin_after_partial_scrape_system}
                                onChange={(e) => { handleEnableAdminPromptChange(e, "admin_after_partial_scrape_system") }}
                            />
                        </div>
                    </div>
                    {enable_admin_after_partial_scrape_system && <div className="mt-2 sm:mt-0 sm:col-span-6">
                        <textarea
                            id="admin_after_partial_scrape_system"
                            name="admin_after_partial_scrape_system"
                            rows={10}
                            className="block w-full max-w-4xl p-2 rounded-md border-0 py-1.5 text-gray-900 shadow-lg ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
                            value={admin_prompts.admin_after_partial_scrape_system}
                            disabled={is_processing || is_committing}
                            onChange={(e) => { setIsInit(false); setAdminPrompts({ ...admin_prompts, admin_after_partial_scrape_system: e.target.value }) }}
                        />
                    </div>}
                    <div className="sm:col-span-6 flex items-center space-x-2">
                        <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-400 sm:pt-1.5">
                            After partial scrape user
                        </label>
                        <div className="mt-2 sm:mt-0">
                            <input
                                id="enable_admin_after_partial_scrape_user"
                                aria-describedby="enable_admin_after_partial_scrape_user"
                                name="enable_admin_after_partial_scrape_user"
                                type="checkbox"
                                className="h-4 w-4 rounded border-gray-300 text-sky-600 focus:ring-sky-600 disabled:text-gray-200"
                                checked={enable_admin_after_partial_scrape_user}
                                onChange={(e) => { handleEnableAdminPromptChange(e, "admin_after_partial_scrape_user") }}
                            />
                        </div>
                    </div>
                    {enable_admin_after_partial_scrape_user && <div className="mt-2 sm:mt-0 sm:col-span-6">
                        <textarea
                            id="admin_after_partial_scrape_user"
                            name="admin_after_partial_scrape_user"
                            rows={10}
                            className="block w-full max-w-4xl p-2 rounded-md border-0 py-1.5 text-gray-900 shadow-lg ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
                            value={admin_prompts.admin_after_partial_scrape_user}
                            disabled={is_processing || is_committing}
                            onChange={(e) => { setIsInit(false); setAdminPrompts({ ...admin_prompts, admin_after_partial_scrape_user: e.target.value }) }}
                        />
                    </div>}
                </div>}

                {!show_admin_details && is_admin && <div className="px-4 py-6 border-t border-gray-200">
                    <Button text="Show admin details" onClick={() => setShowAdminDetails(true)} icon={ChevronDownIcon} />
                </div>}

                {show_admin_details && is_admin && <div className="px-4 py-6 border-t border-gray-200">
                    <Button text="Hide admin details" onClick={() => setShowAdminDetails(false)} icon={ChevronUpIcon} />
                </div>}
            </div>
        </form>}

        {selected_tab_key === "examples" && <form>
            <div className="px-10">
                <div className="max-w-4xl sm:items-start sm:gap-4 sm:pt-6 flex flex-row">
                    <div className="pb-4 text-gray-600 text-sm">
                        <div>
                            Here you can test your template on examples. You can add as many examples as you like.
                            The examples and the results will be saved together with the template and can be
                            used to evaluate any future changes to the template.
                        </div>
                        {is_edit && (new_examples.length > 0 || existing_examples.some((example) => example.is_modified) || is_existing_example_deleted) &&
                            <div className="max-w-5xl sm:items-start sm:gap-4 text-right pb-4">
                                <span className="text-sm text-gray-400">Unsaved changes to examples</span>
                            </div>}
                        {check_error_message !== undefined && <div className="mt-2 text-red-600">Error: {check_error_message}</div>}
                        {eval_metrics && eval_metrics_per_field && <div className="pt-6 w-full">
                            <h3 className="font-semibold">Percentage change compared to the previous template version:</h3>
                            <EvalMetrics eval_metrics={eval_metrics} eval_metrics_per_field={eval_metrics_per_field} />
                        </div>}
                    </div>
                    <div className="flex flex-col justify-end w-96 gap-y-4">
                        <Button text="Add Example"
                            disabled={is_processing || is_committing}
                            onClick={() => setIsNewExampleOpen(true)} />
                        {is_admin && <Button text="Test Template"
                            disabled={is_fields_invalid || is_processing || is_committing}
                            disabled_warning={is_fields_invalid ? "Please provide a name for each field" : undefined}
                            tooltip={is_fields_invalid ? "Please provide a name for each field" : undefined}
                            loading={is_processing}
                            onClick={onCheck} />}
                        {is_edit && selected_tab_key === "examples" && <Button text="Save Examples"
                            disabled={is_committing || (new_examples.length === 0 && existing_examples.every((example) => !example.is_modified) && !is_existing_example_deleted)}
                            loading={is_committing}
                            onClick={() => onUpdateExamples()} />}
                    </div>
                </div>
            </div>

            <div className="px-10">
                {new_examples.map((example, idx) =>
                    <div key={idx} className="sm:items-start sm:gap-4 sm:py-4 mt-5 text-gray-600 text-sm border border-gray-200 bg-gray-50 rounded-md">
                        <div className="pl-6 mb-3 flex flex-row max-w-4xl">
                            <div className="font-semibold flex flex-row items-center truncate">
                                {idx + 1}. {example.name}
                            </div>
                            <div className="font-semibold flex flex-row items-center">
                                <span className="ml-2 font-normal text-gray-400" >(new)</span>
                                <ClipboardDocumentIcon
                                    className="h-4 w-4 ml-2 text-gray-400 hover:text-gray-600 cursor-pointer"
                                    onClick={() => handleCopyToClipboard(flattenScrapeDocuments(example.input_documents))} />
                                <ArrowTopRightOnSquareIcon
                                    className="h-4 w-4 ml-2 text-gray-400 hover:text-gray-600 cursor-pointer"
                                    onClick={() => setFullScreenText(flattenScrapeDocuments(example.input_documents))} />
                            </div>
                            <div className="flex-grow min-w-[40px]" />
                            {(is_processing || is_committing) && <div className="p-2">
                                <i className="fas fa-spinner fa-spin" />
                            </div>}
                            <ButtonGroup
                                buttons={[
                                    { text: "Test", onClick: () => handleTestSingleExample(idx) },
                                    { text: "Save", onClick: () => handleSaveSingleNewExample(idx), skip: !is_edit },
                                    { text: "Edit", onClick: () => setIsEditExampleOpen(idx) },
                                    { text: "Delete", onClick: () => setIsDeleteExampleOpen(idx) }
                                ]}
                                disabled={is_processing || is_committing}
                            />
                        </div>
                        <div className="pl-6 my-3 text-sm max-w-4xl">{example.comment}</div>
                        <div className="max-w-4xl">
                            <div className="p-2 ml-4 mt-3 border-gray-200 border rounded shadow bg-white">
                                <LongText text={flattenScrapeDocuments(example.input_documents)} line_limit={5} />
                            </div>
                        </div>
                        {example.records && <div className="mt-3 px-4">
                            <ExampleScrapeTable fields={fields} output_type={output_type} records={example.records} />
                        </div>}
                    </div>)}

                {existing_examples.map((example, idx) =>
                    <div key={idx} className="sm:items-start sm:gap-4 sm:py-4 mt-5 text-gray-600 text-sm border border-gray-200 bg-gray-50 rounded-md">
                        <div className="pl-6 mb-3 flex flex-row max-w-4xl">
                            <div className="font-semibold flex flex-row items-center truncate">
                                {new_examples.length + idx + 1}. {example.name}
                            </div>
                            <div className="font-semibold flex flex-row items-center">
                                <ClipboardDocumentIcon
                                    className="h-4 w-4 ml-2 text-gray-400 hover:text-gray-600 cursor-pointer"
                                    onClick={() => handleCopyToClipboard(flattenScrapeDocuments(example.input_documents))} />
                                <ArrowTopRightOnSquareIcon
                                    className="h-4 w-4 ml-2 text-gray-400 hover:text-gray-600 cursor-pointer"
                                    onClick={() => setFullScreenText(flattenScrapeDocuments(example.input_documents))} />
                            </div>
                            <div className="flex-grow min-w-[40px]" />
                            {(is_processing || is_committing) && <div className="p-2">
                                <i className="fas fa-spinner fa-spin" />
                            </div>}
                            <ButtonGroup
                                buttons={[
                                    { text: "Excel", href: `/api/item/excel-example?item_uuid=${example.item_uuid}`, open_in_new_tab: true, skip: !is_admin || example.is_modified },
                                    { text: "Test", onClick: () => handleTestSingleExample(new_examples.length + idx) },
                                    { text: "Save", onClick: () => handleSaveSingleExistingExample(idx), skip: !example.is_modified },
                                    { text: "Edit", onClick: () => setIsEditExampleOpen(new_examples.length + idx) },
                                    { text: "Delete", onClick: () => setIsDeleteExampleOpen(new_examples.length + idx) }
                                ]}
                                disabled={is_processing || is_committing}
                            />
                        </div>
                        <div className="pl-6 my-3 text-sm max-w-4xl"><span className="text-xs">[{prettySmartDateTime(example.created_at)}]</span> {example.comment}</div>
                        <div className="max-w-4xl">
                            <div className="p-2 ml-4 mt-3 border-gray-200 border rounded shadow bg-white">
                                <LongText text={flattenScrapeDocuments(example.input_documents)} line_limit={5} />
                            </div>
                        </div>
                        {/* scrape-level metrics  */}
                        {example.metrics && example.metrics_per_field && <div className="w-full max-w-4xl">
                            <EvalMetrics eval_metrics={example.metrics} eval_metrics_per_field={example.metrics_per_field} compact={true} />
                        </div>}
                        {/* we have new and old records so we can do diff  */}
                        {example.new_records && example.old_records && example.diffs && <div className="mt-3">
                            <ExampleDiffScrapeTable
                                fields={fields}
                                output_type={output_type}
                                old_records={example.old_records}
                                new_records={example.new_records}
                                diffs={example.diffs} />
                        </div>}
                        {/* we have only new records so we can only show them */}
                        {example.new_records && !(example.old_records && example.diffs) && <div className="mt-3 px-4">
                            <ExampleScrapeTable fields={fields} output_type={output_type} records={example.new_records} />
                        </div>}
                    </div>)}
            </div>
        </form>}

        <NewExampleModal
            open={is_new_example_open}
            onAddExample={handleNewExample}
            onClose={() => setIsNewExampleOpen(false)} />
        <EditExampleModal
            open={is_edit_example_open >= 0}
            init_comment={getExampleComment()}
            onUpdateExample={handleUpdateExampleComment}
            onClose={() => setIsEditExampleOpen(-1)} />
        <ConfirmModal
            open={is_delete_example_open >= 0}
            title="Remove example"
            message={["Are you sure you want to remove this example?"]}
            confirm="Remove"
            onClose={handleDeleteExample} />
        <FullScreenText
            text={full_screen_text || ""}
            show={full_screen_text !== undefined}
            onClose={() => setFullScreenText(undefined)} />
    </Fragment >;
}

export function NewTemplateWrapper() {
    const navigate = useNavigate();

    const env = useSelector(selectEnv);
    const is_sidebar_large = useSelector(selectIsSidebarLarge);
    const memberships = useSelector(selectMemberships);

    // default is personal org, if not available, use first org
    const default_org_uuid =
        memberships.find((membership) => membership.org.type === c.ORG_TYPES.personal)?.org.uuid ||
        memberships[0].org.uuid ||
        "";

    const { context_uuid } = useParams<{ context_uuid: string | undefined }>();

    const [last_context_uuid, setLastContextUuid] = useState<string | undefined>(undefined);
    const [init_template, setInitTemplate] = useState<IInitTemplate | undefined>(undefined);
    const [is_wizard_open, setIsWizardOpen] = useState<boolean>(true);
    const [is_creating, setIsCreating] = useState<boolean>(false);

    useEffect(() => {
        if (context_uuid !== last_context_uuid) {
            if (context_uuid === undefined) {
                setIsWizardOpen(true);
            }
        }
        setLastContextUuid(context_uuid);
    }, [context_uuid, last_context_uuid]);

    const is_edit = context_uuid !== undefined;

    const onWizardClose = async (init_template: IInitTemplate) => {
        // go over fields and initialize uuids
        const template = {
            ...init_template,
            fields: init_template.fields.map((field) => ({ ...field, uuid: newUuid() }))
        };

        if (init_template.output_type === "hierarchical") {
            // we cannot edit hierarchical templates, just create and redirect to view
            setIsCreating(true);
            const { uuid } = await Backend.commitContext({
                ...template,
                org_uuid: default_org_uuid,
                facts: [],
                postprocess: {},
                extract_params: {
                    prompt_output_format: DEFAULT_NEW_PARAMS.prompt_output_format,
                    remove_duplicate_records: DEFAULT_NEW_PARAMS.remove_duplicate_records,
                    default_decimal_separator: DEFAULT_NEW_PARAMS.default_decimal_separator,
                    detect_decimal_separator: DEFAULT_NEW_PARAMS.detect_decimal_separator,
                    extraction_strategy: DEFAULT_NEW_PARAMS.extraction_strategy,
                    try_auto_heal: DEFAULT_NEW_PARAMS.try_auto_heal,
                    preprocess_excel_strategy: DEFAULT_NEW_PARAMS.preprocess_excel_strategy,
                    preprocess_ocr_strategy: DEFAULT_NEW_PARAMS.preprocess_ocr_strategy,
                    orientation_segments_strategy: DEFAULT_NEW_PARAMS.orientation_segments_strategy,
                    max_partial_responses: DEFAULT_NEW_PARAMS.max_partial_responses,
                    models_overrides: DEFAULT_NEW_PARAMS.models_overrides,
                    admin_prompts: DEFAULT_NEW_PARAMS.admin_prompts
                },
                output_type: "hierarchical",
                examples: []
            });
            navigate(`/templates/${uuid}`);
        } else {
            setIsWizardOpen(false);
            setInitTemplate(template);
        }
    }

    if (is_creating) {
        return <div className={classNames("hidden lg:fixed lg:right-0 lg:inset-y-0 lg:flex lg:flex-row", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
            <LoadingSpinner />
        </div>;
    }

    return <div className={classNames("lg:fixed lg:right-0 lg:inset-y-0 overflow-y-auto", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
        <FullScreen show={!is_edit && is_wizard_open} opacity={false} show_close_button={false}>
            <div className="px-6 py-8 text-lg text-gray-600">
                Select a template from the list below or create a custom template.
            </div>
            <div className="pb-8 flex flex-wrap">
                <div className="opacity-100">
                    <WizardButtonIcon title="Upload your document" icon={DocumentTextIcon} onClick={() => navigate("/template/wizard")} />
                </div>
                <div className="opacity-100">
                    <WizardButtonIcon title="Create custom template" icon={TbTablePlus} onClick={() => setIsWizardOpen(false)} />
                </div>
                {/* In DEV mode, we provide easy way to create contact information template for testing */}
                {env === "dev" && <div className="opacity-100">
                    <WizardButtonIcon title="DEV TEMPLATE" icon={RocketLaunchIcon} onClick={() => onWizardClose(DEV_EXAMPLE)} />
                </div>}
            </div>
            <div className="flex flex-col px-6">
                <h2 className="text-lg font-semibold leading-7 text-gray-900">Procurement</h2>
            </div>
            <div className="pb-4 flex flex-wrap overflow-y-auto">
                <div className="opacity-100">
                    <WizardDocumentButtonImage title="Order Confirmation" image="/wizards/new_template_order_confirmation.png" onClick={() => onWizardClose(WIZARD_ORDER_CONFIRMATION)} />
                </div>
                <div className="opacity-100">
                    <WizardDocumentButtonImage title="Sales Quotation" image="/wizards/new_template_sales_quotation.png" onClick={() => onWizardClose(WIZARD_SALES_QUOTATION)} />
                </div>
                <div className="opacity-100">
                    <WizardDocumentButtonImage title="Vendor Evaluation" image="/wizards/new_template_vendor_evaluation.png" onClick={() => onWizardClose(WIZARD_VENDOR_EVALUATION)} />
                </div>
                <div className="opacity-100">
                    <WizardDocumentButtonImage title="Short Contract" image="/wizards/new_template_contract.png" onClick={() => onWizardClose(WIZARD_SHORT_CONTRACT)} />
                </div>
                <div className="opacity-100">
                    <WizardDocumentButtonImage title="Long Contract" image="/wizards/new_template_contract.png" onClick={() => onWizardClose(WIZARD_LONG_CONTRACT)} />
                </div>
            </div>
            <div className="flex flex-col px-6">
                <h2 className="text-lg font-semibold leading-7 text-gray-900">Sales</h2>
            </div>
            <div className="pb-4 flex flex-wrap overflow-y-auto">
                <div className="opacity-100">
                    <WizardDocumentButtonImage title="Purchase Order" image="/wizards/new_template_purchase_order.png" onClick={() => onWizardClose(WIZARD_PURCHASE_ORDER)} />
                </div>
                <div className="opacity-100">
                    <WizardDocumentButtonImage title="RFQ" image="/wizards/new_template_rfq.png" onClick={() => onWizardClose(WIZARD_RFQ)} />
                </div>
                <div className="opacity-100">
                    <WizardDocumentButtonImage title="Demand Forecast Report" image="/wizards/new_template_demand_forecast_report.png" onClick={() => onWizardClose(WIZARD_DEMAND_FORECAST_REPORT)} />
                </div>
                <div className="opacity-100">
                    <WizardDocumentButtonImage title="Sales Report" image="/wizards/new_template_sales_report.png" onClick={() => onWizardClose(WIZARD_SALES_REPORT)} />
                </div>
            </div>
            <div className="flex flex-col px-6">
                <h2 className="text-lg font-semibold leading-7 text-gray-900">Finance</h2>
            </div>
            <div className="pb-4 flex flex-wrap overflow-y-auto">
                <div className="opacity-100">
                    <WizardDocumentButtonImage title="Invoice" image="/wizards/new_template_invoice.png" onClick={() => onWizardClose(WIZARD_INVOICE)} />
                </div>
                <div className="opacity-100">
                    <WizardDocumentButtonImage title="Bank Statement" image="/wizards/new_template_bank_statement.png" onClick={() => onWizardClose(WIZARD_BANK_STATEMENT)} />
                </div>
                <div className="opacity-100">
                    <WizardDocumentButtonImage title="Bank Statement - Xero" image="/wizards/new_template_bank_statement_xero.png" onClick={() => onWizardClose(WIZARD_BANK_STATEMENT)} />
                </div>
                <div className="opacity-100">
                    <WizardDocumentButtonImage title="Annual Report" image="/wizards/new_template_annual_report.png" onClick={() => onWizardClose(WIZARD_ANNUAL_REPORT)} />
                </div>

            </div>
            <div className="flex flex-col px-6">
                <h2 className="text-lg font-semibold leading-7 text-gray-900">Logistics and Shipping</h2>
            </div>
            <div className="pb-4 flex flex-wrap overflow-y-auto">
                <div className="opacity-100">
                    <WizardDocumentButtonImage title="Bill of Lading" image="/wizards/new_template_bill_of_lading.png" onClick={() => onWizardClose(WIZARD_BILL_OF_LADING)} />
                </div>
                <div className="opacity-100">
                    <WizardDocumentButtonImage title="Delivery Note" image="/wizards/new_template_delivery_note.png" onClick={() => onWizardClose(WIZARD_DELIVERY_NOTE)} />
                </div>
                <div className="opacity-100">
                    <WizardDocumentButtonImage title="Customs Declaration" image="/wizards/new_template_customs_declaration.png" onClick={() => onWizardClose(WIZARD_CUSTOMS_DECLARATION)} />
                </div>
                <div className="opacity-100">
                    <WizardDocumentButtonImage title="Shipping Label" image="/wizards/new_template_shipping_label.png" onClick={() => onWizardClose(WIZARD_SHIPPING_LABEL)} />
                </div>
            </div>
            <div className="flex flex-col px-6">
                <h2 className="text-lg font-semibold leading-7 text-gray-900">Operations</h2>
            </div>
            <div className="pb-4 flex flex-wrap overflow-y-auto">
                <div className="opacity-100">
                    <WizardDocumentButtonImage title="BOM" image="/wizards/new_template_bom.png" onClick={() => onWizardClose(WIZARD_BOM)} />
                </div>
                <div className="opacity-100">
                    <WizardDocumentButtonImage title="Quality Control" image="/wizards/new_template_quality_control.png" onClick={() => onWizardClose(WIZARD_QUALITY_CONTROL)} />
                </div>
                <div className="opacity-100">
                    <WizardDocumentButtonImage title="Work Order" image="/wizards/new_template_work_order.png" onClick={() => onWizardClose(WIZARD_WORK_ORDER)} />
                </div>
            </div>
            <div className="flex flex-col px-6">
                <h2 className="text-lg font-semibold leading-7 text-gray-900">Other</h2>
            </div>
            <div className="pb-4 flex flex-wrap overflow-y-auto">
                <div className="opacity-100">
                    <WizardDocumentButtonImage title="Resume" image="/wizards/new_template_resume.png" onClick={() => onWizardClose(WIZARD_RESUME)} />
                </div>
            </div>
        </FullScreen>

        <NewTemplate init_template={init_template} />
    </div>;
}
