import { Link } from "react-router-dom";

type BigButtonProps = {
    title: string;
    icon?: any;
    href: string;
};

export function BigButton(props: BigButtonProps) {
    return <div className="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-zinc-50 px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-sky-500 focus-within:ring-offset-2 hover:border-gray-400 hover:bg-sky-100">
        <div className="flex-shrink-0">
            <props.icon className="h-6 w-6 text-sky-300" aria-hidden="true" />
        </div>
        <div className="min-w-0 flex-1">
            <Link to={props.href} className="focus:outline-none">
                <span className="absolute inset-0" aria-hidden="true" />
                <p className="text-lg font-medium text-gray-900">{props.title}</p>
            </Link>
        </div>
    </div>;
}
