import * as api from "./rest_client.js";
import * as t from "./extractions.types.generated";

export interface IExtractions {

    /** Resend webhook data for given job */
    resendWebhookData(req: t.IResendWebhookDataReq): Promise<t.IResendWebhookDataRes>;


    /** Get list of open extract confirmations */
    getOpenExtractConfirmations(req: t.IGetOpenExtractConfirmationsReq): Promise<t.IGetOpenExtractConfirmationsRes>;


    /** Update the scrape of an extract confirmation */
    updateExtractConfirmationScrape(req: t.IUpdateExtractConfirmationScrapeReq): Promise<t.IUpdateExtractConfirmationScrapeRes>;


    /** Update the status of an item of an extract confirmation */
    updateExtractConfirmationItemStatus(req: t.IUpdateExtractConfirmationItemStatusReq): Promise<t.IUpdateExtractConfirmationItemStatusRes>;


    /** Get the next item for an extract confirmation */
    getExtractConfirmationNextItem(req: t.IGetExtractConfirmationNextItemReq): Promise<t.IGetExtractConfirmationNextItemRes>;


    /** Executes the code that transforms val_raw to val for records of a scrape */
    executeRecordPostprocessing(req: t.IExecuteRecordPostprocessingReq): Promise<t.IExecuteRecordPostprocessingRes>;


    /** List all rows of a lookup table */
    listLookupTableRows(req: t.IListLookupTableRowsReq): Promise<t.IListLookupTableRowsRes>;


    /** Add a new row to a lookup table */
    addLookupTableRow(req: t.IAddLookupTableRowReq): Promise<t.IAddLookupTableRowRes>;


    /** Update a row of a lookup table */
    updateLookupTableRow(req: t.IUpdateLookupTableRowReq): Promise<t.IUpdateLookupTableRowRes>;


    /** Delete a row of a lookup table */
    deleteLookupTableRow(req: t.IDeleteLookupTableRowReq): Promise<t.IDeleteLookupTableRowRes>;


    /** Create a new endpoint to lookup table mapping */
    createEndpointToLookupTableMapping(req: t.ICreateEndpointToLookupTableMappingReq): Promise<t.ICreateEndpointToLookupTableMappingRes>;


    /** Update an existing endpoint to lookup table mapping */
    updateEndpointToLookupTableMapping(req: t.IUpdateEndpointToLookupTableMappingReq): Promise<t.IUpdateEndpointToLookupTableMappingRes>;


    /** Delete an existing endpoint to lookup table mapping */
    deleteEndpointToLookupTableMapping(req: t.IDeleteEndpointToLookupTableMappingReq): Promise<t.IDeleteEndpointToLookupTableMappingRes>;


    /** List all endpoint to lookup table mappings */
    listEndpointToLookupTableMappings(req: t.IListEndpointToLookupTableMappingsReq): Promise<t.IListEndpointToLookupTableMappingsRes>;


    /** Get an endpoint to lookup table mapping by id */
    getEndpointToLookupTableMapping(req: t.IGetEndpointToLookupTableMappingReq): Promise<t.IGetEndpointToLookupTableMappingRes>;


    /** Get subscription details */
    getSubscriptionDetails(req: t.IGetSubscriptionDetailsReq): Promise<t.IGetSubscriptionDetailsRes>;


    /** Update existing subscription */
    updateSubscription(req: t.IUpdateSubscriptionReq): Promise<t.IUpdateSubscriptionRes>;


    /** Delete/cancel existing subscription */
    cancelSubscription(req: t.ICancelSubscriptionReq): Promise<t.ICancelSubscriptionRes>;


    /** Get referrals given some filters */
    getReferrals(req: t.IGetReferralsReq): Promise<t.IGetReferralsRes>;


    /** Create a new referral */
    createNewReferral(req: t.ICreateNewReferralReq): Promise<t.ICreateNewReferralRes>;


    /** Get subscriptions given some filters */
    getSubscriptions(req: t.IGetSubscriptionsReq): Promise<t.IGetSubscriptionsRes>;


    /** Create a new manual subscription */
    createNewManualSubscription(req: t.ICreateNewManualSubscriptionReq): Promise<t.ICreateNewManualSubscriptionRes>;


    /** Get organizations given some filters */
    getOrganizations(req: t.IGetOrganizationsReq): Promise<t.IGetOrganizationsRes>;


    /** Send an email digest */
    sendEmailDigest(req: t.ISendEmailDigestReq): Promise<t.ISendEmailDigestRes>;


    /** Retrieve audit log for given context */
    getContextAuditLog(req: t.IGetContextAuditLogReq): Promise<t.IGetContextAuditLogRes>;


    /** Get list of all models */
    getModels(req: t.IGetModelsReq): Promise<t.IGetModelsRes>;


    /** Update model overrides for an organization */
    updateModelOverrides(req: t.IUpdateModelOverridesReq): Promise<t.IUpdateModelOverridesRes>;

}


function enc(s: any): string { return encodeURIComponent("" + s); }

export class Extractions implements IExtractions {

    client: api.IApiClient;

    constructor(client: api.IApiClient) { this.client = client; }

    /** Resend webhook data for given job */
    async resendWebhookData(req: t.IResendWebhookDataReq): Promise<t.IResendWebhookDataRes> {
        const reqx: any = req;
        const url = `/api/v1.0/job/${enc(req.id)}/resend-webhook`;
        delete reqx.id;
        const res = await this.client.post(url, reqx);
        return res.data;
    }


    /** Get list of open extract confirmations */
    async getOpenExtractConfirmations(req: t.IGetOpenExtractConfirmationsReq): Promise<t.IGetOpenExtractConfirmationsRes> {
        const reqx: any = req;
        const url = `/api/v1.0/extract_confirmation/open`;
        const res = await this.client.get(url, { params: reqx });
        return res.data;
    }


    /** Update the scrape of an extract confirmation */
    async updateExtractConfirmationScrape(req: t.IUpdateExtractConfirmationScrapeReq): Promise<t.IUpdateExtractConfirmationScrapeRes> {
        const reqx: any = req;
        const url = `/api/v1.0/extract_confirmation/update_scrape`;
        const res = await this.client.post(url, reqx);
        return res.data;
    }


    /** Update the status of an item of an extract confirmation */
    async updateExtractConfirmationItemStatus(req: t.IUpdateExtractConfirmationItemStatusReq): Promise<t.IUpdateExtractConfirmationItemStatusRes> {
        const reqx: any = req;
        const url = `/api/v1.0/extract_confirmation/update_item_status`;
        const res = await this.client.post(url, reqx);
        return res.data;
    }


    /** Get the next item for an extract confirmation */
    async getExtractConfirmationNextItem(req: t.IGetExtractConfirmationNextItemReq): Promise<t.IGetExtractConfirmationNextItemRes> {
        const reqx: any = req;
        const url = `/api/v1.0/extract_confirmation/get_next_item`;
        const res = await this.client.get(url, { params: reqx });
        return res.data;
    }


    /** Executes the code that transforms val_raw to val for records of a scrape */
    async executeRecordPostprocessing(req: t.IExecuteRecordPostprocessingReq): Promise<t.IExecuteRecordPostprocessingRes> {
        const reqx: any = req;
        const url = `/api/v1.0/scrape/execute_record_postprocessing`;
        const res = await this.client.post(url, reqx);
        return res.data;
    }


    /** List all rows of a lookup table */
    async listLookupTableRows(req: t.IListLookupTableRowsReq): Promise<t.IListLookupTableRowsRes> {
        const reqx: any = req;
        const url = `/api/v1.0/lookup_table/rows/list`;
        const res = await this.client.get(url, { params: reqx });
        return res.data;
    }


    /** Add a new row to a lookup table */
    async addLookupTableRow(req: t.IAddLookupTableRowReq): Promise<t.IAddLookupTableRowRes> {
        const reqx: any = req;
        const url = `/api/v1.0/lookup_table/row/add`;
        const res = await this.client.post(url, reqx);
        return res.data;
    }


    /** Update a row of a lookup table */
    async updateLookupTableRow(req: t.IUpdateLookupTableRowReq): Promise<t.IUpdateLookupTableRowRes> {
        const reqx: any = req;
        const url = `/api/v1.0/lookup_table/row/update`;
        const res = await this.client.post(url, reqx);
        return res.data;
    }


    /** Delete a row of a lookup table */
    async deleteLookupTableRow(req: t.IDeleteLookupTableRowReq): Promise<t.IDeleteLookupTableRowRes> {
        const reqx: any = req;
        const url = `/api/v1.0/lookup_table/row/delete`;
        const res = await this.client.post(url, reqx);
        return res.data;
    }


    /** Create a new endpoint to lookup table mapping */
    async createEndpointToLookupTableMapping(req: t.ICreateEndpointToLookupTableMappingReq): Promise<t.ICreateEndpointToLookupTableMappingRes> {
        const reqx: any = req;
        const url = `/api/v1.0/endpoint_to_lookup_table/create`;
        const res = await this.client.post(url, reqx);
        return res.data;
    }


    /** Update an existing endpoint to lookup table mapping */
    async updateEndpointToLookupTableMapping(req: t.IUpdateEndpointToLookupTableMappingReq): Promise<t.IUpdateEndpointToLookupTableMappingRes> {
        const reqx: any = req;
        const url = `/api/v1.0/endpoint_to_lookup_table/update`;
        const res = await this.client.post(url, reqx);
        return res.data;
    }


    /** Delete an existing endpoint to lookup table mapping */
    async deleteEndpointToLookupTableMapping(req: t.IDeleteEndpointToLookupTableMappingReq): Promise<t.IDeleteEndpointToLookupTableMappingRes> {
        const reqx: any = req;
        const url = `/api/v1.0/endpoint_to_lookup_table/delete`;
        const res = await this.client.post(url, reqx);
        return res.data;
    }


    /** List all endpoint to lookup table mappings */
    async listEndpointToLookupTableMappings(req: t.IListEndpointToLookupTableMappingsReq): Promise<t.IListEndpointToLookupTableMappingsRes> {
        const reqx: any = req;
        const url = `/api/v1.0/endpoint_to_lookup_table/list`;
        const res = await this.client.get(url, { params: reqx });
        return res.data;
    }


    /** Get an endpoint to lookup table mapping by id */
    async getEndpointToLookupTableMapping(req: t.IGetEndpointToLookupTableMappingReq): Promise<t.IGetEndpointToLookupTableMappingRes> {
        const reqx: any = req;
        const url = `/api/v1.0/endpoint_to_lookup_table/get`;
        const res = await this.client.get(url, { params: reqx });
        return res.data;
    }


    /** Get subscription details */
    async getSubscriptionDetails(req: t.IGetSubscriptionDetailsReq): Promise<t.IGetSubscriptionDetailsRes> {
        const reqx: any = req;
        const url = `/api/v1.0/subscription/${enc(req.id)}`;
        delete reqx.id;
        const res = await this.client.get(url, { params: reqx });
        return res.data;
    }


    /** Update existing subscription */
    async updateSubscription(req: t.IUpdateSubscriptionReq): Promise<t.IUpdateSubscriptionRes> {
        const reqx: any = req;
        const url = `/api/v1.0/subscription/${enc(req.id)}`;
        delete reqx.id;
        const res = await this.client.put(url, reqx);
        return res.data;
    }


    /** Delete/cancel existing subscription */
    async cancelSubscription(req: t.ICancelSubscriptionReq): Promise<t.ICancelSubscriptionRes> {
        const reqx: any = req;
        const url = `/api/v1.0/subscription/${enc(req.id)}`;
        delete reqx.id;
        const res = await this.client.delete(url, reqx);
        return res.data;
    }


    /** Get referrals given some filters */
    async getReferrals(req: t.IGetReferralsReq): Promise<t.IGetReferralsRes> {
        const reqx: any = req;
        const url = `/api/v1.0/referral`;
        const res = await this.client.get(url, { params: reqx });
        return res.data;
    }


    /** Create a new referral */
    async createNewReferral(req: t.ICreateNewReferralReq): Promise<t.ICreateNewReferralRes> {
        const reqx: any = req;
        const url = `/api/v1.0/referral`;
        const res = await this.client.post(url, reqx);
        return res.data;
    }


    /** Get subscriptions given some filters */
    async getSubscriptions(req: t.IGetSubscriptionsReq): Promise<t.IGetSubscriptionsRes> {
        const reqx: any = req;
        const url = `/api/v1.0/subscription`;
        const res = await this.client.get(url, { params: reqx });
        return res.data;
    }


    /** Create a new manual subscription */
    async createNewManualSubscription(req: t.ICreateNewManualSubscriptionReq): Promise<t.ICreateNewManualSubscriptionRes> {
        const reqx: any = req;
        const url = `/api/v1.0/subscription`;
        const res = await this.client.post(url, reqx);
        return res.data;
    }


    /** Get organizations given some filters */
    async getOrganizations(req: t.IGetOrganizationsReq): Promise<t.IGetOrganizationsRes> {
        const reqx: any = req;
        const url = `/api/v1.0/org`;
        const res = await this.client.get(url, { params: reqx });
        return res.data;
    }


    /** Send an email digest */
    async sendEmailDigest(req: t.ISendEmailDigestReq): Promise<t.ISendEmailDigestRes> {
        const reqx: any = req;
        const url = `/api/v1.0/email_digest/send`;
        const res = await this.client.post(url, reqx);
        return res.data;
    }


    /** Retrieve audit log for given context */
    async getContextAuditLog(req: t.IGetContextAuditLogReq): Promise<t.IGetContextAuditLogRes> {
        const reqx: any = req;
        const url = `/api/v1.0/context-audit/${enc(req.id)}`;
        delete reqx.id;
        const res = await this.client.get(url, { params: reqx });
        return res.data;
    }


    /** Get list of all models */
    async getModels(req: t.IGetModelsReq): Promise<t.IGetModelsRes> {
        const reqx: any = req;
        const url = `/api/v1.0/models`;
        const res = await this.client.get(url, { params: reqx });
        return res.data;
    }


    /** Update model overrides for an organization */
    async updateModelOverrides(req: t.IUpdateModelOverridesReq): Promise<t.IUpdateModelOverridesRes> {
        const reqx: any = req;
        const url = `/api/v1.0/org/models_overrides`;
        const res = await this.client.post(url, reqx);
        return res.data;
    }

}
