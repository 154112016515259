import { Fragment, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import {
    BookOpenIcon, PencilIcon, TrashIcon, CheckCircleIcon, BoltIcon
} from "@heroicons/react/24/outline";

import * as st from "../lib/types";
import * as xt from "../lib/backend/extractions.types.generated";
import * as stt from "../lib/backend/stripe.types.generated";
import { Backend, BackendObj } from "../lib/backend";
import { classNames, prettySmartDateTime, prettyDate, prettyNumber } from "../lib/utils";
import { selectIsSidebarLarge } from "../lib/scraper.slice";

import { Button } from "../components/Button";
import { LoadingSpinner, LoadingSpinnerLimit } from "../components/LoadingSpinner";
import { TransactionHistory } from "../components/TransactionHistory";
import { ExtractJobs } from "../components/ExtractJobs";
import { OrgAuditLog } from "../components/OrgAuditLog";
import { Tabs } from "../components/Tabs";
import { CopyTextbox } from "../components/CopyTextbox";
import { Dropdown } from "../components/Dropdown";
import { ReadOnlyTextbox } from "../components/ReadOnlyTextbox";
import { Toggle } from "../components/Toggle";
import { Textbox } from "../components/Textbox";

export function AdminOrg() {
    const { org_uuid } = useParams<{ org_uuid: string }>();

    const is_sidebar_large = useSelector(selectIsSidebarLarge);

    const [org, setOrg] = useState<st.IOrganization | undefined>(undefined);
    const [users, setUsers] = useState<st.IOrgUser[] | undefined>(undefined);
    const [balance, setBalance] = useState<number | undefined>(undefined);
    const [sso, setSso] = useState<st.IOrgSingleSignOn | undefined>(undefined);
    const [stripe_enabled, setStripeEnabled] = useState<boolean>(false);
    const [stripe_subscription, setStripeSubscription] = useState<xt.ISubscription | null>(null);
    const [invoices, setInvoices] = useState<stt.IStripeInvoice[] | undefined>(undefined);
    const [manual_subscription_edit, setManualSubscriptionEdit] = useState<boolean>(false);
    const [manual_subscription, setManualSubscription] = useState<xt.ISubscription | null>(null);
    const [selected_tab, setSelectedTab] = useState<string>("users");
    const [open_transaction_history, setOpenTransactionHistory] = useState<boolean>(false);
    const [existing_stripe_subscription_id, setExistingStripeSubscriptionId] = useState<string>("");
    const [existing_stripe_subscription_description, setExistingStripeSubscriptionDescription] = useState<string>("");
    const [existing_stripe_subscription_locked, setExistingStripeSubscriptionLocked] = useState<boolean>(false);
    const [models, setModels] = useState<st.IModel[]>([]);
    const [is_models_saving, setIsModelsSaving] = useState<boolean>(false);

    const [error_message, setErrorMessage] = useState<string>("");

    const [manual_price, setManualPrice] = useState<number>(100);
    const [manual_credits, setManualCredits] = useState<number>(1000);
    const [manual_dom, setManualDayOfMonth] = useState<number>(1);

    const UNKNOWN_UUID = "unknown-uuid";

    const tabs = [
        { name: "Users", key: "users" },
        { name: "Extract Jobs", key: "extract_jobs" },
        { name: "Audit Log", key: "audit_log" },
        { name: "Subscription", key: "subscription" },
        { name: "Models", key: "models" },
    ];

    // prepare list of available day-of-month values
    // show up to 28, other dates are not present in each month. unsupported for now.
    const days_of_month: string[] = [];
    for (let i = 1; i <= 28; i++) {
        days_of_month.push(i.toString());
    }

    useEffect(() => {
        if (!org_uuid) { return; }
        Backend.getAdminOrg({ org_uuid })
            .then(({ org, users, balance, sso }) => {
                setOrg(org);
                setUsers(users);
                setBalance(balance);
                setSso(sso);
                loadSubscriptionData();
            });
    }, [org_uuid]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        BackendObj.extractions.getModels({})
            .then(({ models }) => {
                setModels(models);
            });
    }, []);

    const loadSubscriptionData = async () => {
        setErrorMessage("");
        try {
            setManualSubscription(null);
            const { can_use_stripe } = await BackendObj.stripe.getStripeOptions({ org_uuid });
            setStripeEnabled(can_use_stripe);

            const { subscriptions } = await BackendObj.extractions.getSubscriptions({ org_uuid, active: "true" });
            const manual_subs = subscriptions.filter(x => x.provider === "manual");
            const stripe_subs = subscriptions.filter(x => x.provider === "stripe");
            if (manual_subs.length >= 1) {
                setManualSubscription(subscriptions[0]);
                setManualPrice(subscriptions[0].price);
                setManualCredits(subscriptions[0].details.quantity ?? 1000);
                setManualDayOfMonth(subscriptions[0].details.manual_subscription_day_of_month ?? 1);
                if (stripe_enabled) {
                    // manually force stripe to be disabled
                    // we cannot have enabled stripe and manual subscription
                    await setStripeEnabledMain(false);
                }
            } else {
                setManualSubscription(null);
            }

            if (stripe_subs.length === 1) {
                setStripeSubscription(stripe_subs[0]);
                if (org_uuid !== undefined) {
                    const { invoices } = await BackendObj.stripe.getStripeInvoices({ org_uuid });
                    setInvoices(invoices);
                }
            } else {
                setStripeSubscription(null);
            }
        } catch (err: any) {
            setErrorMessage(err.message);
        }
    }

    const setOrgTag = async (tag: "admin_org" | "eval_check_credits" | "sso_enabled", enabled: boolean) => {
        if (!org) { return; }
        await Backend.setOrganizationTagFlag({ org_uuid: org.uuid, tag, flag: enabled });
        const { org: new_org } = await Backend.getAdminOrg({ org_uuid: org.uuid });
        setOrg(new_org);
    }

    const setStripeEnabledMain = async (enabled: boolean) => {
        if (!org) { return; }
        setStripeEnabled(enabled); // update gui to make quick impression
        await BackendObj.stripe.setStripeFlagForOrg({ org_uuid: org.uuid, can_use_stripe: enabled });
        const { can_use_stripe } = await BackendObj.stripe.getStripeOptions({ org_uuid: org.uuid });
        setStripeEnabled(can_use_stripe); // update gui to show real value
    };

    const enableManualSubscription = async () => {
        if (!org_uuid) { return; }
        setManualCredits(1000);
        setManualPrice(100);
        setManualDayOfMonth(1);
        // create dummy subscription record, wont be sent anywhere, just helps keep GUI code cleaner
        setManualSubscription({
            created_at: Date.now(),
            details: {},
            customer_external_id: "",
            end_ts: null,
            external_id: UNKNOWN_UUID,
            org_uuid,
            org_title: "",
            org_type: "personal",
            plan_amount: 1000,
            plan_currency: "default",
            plan_id: "default",
            plan_interval: "monthly",
            plan_name: "default",
            price: 100,
            provider: "manual",
            start_ts: Date.now(),
            status: "active",
            type: "manual",
            uuid: UNKNOWN_UUID,
        });
        setManualSubscriptionEdit(true);
    };
    const disableManualSubscription = async () => {
        setErrorMessage("");
        try {
            if (manual_subscription === null) { return; }
            await BackendObj.extractions.cancelSubscription({ id: manual_subscription.uuid });
            await loadSubscriptionData();
        } catch (err: any) {
            setErrorMessage(err.message);
        }
    };
    const updateManualSubscription = async () => {
        setErrorMessage("");
        try {
            if (!org_uuid) { return; }
            if (manual_subscription === null || manual_subscription.uuid === UNKNOWN_UUID) {
                // create new manual subscription
                await BackendObj.extractions.createNewManualSubscription({
                    credits: manual_credits,
                    day_of_month: manual_dom,
                    freq: "monthly",
                    org_uuid: org_uuid,
                    price: manual_price,
                });
            } else {
                // update manual subscription
                await BackendObj.extractions.updateSubscription({
                    credits: manual_credits,
                    day_of_month: manual_dom,
                    freq: "monthly",
                    id: manual_subscription.uuid,
                    org_uuid: org_uuid,
                    price: manual_price,
                });
            }
            setManualSubscriptionEdit(false);
            await loadSubscriptionData();
        } catch (err: any) {
            setErrorMessage(err.message);
        }
    };
    const cancelManualSubscriptionEdit = async () => {
        setManualSubscriptionEdit(false);
        // remove dummy subscription record if it was not saved
        if (manual_subscription !== null && manual_subscription.uuid === UNKNOWN_UUID) {
            setManualSubscription(null);
        }
    };
    const verifyCandidateSubscription = async () => {
        setErrorMessage("");
        setExistingStripeSubscriptionDescription("Verifying...");
        try {
            const res = await BackendObj.stripe.verifySubscriptionsSync({
                org_uuid: org_uuid ?? "UNKNOWN",
                stripe_subscription_id: existing_stripe_subscription_id,
            });
            setExistingStripeSubscriptionDescription([
                res.message ?? "Verification successful, subscription can be associated with this organization.",
                "Credits: " + res.credits_num,
                "Quantity: " + res.quantity,
                "Existing invoices: " + res.invoices.map(x => x.id + " (" + x.amount + ")").join(", "),
            ].join("\n"));
            setExistingStripeSubscriptionLocked(res.message === undefined);
        } catch (err: any) {
            setExistingStripeSubscriptionDescription("");
            setErrorMessage(err.message);
        }
    };

    const associateCandidateSubscription = async () => {
        setErrorMessage("");
        setExistingStripeSubscriptionDescription("Associating subscription with organization...");
        try {
            await BackendObj.stripe.associateSubscriptionsSync({
                org_uuid: org_uuid ?? "UNKNOWN",
                stripe_subscription_id: existing_stripe_subscription_id,
            });
            await loadSubscriptionData();
            setExistingStripeSubscriptionId("");
            setExistingStripeSubscriptionDescription("");
        } catch (err: any) {
            setErrorMessage(err.message);
        }
    };

    const unlockCandidateSubscription = async () => {
        setExistingStripeSubscriptionLocked(false);
        setExistingStripeSubscriptionDescription("");
    };

    const handleModelNameChange = (model_name: string, field_name: "default" | "default_fast" | "scrape_extract" | "scrape_heal" | "scrape_summarize" | "scrape_focused_summarize" | "decimal_separator" | "context_suggest") => {
        setOrg((prev_org) => {
            if (!prev_org) { return prev_org; }
            return {
                ...prev_org,
                models_overrides: {
                    ...prev_org.models_overrides,
                    [field_name]: (model_name === "/") ? undefined : model_name
                }
            };
        });
    };

    const handleModelOverridesSave = async () => {
        if (!org) { return; }
        setIsModelsSaving(true);
        await BackendObj.extractions.updateModelOverrides({
            org_uuid: org.uuid,
            models_overrides: org.models_overrides,
        });
        setIsModelsSaving(false);
    }

    if (org === undefined) {
        return <div className={classNames("flex-row lg:fixed lg:right-0 lg:inset-y-0 overflow-y-auto", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
            <LoadingSpinner />
        </div>
    }

    const model_names = models.map((model) => model.name);

    return <Fragment>
        <div className={classNames("flex-row lg:fixed lg:right-0 lg:inset-y-0 overflow-y-auto", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>

            {error_message !== "" && <div className="sm:grid sm:grid-cols-4 sm:items-start mt-4 sm:mt-0">
                <div className="py-5 sm:col-span-3 text-sm text-red-600">
                    Error: {error_message}
                </div>
            </div>}

            <div className="m-16 mb-6">
                <h2 className="text-xl font-semibold leading-7 text-gray-600 sm:truncate sm:text-3xl sm:tracking-tight">
                    Organization "{org?.name}"
                </h2>
                <div className="pt-5 border-b-4 border-sky-600" />
            </div>

            <div className="px-10 py-5">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-4">
                    <div className="py-2">
                        <h3 className="text-md font-semibold text-gray-800">Uuid:</h3>
                        <div className="text-sm text-gray-600 mt-2">
                            <CopyTextbox text={org.uuid} />
                        </div>
                    </div>

                    <div className="py-2">
                        <h3 className="text-md font-semibold text-gray-800">Type:</h3>
                        <div className="text-sm text-gray-600 mt-2">
                            <ReadOnlyTextbox text={org.type as string} />
                        </div>
                    </div>

                    <div className="py-2">
                        <h3 className="text-md font-semibold text-gray-800">Admin ORG:</h3>
                        <div className="text-sm text-gray-600 mt-2">
                            <Dropdown
                                values={["Yes", "No"]}
                                selected={org.tags.admin_org ? "Yes" : "No"}
                                onChange={(value) => setOrgTag("admin_org", value === "Yes")} />
                        </div>
                    </div>

                    <div className="py-2">
                        <h3 className="text-md font-semibold text-gray-800">Eval uses credits:</h3>
                        <div className="text-sm text-gray-600 mt-2">
                            <Dropdown
                                values={["Yes", "No"]}
                                selected={org.tags.eval_check_credits ? "Yes" : "No"}
                                onChange={(value) => setOrgTag("eval_check_credits", value === "Yes")} />
                        </div>
                    </div>

                    <div className="py-2">
                        <h3 className="text-md font-semibold text-gray-800">SSO enabled:</h3>
                        <div className="text-sm text-gray-600 mt-2">
                            <Dropdown
                                values={["Yes", "No"]}
                                selected={org.tags.sso_enabled ? "Yes" : "No"}
                                onChange={(value) => setOrgTag("sso_enabled", value === "Yes")} />
                        </div>
                    </div>

                    {sso && <div className="py-2">
                        <h3 className="text-md font-semibold text-gray-800">Provider:</h3>
                        <div className="text-sm text-gray-600 mt-2">
                            <ReadOnlyTextbox text={sso.provider} />
                        </div>
                    </div>}
                    {sso && <div className="py-2">
                        <h3 className="text-md font-semibold text-gray-800">Email domain:</h3>
                        <div className="text-sm text-gray-600 mt-2">
                            <CopyTextbox text={sso.email_suffix} />
                        </div>
                    </div>}
                    {sso && <div className="py-2">
                        <h3 className="text-md font-semibold text-gray-800">Tenant ID:</h3>
                        <div className="text-sm text-gray-600 mt-2">
                            <CopyTextbox text={sso.tenant_id} />
                        </div>
                    </div>}
                    {sso && <div className="py-2">
                        <h3 className="text-md font-semibold text-gray-800">Client ID:</h3>
                        <div className="text-sm text-gray-600 mt-2">
                            <CopyTextbox text={sso.client_id} />
                        </div>
                    </div>}
                </div>
            </div>

            <div className="px-10">
                <div className="py-4 grid grid-cols-1">
                    <table className="w-full divide-y divide-gray-300 border-gray-300 border">
                        <thead>
                            <tr>
                                <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Type</th>
                                <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Credit Balance</th>
                                <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Transactions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {org && balance !== undefined && <tr>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{org.type}</td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{balance}</td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-right w-10">
                                    <Button onClick={() => setOpenTransactionHistory(true)} text="History" icon={BookOpenIcon} highlight={false} />
                                </td>
                            </tr>}
                            {balance === undefined && <tr><td className="text-center p-2" colSpan={4}><LoadingSpinnerLimit /></td></tr>}
                        </tbody>
                    </table>
                </div>
            </div>

            <div className="p-10">
                <Tabs tabs={tabs} selected_tab_key={selected_tab} setSelectedTab={setSelectedTab} />
                {selected_tab === "users" && <div className="my-4 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-300">
                        <thead className="bg-gray-50">
                            <tr>
                                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                    Name
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Role
                                </th>
                                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6"></th>
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                            {users && users.map((person) => (
                                <tr key={person.email}>
                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                        <div className="flex items-center">
                                            <div className="h-11 w-11 flex-shrink-0">
                                                <img className="h-11 w-11 rounded-full" src={person.picture_url} alt="" />
                                            </div>
                                            <div className="ml-4">
                                                <div className="font-medium text-gray-900">{person.first_name + " " + person.last_name}</div>
                                                <div className="mt-1 text-gray-500">{person.email}</div>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">{person.role}</td>
                                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                        <Link to={`/admin/user/${person.uuid}`} className="text-sky-600 hover:text-sky-900">Edit</Link>
                                    </td>
                                </tr>
                            ))}
                            {!users && <tr>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6" colSpan={4}>
                                    <LoadingSpinnerLimit />
                                </td>
                            </tr>}
                        </tbody>
                    </table>
                </div>}
                {selected_tab === "extract_jobs" && <div className="my-4 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                    <ExtractJobs type="admin_org" org_uuid={org_uuid} org_users={users} />
                </div>}
                {selected_tab === "audit_log" && org_uuid && <div className="my-4 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                    <OrgAuditLog org_uuid={org_uuid} is_admin={true} />
                </div>}
                {selected_tab === "subscription" && org_uuid && <div className="my-4 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                    <div className="px-10 py-5">
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-4">
                            <div className="py-2">
                                <h3 className="text-md font-semibold text-gray-800">Manual subscription:</h3>
                                <div className="text-sm text-gray-600 mt-2">
                                    {manual_subscription !== null && manual_subscription_edit && <Button
                                        onClick={updateManualSubscription} text="Save" />}
                                    {manual_subscription !== null && manual_subscription_edit && <Button
                                        onClick={cancelManualSubscriptionEdit} text="Cancel" />}
                                    {manual_subscription === null && !manual_subscription_edit && <Button
                                        onClick={enableManualSubscription} text="Enable subscription" />}
                                    {manual_subscription !== null && !manual_subscription_edit && <Button
                                        onClick={() => setManualSubscriptionEdit(true)} text="Edit subscription" icon={PencilIcon}></Button>}
                                    {manual_subscription !== null && !manual_subscription_edit && <Button
                                        onClick={disableManualSubscription} text="Disable subscription" icon={TrashIcon} />}
                                </div>
                            </div>
                            <div className="py-2">
                                &nbsp;
                            </div>
                            <div className="py-2">
                                &nbsp;
                            </div>
                            <div className="py-2">
                                <h3 className="text-md font-semibold text-gray-800">Credits:</h3>
                                {manual_subscription !== null && <div className="text-sm text-gray-600 mt-2">
                                    {!manual_subscription_edit && <span>{manual_credits}</span>}
                                    {manual_subscription_edit && <Textbox value={"" + manual_credits} onChange={val => setManualCredits(+val)} />}
                                </div>}
                            </div>
                            <div className="py-2">
                                <h3 className="text-md font-semibold text-gray-800">Amount:</h3>
                                {manual_subscription !== null && <div className="text-sm text-gray-600 mt-2">
                                    {!manual_subscription_edit && <span>{manual_price}</span>}
                                    {manual_subscription_edit && <Textbox value={"" + manual_price} onChange={val => setManualPrice(+val)} />}
                                </div>}
                            </div>
                            <div className="py-2">
                                <h3 className="text-md font-semibold text-gray-800">Day-of-month:</h3>
                                {manual_subscription !== null && <div className="text-sm text-gray-600 mt-2">
                                    {!manual_subscription_edit && <span>{manual_dom}</span>}
                                    {manual_subscription_edit && <Dropdown
                                        values={days_of_month}
                                        selected={"" + manual_dom}
                                        onChange={(value) => setManualDayOfMonth(+value)} />}
                                </div>}
                            </div>
                        </div>
                    </div>

                    <div className="px-10 py-5">
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-4">
                            <div className="py-2">
                                <h3 className="text-md font-semibold text-gray-800">Enable Stripe:</h3>
                                <div className="text-sm text-gray-600 mt-2">
                                    <Toggle enabled={stripe_enabled && manual_subscription === null} setEnabled={() => setStripeEnabledMain(!stripe_enabled)} />
                                </div>
                            </div>
                            <div className="py-2">
                                <h3 className="text-md font-semibold text-gray-800">Stripe subscription:</h3>
                                {stripe_subscription === null && <div>
                                    <div className="text-sm text-gray-600 mt-2">
                                        No Stripe subscription currently associated with this organization.
                                    </div>
                                    {stripe_enabled && <div className="mt-4">
                                        Associate with existing Stripe subscription:
                                        <div className="mt-4">
                                            <Textbox
                                                placeholder="Subscription ID from Stripe, e.g. sub_1234567890....."
                                                value={existing_stripe_subscription_id}
                                                onChange={setExistingStripeSubscriptionId}
                                                disabled={existing_stripe_subscription_locked} />
                                        </div>
                                        <div className="mt-4">
                                            {!existing_stripe_subscription_locked && <Button
                                                onClick={verifyCandidateSubscription}
                                                icon={CheckCircleIcon}
                                                text="Verify" />}

                                            {existing_stripe_subscription_locked && <Button
                                                onClick={associateCandidateSubscription}
                                                icon={BoltIcon}
                                                text="Associate" />}

                                            {existing_stripe_subscription_locked && <Button
                                                onClick={unlockCandidateSubscription}
                                                text="Unlock" />}

                                            {existing_stripe_subscription_description !== "" && <div className="text-sm text-gray-600 mt-4">
                                                {existing_stripe_subscription_description}
                                            </div>}
                                        </div>
                                    </div>}
                                </div>}
                                {stripe_subscription !== null && <div>
                                    <div className="text-sm text-gray-600 mt-2 mb-2">
                                        Status: {stripe_subscription.status} <br />
                                        Price: {stripe_subscription.price} <br />
                                        Started at: {prettySmartDateTime(stripe_subscription.start_ts)} <br />
                                        Link: <Link
                                            className="text-sky-600 hover:text-sky-900"
                                            to={`https://dashboard.stripe.com/subscriptions/${stripe_subscription.external_id}`}>Details</Link>
                                    </div>

                                    {(invoices === undefined || invoices.length === 0) && <div className="p-5">No data</div>}
                                    {invoices !== undefined && invoices.length > 0 && <table className="min-w-full divide-y divide-gray-300">
                                        <thead className="bg-gray-50">
                                            <tr>
                                                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                    Date
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    Amount
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    Invoice Number
                                                </th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody className="divide-y divide-gray-200 bg-white">
                                            {invoices.map((invoice) => (
                                                <tr key={invoice.id}>
                                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                        {prettyDate(invoice.created_at)}
                                                    </td>
                                                    <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                                                        {prettyNumber(invoice.amount, 2)}
                                                    </td>
                                                    <td>
                                                        {invoice.number}
                                                    </td>
                                                    <td>
                                                        {invoice.url && <a href={invoice.url} target="_blank" rel="noreferrer">View</a>}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>}
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>}

                {selected_tab === "models" && org && <div className="my-4 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg p-6">
                    <div className="text-sm text-gray-600 pb-2 flex flex-col gap-4">
                        <p>When specified, model overrides for organization will be used instead of the default ones for all templates belonging to this org.</p>
                        <p>Template model overrides override the organization-wide settings.</p>
                    </div>
                    <div className="sm:grid sm:grid-cols-6 sm:items-start sm:gap-4 sm:py-6">
                        <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-800 sm:pt-1.5">
                            Extract
                        </label>
                        <div className="mt-2 sm:mt-0 sm:col-span-2">
                            <div className="flex w-full max-w-xs rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-sky-600">
                                <Dropdown
                                    values={["/", ...model_names]}
                                    selected={org.models_overrides.scrape_extract || "/"}
                                    onChange={(model_name) => { handleModelNameChange(model_name, "scrape_extract"); }} />
                            </div>
                        </div>

                        <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-800 sm:pt-1.5">
                            Heal
                        </label>
                        <div className="mt-2 sm:mt-0 sm:col-span-2">
                            <div className="flex w-full max-w-xs rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-sky-600">
                                <Dropdown
                                    values={["/", ...model_names]}
                                    selected={org.models_overrides.scrape_heal || "/"}
                                    onChange={(model_name) => { handleModelNameChange(model_name, "scrape_heal"); }} />
                            </div>
                        </div>

                        <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-800 sm:pt-1.5">
                            Summarize
                        </label>
                        <div className="mt-2 sm:mt-0 sm:col-span-2">
                            <div className="flex w-full max-w-xs rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-sky-600">
                                <Dropdown
                                    values={["/", ...model_names]}
                                    selected={org.models_overrides.scrape_summarize || "/"}
                                    onChange={(model_name) => { handleModelNameChange(model_name, "scrape_summarize"); }} />
                            </div>
                        </div>

                        <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-800 sm:pt-1.5">
                            Focused Summarize
                        </label>
                        <div className="mt-2 sm:mt-0 sm:col-span-2">
                            <div className="flex w-full max-w-xs rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-sky-600">
                                <Dropdown
                                    values={["/", ...model_names]}
                                    selected={org.models_overrides.scrape_focused_summarize || "/"}
                                    onChange={(model_name) => { handleModelNameChange(model_name, "scrape_focused_summarize"); }} />
                            </div>
                        </div>

                        <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-800 sm:pt-1.5">
                            Decimal Separator
                        </label>
                        <div className="mt-2 sm:mt-0 sm:col-span-2">
                            <div className="flex w-full max-w-xs rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-sky-600">
                                <Dropdown
                                    values={["/", ...model_names]}
                                    selected={org.models_overrides.decimal_separator || "/"}
                                    onChange={(model_name) => { handleModelNameChange(model_name, "decimal_separator"); }} />
                            </div>
                        </div>

                        <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-800 sm:pt-1.5">
                            Template suggest
                        </label>
                        <div className="mt-2 sm:mt-0 sm:col-span-2">
                            <div className="flex w-full max-w-xs rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-sky-600">
                                <Dropdown
                                    values={["/", ...model_names]}
                                    selected={org.models_overrides.context_suggest || "/"}
                                    onChange={(model_name) => { handleModelNameChange(model_name, "context_suggest"); }} />
                            </div>
                        </div>

                        <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-800 sm:pt-1.5">
                            Default
                        </label>
                        <div className="mt-2 sm:mt-0 sm:col-span-2">
                            <div className="flex w-full max-w-xs rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-sky-600">
                                <Dropdown
                                    values={["/", ...model_names]}
                                    selected={org.models_overrides.default || "/"}
                                    onChange={(model_name) => { handleModelNameChange(model_name, "default"); }} />
                            </div>
                        </div>

                        <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-800 sm:pt-1.5">
                            Default fast
                        </label>
                        <div className="mt-2 sm:mt-0 sm:col-span-2">
                            <div className="flex w-full max-w-xs rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-sky-600">
                                <Dropdown
                                    values={["/", ...model_names]}
                                    selected={org.models_overrides.default_fast || "/"}
                                    onChange={(model_name) => { handleModelNameChange(model_name, "default_fast"); }} />
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row items-center">
                        <Button
                            text="Update"
                            highlight={true}
                            loading={is_models_saving}
                            disabled={is_models_saving}
                            onClick={handleModelOverridesSave} />
                    </div>
                </div>}
            </div>
        </div>
        {
            org && <TransactionHistory
                is_admin={true}
                org={org}
                open={open_transaction_history}
                setOpen={setOpenTransactionHistory} />
        }
    </Fragment >;
};
