import {
    Fragment,
    useEffect,
    useState
} from "react";
import {
    useNavigate,
    useParams
} from "react-router-dom";

import {
    classNames,
    flattenScrapeDocuments,
    prettySmartDateTime
} from "../lib/utils";
import {
    IItem,
    IItemAttachmentBase
} from "../lib/types";
import { SIMPLE_MIME_TYPES } from "../lib/consts";
import {
    Backend,
    BackendObj
} from "../lib/backend";

import { LoadingSpinner } from "../components/LoadingSpinner";
import { ItemTables } from "../components/ItemTables";
import { LongText } from "../components/LongText";
import { PdfViewerVertical } from "../components/PdfViewer";
import { Toggle } from "../components/Toggle";
import {
    CompactTabs,
    ITab
} from "../components/Tabs";
import { ExcelViewer } from "../components/ExcelViewer";

function EmailContent(props: { item: IItem }) {
    const { item } = props;
    const is_html = item.details.email_html !== undefined;
    return <div className="text-sm p-5 mt-5 border border-gray-200 rounded shadow bg-gray-50">
        <div className="pb-2"><span className="font-bold">From:</span> {item.details.from_email_address}</div>
        {item.details.to_email_addresses && <div className="pb-2 truncate"><span className="font-bold">To:</span> {item.details.to_email_addresses?.join(", ")}</div>}
        {item.details.email_subject && <div className="pb-2"><span className="font-bold">Subject:</span> {item.details.email_subject}</div>}
        {/* render html from item.details.email_html in iframe */}
        {is_html && <iframe
            title="email"
            srcDoc={item.details.email_html}
            style={{ width: "100%", height: "400px" }}
            className="border bg-white mt-2"
            sandbox=""
        />}
        {/* render text from item.text */}
        {!is_html && <LongText text={flattenScrapeDocuments(item.documents)} />}
    </div>
}

function AttachmentContent(props: { attachment: IItemAttachmentBase }) {
    const { attachment } = props;

    const url = `/api/attachment/get?uuid=${attachment.uuid}`;
    const link = <a
        className="text-xs flex truncate text-sky-600 cursor-pointer hover:underline"
        href={url} target="_blank" rel="noreferrer">
        [download]
    </a>

    const lc_filename = attachment.filename.toLowerCase();
    if (
        attachment.mimetype.startsWith("image/") ||
        lc_filename.endsWith(".jpg") ||
        lc_filename.endsWith(".jpeg") ||
        lc_filename.endsWith(".png") ||
        lc_filename.endsWith(".tiff") ||
        lc_filename.endsWith(".bmp") ||
        lc_filename.endsWith(".gif")
    ) {
        return <div className="pt-3">
            <img src={url} alt={attachment.filename} />
            {link}
        </div>;
    }

    if (attachment.mimetype === "application/pdf" || lc_filename.endsWith(".pdf")) {
        return <div className="pt-3">
            <PdfViewerVertical file={url} />
            {link}
        </div>;
    }

    if (lc_filename.endsWith(".xlsx") || lc_filename.endsWith(".xls") || lc_filename.endsWith(".ods")) {
        return <div className="pt-3">
            <ExcelViewer attachment_uuid={attachment.uuid} />
            {link}
        </div>;
    }

    if (SIMPLE_MIME_TYPES.includes(attachment.mimetype)) {
        return <div className="pt-3">
            <div className="text-sm mb-2 px-2 py-4 border border-gray-200 rounded shadow bg-gray-50">
                <LongText text={flattenScrapeDocuments([attachment.document])} />
            </div>
            {link}
        </div>;
    }

    return <div className="p-5">{link}</div>
}

function DocumentContent(props: { item: IItem }) {
    const { item } = props;

    // we have email, render it as such
    return item.attachments.length > 0 ? <AttachmentContent attachment={item.attachments[0]} /> :
        <div className="text-sm p-5 mt-5 border-gray-200 bg-gray-50 rounded shadow">
            {item.details.document_subject && <div className="pb-2 mb-2 border-b"><span className="font-bold">Subject:</span> {item.details.document_subject}</div>}
            <LongText text={flattenScrapeDocuments(item.documents)} />
        </div>;
}

function ItemContent(props: { item: IItem }) {
    const { item } = props;

    const [tabs, setTabs] = useState<ITab[]>([]);
    const [selected_tab, setSelectedTab] = useState<string>("");

    useEffect(() => {
        if (item === undefined) { return; }
        const tabs: ITab[] = [];
        if (item.details.from_email_address !== undefined) {
            tabs.push({ name: "Email", key: "email" });
            for (const attachment of item.attachments) {
                tabs.push({ name: attachment.filename, key: attachment.uuid });
            }
        } else if (item.details.document_subject !== undefined) {
            tabs.push({ name: "Document", key: "doc" });
        } else if (item.details.document_filename !== undefined) {
            tabs.push({ name: "Document", key: "doc" });
        } else {
            tabs.push({ name: "Text", key: "text" });
        }
        setTabs(tabs);
        const first_content_tab = tabs.find(tab => tab.name.endsWith(".pdf")) || tabs[0];
        if (first_content_tab !== undefined) {
            setSelectedTab(first_content_tab.key);
        }
    }, [item]);

    return <Fragment>
        <CompactTabs tabs={tabs} selected_tab_key={selected_tab} setSelectedTab={setSelectedTab} />
        {selected_tab === "email" && <EmailContent item={item} />}
        {selected_tab === "doc" && <DocumentContent item={item} />}
        {selected_tab === "text" && <div className="text-sm p-5 mt-5 border-gray-200 bg-gray-50 rounded shadow">
            <LongText text={flattenScrapeDocuments(item.documents)} />
        </div>}
        {item.attachments.map((attachment, idx) => {
            if (selected_tab === attachment.uuid) {
                return <AttachmentContent key={idx} attachment={attachment} />;
            }
            return null;
        })}
    </Fragment >;
}

export function ItemConfirm() {
    const navigate = useNavigate();
    const { item_uuid } = useParams<{ item_uuid: string | undefined }>();

    const [item, setItem] = useState<IItem | undefined>(undefined);
    const [invalid_uuid, setInvalidUuid] = useState(false);
    const [show_split, _setShowSplit] = useState(localStorage.getItem("show_confirm_split") ? localStorage.getItem("show_confirm_split") === "true" : true);
    const [left_width, setLeftWidth] = useState(localStorage.getItem("confirm_split_width") ? parseInt(localStorage.getItem("confirm_split_width") as string) : 50);
    const [is_dragging, setIsDragging] = useState(false);

    useEffect(() => {
        if (item_uuid === undefined) { return; }
        Backend.getItem({ item_uuid })
            .then(setItem)
            .catch(() => setInvalidUuid(true));
    }, [item_uuid]);

    const setShowSplit = (show: boolean) => {
        _setShowSplit(show);
        localStorage.setItem("show_confirm_split", show.toString());
    }

    /// COLUMN DRAGGING

    const startDragging = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault(); // Prevent text selection
        setIsDragging(true);
    };

    const stopDragging = () => {
        if (is_dragging) { setIsDragging(false); }
    };

    const drag = (e: MouseEvent) => {
        if (is_dragging) {
            // offset all coordinates for the size of the sidebar (256px or 80px)
            const client_x = e.clientX - 80;
            const window_width = window.innerWidth - 80;
            // compute the new width as a percentage of the window width
            const new_width = (client_x / window_width) * 100;
            setLeftWidth(new_width);
            localStorage.setItem("confirm_split_width", new_width.toString());
        }
    };

    useEffect(() => {
        window.addEventListener('mousemove', drag);
        window.addEventListener('mouseup', stopDragging);

        return () => {
            window.removeEventListener('mousemove', drag);
            window.removeEventListener('mouseup', stopDragging);
        };
    });

    const onItemUpdate = async () => {
        if (item === undefined) { return; }
        // get next item uuid if it exists
        const { next_item_uuid } = await BackendObj.extractions.getExtractConfirmationNextItem({ item_uuid: item.uuid });
        if (next_item_uuid) {
            setItem(undefined);
            navigate(`/confirm/${next_item_uuid}`);
        } else {
            navigate(`/`);
        }
    }

    /// RENDER

    if (item_uuid === undefined || invalid_uuid) {
        return <div className={classNames("flex-row lg:fixed lg:right-0 lg:inset-y-0 overflow-y-auto m-10 lg:left-20")}>
            Item not found
        </div>;
    }

    if (item === undefined) {
        return <div className={classNames("flex-row lg:fixed lg:right-0 lg:inset-y-0 overflow-y-auto lg:left-20")}>
            <LoadingSpinner />
        </div>;
    }

    return <div className={classNames("flex lg:fixed lg:right-0 lg:inset-y-0 overflow-y-auto lg:left-20")}>
        {/* Left Column */}
        <div style={show_split ? { width: `${left_width}%` } : {}} className={classNames("h-full overflow-auto", show_split ? "" : "w-full")}>
            <div className="py-4 px-10 flex flex-row justify-end items-center gap-x-2 bg-white border-b-gray-200 border-b w-full">
                <h2 className="text-xl font-semibold leading-7 text-gray-900 sm:truncate sm:text-2xl sm:tracking-tight whitespace-nowrap truncate">
                    {item.name.length > 0 ? item.name : "[no subject]"}
                </h2>
                <div className="ml-2 mt-3 text-xs text-gray-500">
                    [{prettySmartDateTime(item.created_at)}]
                </div>
                <div className="flex-grow" />
                <div className="text-xs text-gray-500 w-44 text-right">Show Input Documents</div>
                <Toggle enabled={show_split} setEnabled={setShowSplit} toggle_color="bg-sky-600" />
            </div>
            <div className="py-4 px-5">
                <ItemTables item={item} onItemUpdate={onItemUpdate} />
            </div>
        </div>
        {/* Separator */}
        {show_split && <div onMouseDown={startDragging} className="cursor-col-resize bg-gray-400 hover:bg-sky-400 w-1 h-full" />}
        {/* Right Column */}
        {show_split && <div style={{ width: `${100 - left_width}%` }} className="h-full overflow-auto" >
            <div className="p-5">
                <ItemContent item={item} />
            </div>
        </div>}
    </div>;
}