import { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { selectIsSidebarLarge, selectMemberships, selectUser } from "../lib/scraper.slice";
import { classNames, redirectToExternalPage, sleep } from "../lib/utils";
import { EndpointType, IContextField, ISuggestContext } from "../lib/types";
import { Backend } from "../lib/backend";

import { DEFAULT_NEW_PARAMS } from "./NewTemplate";

import { Button } from "../components/Button";
import { DragDropArea } from "../components/DragDropArea";
import { FieldsTable } from "../components/FieldsTable";
import { ENDPOINT_TYPE, ORG_TYPES, USER_ROLES } from "../lib/consts";
import { PlusIcon } from "@heroicons/react/24/outline";
import { DidYouKnow } from "../components/DidYouKnow";

export function NewTemplateWizard() {
    const navigate = useNavigate();

    const is_sidebar_large = useSelector(selectIsSidebarLarge);
    const user = useSelector(selectUser);
    const memberships = useSelector(selectMemberships);
    // default is personal org, if not available, use first org
    const default_org_uuid =
        memberships.find((membership) => membership.org.type === ORG_TYPES.personal)?.org.uuid ||
        memberships[0].org.uuid ||
        "";

    const [input_text, setInputText] = useState<string | undefined>(undefined);
    const [input_file, setInputFile] = useState<File | undefined>(undefined);
    const [wizard_step, setWizardStep] = useState<"input" | "processing" | "output">("input");
    const [suggested_name, setSuggestedName] = useState<string | undefined>(undefined);
    const [contexts, setContexts] = useState<ISuggestContext[]>([]);
    const [saved_contexts, setSavedContexts] = useState<{ idx: number, uuid: string }[]>([]);
    const [is_committing, setIsCommitting] = useState<boolean>(false);
    const [committing_idx, setCommittingIdx] = useState<number | undefined>(undefined);
    const [error_message, setErrorMessage] = useState<string | undefined>(undefined);

    const updateText = (text: string, filename?: string) => {
        setWizardStep("input");
        setInputText(text);
        setInputFile(undefined);
    }

    const updateFiles = (files: File[]) => {
        setWizardStep("input");
        // suggest only works with one file
        setInputFile(files[0]);
        setInputText(undefined);
    }

    const clearInput = () => {
        setWizardStep("input");
        setInputText(undefined);
        setInputFile(undefined);
        setContexts([]);
        setSavedContexts([]);
    }

    const suggestTemplate = async () => {
        setWizardStep("processing");
        setErrorMessage(undefined);
        setContexts([]);
        setSavedContexts([]);
        // try to get suggestion
        try {
            const job_uuid = await Backend.suggestContextStart({ input_text, input_file });
            while (true) {
                const result = await Backend.suggestContextGet({ job_uuid });
                if (result.status === "done") {
                    setSuggestedName(result.name || "New Endpoint");
                    setContexts(result.contexts ?? []);
                    setWizardStep("output");
                    break;
                } else if (result.status === "error") {
                    setErrorMessage(result.message);
                    setWizardStep("input");
                    break;
                }
                await sleep(500);
            }
        } catch (err: any) {
            setErrorMessage(err.message);
            setWizardStep("input");
        }
    }

    const setFields = (context_idx: number, fields: IContextField[]) => {
        const new_contexts = [...contexts];
        new_contexts[context_idx].fields = fields;
        setContexts(new_contexts);
    }

    const createTemplate = async (idx: number) => {
        const { context_name, fields, output_type } = contexts[idx];
        // make sure we have valid context
        if (context_name === "") {
            setErrorMessage("Please provide a name for the context.");
            return;
        }
        const valid_fields = fields.filter(f => f.name !== "");
        if (valid_fields.length === 0) {
            setErrorMessage("Please provide at least one field for the context.");
            return;
        }
        try {
            setIsCommitting(true);
            setCommittingIdx(idx);
            const { uuid } = await Backend.commitContext({
                context_name,
                org_uuid: default_org_uuid,
                facts: DEFAULT_NEW_PARAMS.facts,
                fields: valid_fields,
                postprocess: DEFAULT_NEW_PARAMS.postprocess,
                extract_params: {
                    prompt_output_format: DEFAULT_NEW_PARAMS.prompt_output_format,
                    remove_duplicate_records: DEFAULT_NEW_PARAMS.remove_duplicate_records,
                    default_decimal_separator: DEFAULT_NEW_PARAMS.default_decimal_separator,
                    detect_decimal_separator: DEFAULT_NEW_PARAMS.detect_decimal_separator,
                    extraction_strategy: DEFAULT_NEW_PARAMS.extraction_strategy,
                    try_auto_heal: DEFAULT_NEW_PARAMS.try_auto_heal,
                    preprocess_excel_strategy: DEFAULT_NEW_PARAMS.preprocess_excel_strategy,
                    preprocess_ocr_strategy: DEFAULT_NEW_PARAMS.preprocess_ocr_strategy,
                    orientation_segments_strategy: DEFAULT_NEW_PARAMS.orientation_segments_strategy,
                    max_partial_responses: DEFAULT_NEW_PARAMS.max_partial_responses,
                    models_overrides: DEFAULT_NEW_PARAMS.models_overrides,
                    admin_prompts: DEFAULT_NEW_PARAMS.admin_prompts
                },
                output_type: output_type,
                examples: []
            });
            // remember we saved this context
            setSavedContexts([...saved_contexts.filter(c => c.idx !== idx), { idx, uuid }]);
            setCommittingIdx(undefined);
            setIsCommitting(false);
        } catch (err: any) {
            setErrorMessage(err.message);
            setIsCommitting(false);
        }
    };

    const createEndpoint = async () => {
        if (saved_contexts.length === 0) { return; }
        try {
            setIsCommitting(true);
            // first commit each context
            const context_uuids = saved_contexts.map(c => c.uuid);
            // commit endpoint
            const { endpoint_uuid, message } = await Backend.createEndpoint({
                org_uuid: default_org_uuid,
                user_uuid: user.uuid,
                type: ENDPOINT_TYPE.email as EndpointType,
                name: suggested_name || "New Endpoint",
                description: "Automatically generated endpoint",
                ip_whitelist: [],
                details: {
                    store_extractions: true,
                    require_confirmation: "never",
                    require_confirmation_users: [],
                    join_object_extractions: false,
                    only_process_attachments: false,
                    unit_of_processing: "all",
                    reply_to_sender: true,
                    reply_file_format: "excel",
                    reply_file_extension: "",
                    reply_file_mimetype: "",
                    reply_include_input: true,
                    forward_email_address: "",
                    forward_email_frequency: "per_job",
                    forward_email_digest_hour_utc: 6,
                    passthrough_email_address: "",
                    passthrough_email_subject: [],
                    webhook_url: "",
                    webhook_include_input: false,
                    webhook_version: "v2",
                    webhook_retry_count: 0,
                    webhook_store_payload: "none",
                    truncate_long_text: true,
                    handlebar_template: ""
                },
                output_sheets: [],
                output_name: [],
                context_uuids,
            });
            if (message) {
                setErrorMessage(message);
                setIsCommitting(false);
                return;
            }
            navigate(`/extraction/new/${endpoint_uuid}`);
        } catch (err: any) {
            setErrorMessage(err.message);
            setIsCommitting(false);
        }
    }

    return <div className={classNames("flex-row lg:fixed lg:right-0 lg:inset-y-0 overflow-y-auto", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
        <div className={classNames("z-50 h-16 bg-white border-b-gray-200 border-b lg:fixed lg:right-0", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
            <div className="px-10 py-4 lg:max-w-5xl">
                <div className="flex flex-row items-center">
                    <h2 className="text-xl font-semibold leading-7 text-gray-900 sm:truncate sm:text-2xl sm:tracking-tight">
                        Upload Your Document
                    </h2>
                </div>
            </div>
        </div>

        {wizard_step === "input" && <div className="px-10 mt-10 lg:pt-12 max-w-5xl">
            <div className="flow flow-col sm:items-start sm:gap-8 sm:py-3 text-gray-600">
                <div className="pb-3 grid items-center">
                    Provide a sample document and let our AI suggest the most appropriate templates.
                </div>
                <div className="pb-3 grid items-center">
                    The document can be in plain text, Excel or PDF file.
                </div>

                <div className="py-3 grid items-center">
                    <DragDropArea
                        name="body"
                        rows={15}
                        text={input_text}
                        file={input_file}
                        setText={updateText}
                        setFiles={updateFiles}
                        setClear={clearInput}
                        setErrorMessage={setErrorMessage}
                    />
                </div>
            </div>
        </div>}

        {error_message !== undefined && <div className="px-10 lg:max-w-5xl">
            <div className="mx-1 text-sm text-red-600">
                Error: {error_message}
            </div>
        </div>}

        {(input_text || input_file) && (wizard_step === "input") && <div className="text-center px-10 py-5 lg:max-w-5xl">
            <Button
                text="Suggest Template"
                highlight={true}
                disabled={wizard_step !== "input"}
                onClick={suggestTemplate} />
        </div>}

        {wizard_step === "processing" && <div className="px-10 mt-10 pb-10 lg:pt-14 flex flex-col min-h-screen max-w-5xl">
            <div className="flex-grow px-10 py-16 mx-auto">
                <div className="flex flex-col items-center justify-center py-8">
                    <h1 className="text-4xl font-bold mb-4 text-gray-600">Scanning your document<span className="dots" /></h1>
                </div>
                <div className="flex flex-col items-center justify-center w-full py-8">
                    <div className="max-w-lg relative">
                        <DidYouKnow change_seconds={10} />
                        <div className="absolute inset-0 m-auto pb-20" style={{ width: "50px", height: "50px" }}>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-col items-center justify-center py-8 text-gray-400 text-sm">
                <p className="max-w-xl py-2 text-center">
                    Please wait while we analyze your document using our AI<br /> and identify best templates.
                </p>
                <p className="max-w-xl py-2 text-center">
                    This may take around 60 seconds.
                </p>
            </div>
        </div>}

        {wizard_step === "output" && contexts.length === 0 && <div className="mx-10 mt-10 lg:pt-14 pb-4 lg:max-w-5xl ">
            <div className="flex flex-row items-start">
                <div className="flex flex-col">
                    <p className="py-2">
                        We could not find any templates based on your document.
                    </p>
                    <p className="py-2">
                        Please try another document or create a template manually.
                    </p>
                </div>
                <div className="flex-grow" />
                <div className="flex flex-col gap-y-2">
                    <Button
                        text="Try Another Document"
                        disabled={is_committing}
                        highlight={true}
                        onClick={clearInput} />
                    <Button
                        text="Create Manually"
                        disabled={is_committing}
                        onClick={() => navigate("/template/new")} />
                </div>
            </div>
        </div>}

        {wizard_step === "output" && contexts.length > 0 && <div className="mx-10 mt-10 lg:pt-14 pb-4 lg:max-w-5xl ">
            <div className="flex flex-row items-start">
                <div className="flex flex-col">
                    <p className="py-2">
                        The following templates are suggested based on your document.
                    </p>
                    <p className="py-2">
                        You can save multiple templates and create an endpoint combining them.
                    </p>
                </div>
                <div className="flex-grow" />
                <div className="flex flex-col gap-y-2">
                    {user.role !== USER_ROLES.free && <Button
                        text="Create and Test Endpoint"
                        disabled={is_committing || saved_contexts.length === 0}
                        onClick={createEndpoint} />}
                    <Button
                        text="Try Another Document"
                        disabled={is_committing}
                        onClick={clearInput} />
                </div>
            </div>
            <div className="pt-10">
                <h1 className="text-2xl font-bold">{suggested_name}</h1>
            </div>
        </div>}

        {wizard_step === "output" && contexts.map((context, idx) => (<Fragment key={idx}>
            <div className="mx-10 py-10">
                <div className="flex flex-row items-center pb-5 gap-x-2 lg:max-w-5xl">
                    {saved_contexts.find(c => c.idx === idx) === undefined &&
                        <Button
                            icon={idx !== committing_idx ? PlusIcon : undefined}
                            text={idx !== committing_idx ? "Create" : "Creating..."}
                            loading={idx === committing_idx}
                            disabled={is_committing}
                            onClick={() => createTemplate(idx)} />}
                    {saved_contexts.find(c => c.idx === idx) !== undefined &&
                        <Button
                            text="Test"
                            onClick={() => redirectToExternalPage(`/extraction/new/${saved_contexts.find(c => c.idx === idx)?.uuid}`, true)} />}
                    <div className="text-xl text-gray-600 font-bold">
                        {context.context_name}
                    </div>
                </div>
                <div className="outer-div">
                    <FieldsTable
                        fields={context.fields}
                        records={context.example_records}
                        indicate_array={context.output_type === "array"}
                        setFields={(fields) => setFields(idx, fields)} />
                </div>
            </div>
        </Fragment>))}

    </div>
}