import {
    Fragment,
    useState
} from "react";

import CodeMirror from "@uiw/react-codemirror";
import { json, jsonParseLinter } from "@codemirror/lang-json";
import { linter } from "@codemirror/lint";

import { Backend } from "../lib/backend";
import { useSelector } from "react-redux";
import {
    selectIsSidebarLarge,
    selectMemberships
} from "../lib/scraper.slice";
import {
    ORG_ROLES,
    ORG_TYPES
} from "../lib/consts";
import { classNames } from "../lib/utils";

import { Button } from "../components/Button";
import { OrgPill } from "../components/OrgPill";

export function AdminImportTemplate() {
    const is_sidebar_large = useSelector(selectIsSidebarLarge);
    const memberships = useSelector(selectMemberships);
    // default is personal org, if not available, use first org
    const default_org_uuid =
        memberships.find((membership) => membership.org.type === ORG_TYPES.personal)?.org.uuid ||
        memberships[0].org.uuid || "";
    // get list of admin orgs, since only admin can create or edit templates
    const admin_orgs = memberships.filter((m) => m.role === ORG_ROLES.admin).map((m) => m.org);

    const [message, setMessage] = useState<string | undefined>(undefined);
    const [org_uuid, setOrgUuid] = useState<string>(default_org_uuid);
    const [context_def, setContextDef] = useState<string>("");
    const [is_loading, setIsLoading] = useState<boolean>(false);

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();

        if (event.dataTransfer.files && event.dataTransfer.files[0]) {
            const file = event.dataTransfer.files[0];
            const reader = new FileReader();
            reader.onload = (e) => {
                const text = e.target?.result;
                setContextDef(text as string);
                setMessage(undefined);
            };
            reader.readAsText(file);
        }
    };

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
    };

    const createTemplate = () => {
        try {
            const context_def_json = JSON.parse(context_def);

            if (context_def_json.context_name === undefined) { setMessage("Missing context_name"); return; }
            if (context_def_json.facts === undefined) { setMessage("Missing facts"); return; }
            if (context_def_json.fields === undefined) { setMessage("Missing fields"); return; }
            if (context_def_json.fields.length === 0) { setMessage("Missing fields"); return; }
            if (context_def_json.postprocess === undefined) { setMessage("Missing postprocess"); return; }
            if (context_def_json.extract_params === undefined) { setMessage("Missing extract_params"); return; }
            if (context_def_json.extract_params.prompt_output_format === undefined) { setMessage("Missing extract_params.prompt_output_format"); return; }
            if (context_def_json.extract_params.remove_duplicate_records === undefined) { setMessage("Missing extract_params.remove_duplicate_records"); return; }
            if (context_def_json.extract_params.default_decimal_separator === undefined) { setMessage("Missing extract_params.default_decimal_separator"); return; }
            if (context_def_json.extract_params.detect_decimal_separator === undefined) { setMessage("Missing extract_params.detect_decimal_separator"); return; }
            if (context_def_json.extract_params.extraction_strategy === undefined) { setMessage("Missing extract_params.extraction_strategy"); return; }
            if (context_def_json.extract_params.try_auto_heal === undefined) { setMessage("Missing extract_params.try_auto_heal"); return; }
            if (context_def_json.extract_params.preprocess_excel_strategy === undefined) { setMessage("Missing extract_params.preprocess_excel_strategy"); return; }
            if (context_def_json.extract_params.preprocess_ocr_strategy === undefined) { setMessage("Missing extract_params.preprocess_ocr_strategy"); return; }
            if (context_def_json.extract_params.orientation_segments_strategy === undefined) { setMessage("Missing extract_params.orientation_segments_strategy"); return; }
            if (context_def_json.extract_params.max_partial_responses === undefined) { setMessage("Missing extract_params.max_partial_responses"); return; }
            if (context_def_json.extract_params.models_overrides === undefined) { setMessage("Missing extract_params.models_overrides"); return; }
            if (context_def_json.extract_params.admin_prompts === undefined) { setMessage("Missing extract_params.admin_prompts"); return; }
            if (context_def_json.output_type === undefined) { setMessage("Missing output_type"); return; }
            setIsLoading(true);

            Backend.commitContext({
                context_name: context_def_json.context_name,
                org_uuid,
                facts: context_def_json.facts,
                fields: context_def_json.fields,
                postprocess: context_def_json.postprocess,
                extract_params: {
                    prompt_output_format: context_def_json.extract_params.prompt_output_format,
                    remove_duplicate_records: context_def_json.extract_params.remove_duplicate_records,
                    default_decimal_separator: context_def_json.extract_params.default_decimal_separator,
                    detect_decimal_separator: context_def_json.extract_params.detect_decimal_separator,
                    extraction_strategy: context_def_json.extract_params.extraction_strategy,
                    try_auto_heal: context_def_json.extract_params.try_auto_heal,
                    preprocess_excel_strategy: context_def_json.extract_params.preprocess_excel_strategy,
                    preprocess_ocr_strategy: context_def_json.extract_params.preprocess_ocr_strategy,
                    orientation_segments_strategy: context_def_json.extract_params.orientation_segments_strategy,
                    max_partial_responses: context_def_json.extract_params.max_partial_responses,
                    models_overrides: context_def_json.extract_params.models_overrides,
                    admin_prompts: context_def_json.extract_params.admin_prompts
                },
                output_type: context_def_json.output_type,
                examples: context_def_json.examples ? context_def_json.examples : []
            }).then(() => {
                setMessage("Success");
                setIsLoading(false);
            }).catch((e) => {
                setMessage(e.message);
                setIsLoading(false);
            });
        } catch (e) {
            setMessage("Invalid JSON");
            setIsLoading(false);
        }
    };

    return <Fragment>
        <div className={classNames("flex-row lg:fixed lg:right-0 lg:inset-y-0 overflow-y-auto", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
            <div className="m-16 mb-6">
                <h2 className="text-xl font-semibold leading-7 text-gray-600 sm:truncate sm:text-3xl sm:tracking-tight">
                    Import Template
                </h2>
                <div className="pt-5 border-b-4 border-sky-600" />
            </div>

            <div className="flex flex-col px-10 py-5">
                <div className="w-full">
                    <div className="flex gap-2">
                        <span>Organization:</span>{admin_orgs.map((org, idx) => (<OrgPill key={idx} name={org.name} type={org.type} selected={org.uuid === org_uuid} onClick={() => setOrgUuid(org.uuid)} />))}
                    </div>
                    <div className="mt-4 shadow border border-gray-300" onDrop={handleDrop} onDragOver={handleDragOver}>
                        <CodeMirror
                            value={context_def}
                            height="600px"
                            theme={"light"}
                            extensions={[json(), linter(jsonParseLinter())]}
                            onDrop={handleDrop}
                            onDragOver={handleDragOver}
                            onChange={(value) => {
                                setContextDef(value);
                                setMessage(undefined);
                            }} />
                    </div>
                    {message && <div className={classNames("p-4 rounded-md text-center mb-4 w-full",
                        message === "Success" ? "bg-green-100 text-green-800" : "bg-red-100 text-red-800"
                    )}>{message}</div>}
                    <div className="mt-4 text-right">
                        <Button onClick={createTemplate} text="Create Template" disabled={is_loading} loading={is_loading} />
                    </div>
                </div>
            </div>
        </div>
    </Fragment >;
};
