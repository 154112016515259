export function LoadingSpinnerIcon() {
    return <i className="fas fa-spinner fa-spin text-sky-600 text-7xl"></i>;
}

export function LoadingSpinner() {
    return <div className="flex justify-center items-center h-screen w-full">
        <LoadingSpinnerIcon />
    </div>;
}

type LabeledLoadingSpinnerProps = {
    text: string;
}

export function LabeledLoadingSpinner(props: LabeledLoadingSpinnerProps) {
    const { text } = props;

    return <div className="flex flex-col justify-center items-center h-screen w-full gap-y-5 text-lg">
        {text}
        <LoadingSpinnerIcon />
    </div>;
}

type LoadingSpinnerProps = {
    text?: string;
}

export function LoadingSpinnerLimit(props: LoadingSpinnerProps) {
    const { text } = props;

    // center the spinner and put text under it
    return <div className="flex flex-col justify-center items-center w-full">
        <LoadingSpinnerIcon />
        {text && <span className="mt-6">{text}</span>}
    </div>;
}
