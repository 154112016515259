import * as hi from "@heroicons/react/24/outline";

import { LongText } from "./LongText";
import { classNames } from "../lib/utils";
import { useSelector } from "react-redux";
import { selectIsSidebarLarge } from "../lib/scraper.slice";
import { useEffect } from "react";

type FullScreenTextProps = {
    title?: string;
    text: string;
    show: boolean;
    onClose: () => void;
}

export function FullScreenText(props: FullScreenTextProps) {
    const { title, text, show, onClose } = props;

    const is_sidebar_large = useSelector(selectIsSidebarLarge);

    // intercept the escape key and close the box
    useEffect(() => {
        const handleEsc = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                onClose();
            }
        };

        window.addEventListener('keydown', handleEsc);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('keydown', handleEsc);
        };
    }, [onClose]); // Re-run the effect if onClose changes

    if (!show) {
        return null;
    }

    // intercept click on the outside and close the box
    const onBorderClick = () => {
        onClose();
    }

    // if the user clicks on the box, don't close it
    const onBoxClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.stopPropagation();
    };

    const onCopy = () => {
        navigator.clipboard.writeText(text);
    }

    const onDownload = () => {
        const dataUri = 'data:application/json;charset=utf-8,' + encodeURIComponent(text);
        let downloadAnchorNode = document.createElement('a');
        downloadAnchorNode.setAttribute("href", dataUri);
        downloadAnchorNode.setAttribute("download", title || "data.txt");
        document.body.appendChild(downloadAnchorNode);
        downloadAnchorNode.click();
        downloadAnchorNode.remove();
    }

    return <div className={classNames("fixed inset-0 z-50", is_sidebar_large ? "lg:left-64" : "lg:left-20")} onClick={onBorderClick}>
        <div className="absolute inset-x-11 inset-y-2 bg-white opacity-95 border-2 rounded-lg shadow-lg overflow-y-auto overflow-x-auto" onClick={onBoxClick}>
            <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                <button
                    type="button"
                    className="mr-4 rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
                    onClick={onDownload}
                >
                    <span className="sr-only">Download</span>
                    <hi.ArrowDownTrayIcon className="h-6 w-6" aria-hidden="true" />
                </button>
                <button
                    type="button"
                    className="mr-4 rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
                    onClick={onCopy}
                >
                    <span className="sr-only">Copy to clipboard</span>
                    <hi.ClipboardDocumentIcon className="h-6 w-6" aria-hidden="true" />
                </button>
                <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
                    onClick={onClose}
                >
                    <span className="sr-only">Close</span>
                    <hi.XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
            </div>
            <div className="p-4">
                <div className="text-sm font-mono whitespace-pre-wrap font-light">
                    <LongText text={text} />
                </div>
            </div>
        </div>
    </div>;
}

type FullScreenProps = {
    children: any;
    show: boolean;
    opacity?: boolean; // default is true
    show_close_button?: boolean; // default is true
    onClose?: () => void;
}

export function FullScreen(props: FullScreenProps) {
    const { children, show, opacity, onClose } = props;

    const is_sidebar_large = useSelector(selectIsSidebarLarge);
    const show_opacity = opacity === undefined || opacity === true;
    const show_close_button = props.show_close_button === undefined || props.show_close_button === true;

    // intercept the escape key and close the box
    useEffect(() => {
        const handleEsc = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                if (onClose) { onClose(); }
            }
        };

        window.addEventListener('keydown', handleEsc);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('keydown', handleEsc);
        };
    }, [onClose]); // Re-run the effect if onClose changes

    if (!show) {
        return null;
    }

    // intercept click on the outside and close the box
    const onBorderClick = () => {
        if (onClose) { onClose(); }
    }

    // if the user clicks on the box, don't close it
    const onBoxClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.stopPropagation();
    };

    return <div className={classNames("fixed inset-0 z-50", is_sidebar_large ? "lg:left-64" : "lg:left-20")} onClick={onBorderClick}>
        <div className={classNames("mt-16 mb-2 absolute inset-x-11 inset-y-2 bg-white border-2 rounded-lg shadow-lg overflow-y-auto overflow-x-auto", show_opacity ? "bg-opacity-95" : "")} onClick={onBoxClick}>
            <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                {show_close_button && <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
                    onClick={onClose}
                >
                    <span className="sr-only">Close</span>
                    <hi.XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>}
            </div>
            <div className="p-4">
                <div className="text-sm">
                    {children}
                </div>
            </div>
        </div>
    </div>;
}