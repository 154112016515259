import {
    Fragment,
    ReactNode
} from "react";

import {
    Dialog,
    Transition
} from "@headlessui/react";
import { classNames } from "../lib/utils";

type SidePanelProps = {
    children: ReactNode;
    title: string;
    size?: "lg" | "xl" | "2xl";
    open: boolean;
    onClose: () => void;
}

export function SidePanel(props: SidePanelProps) {
    const { children, title, size, open, onClose } = props;

    return <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={onClose}>
            <div className="fixed inset-0" />

            <div className="fixed inset-0 overflow-hidden">
                <div className="absolute inset-0 overflow-hidden">
                    <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                        <Transition.Child
                            as={Fragment}
                            enter="transform transition ease-in-out duration-500 sm:duration-700"
                            enterFrom="translate-x-full"
                            enterTo="translate-x-0"
                            leave="transform transition ease-in-out duration-500 sm:duration-700"
                            leaveFrom="translate-x-0"
                            leaveTo="translate-x-full"
                        >
                            <Dialog.Panel className={classNames("pointer-events-auto w-screen, max-w-" + (size ?? "xl"))}>
                                <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                                    <div className="px-4 sm:px-6">
                                        <div className="flex items-start justify-between">
                                            <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                                                {title}
                                            </Dialog.Title>
                                        </div>
                                    </div>
                                    <div className="relative mt-6 px-4 sm:px-6 w-full">
                                        {children}
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </div>
        </Dialog>
    </Transition.Root >;
}