import {
    Fragment,
    RefObject,
    createRef,
    useEffect,
    useState
} from "react";
import { useSelector } from "react-redux";

import CodeMirror from "@uiw/react-codemirror";
import { javascript } from "@codemirror/lang-javascript";

import {
    Cog6ToothIcon,
    MinusIcon
} from "@heroicons/react/20/solid"
import {
    ChevronDoubleLeftIcon,
    ChevronDoubleRightIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    TrashIcon,
    XMarkIcon
} from '@heroicons/react/24/outline'
import {
    Dialog,
    Transition
} from '@headlessui/react'

import * as t from "../lib/types";
import {
    classNames,
    getExcelColumnName,
    getHierarchicalContextExample,
    newUuid
} from "../lib/utils";
import {
    languages,
    context_datatypes,
    USER_ROLES
} from "../lib/consts";
import {
    ContextFieldConditionType
} from "../lib/backend/extractions.types.generated";
import { selectUser } from "../lib/scraper.slice";

import { Button } from "./Button";
import {
    CompactTabs,
    ITab
} from "./Tabs";
import { DropdownMenu, IDropdownMenuItem } from "./DropdownMenu";
import { Dropdown } from "../components/Dropdown";
import { HierarchicalRecord } from "./ItemTables";
import { Textbox } from "./Textbox";
import { Checkbox } from "./Checkbox";

type FieldSettingsProps = {
    open: boolean;
    field_idx: number;
    fields_count: number;
    field?: t.IContextField;
    fields: t.IContextField[];
    lookup_tables: t.ILookupTableBase[];
    setOpen: (open: boolean) => void;
    setField: (field_idx: number, field: t.IContextField) => void;
    moveField: (curr_idx: number, diff_idx: number) => void;
    deleteField: (field_idx: number) => void;
    createField: (after_field_idx: number, field: t.IContextField) => void;
};

function FieldSettings(props: FieldSettingsProps) {
    const {
        open,
        field_idx,
        fields_count,
        field, fields,
        lookup_tables,
        setOpen,
        setField,
        moveField,
        deleteField,
        createField
    } = props;

    // check if user is admin
    const user = useSelector(selectUser);
    const is_admin = user && user.role === USER_ROLES.admin;

    const [selected_tab, setSelectedTab] = useState<string>("general");
    const [tabs, setTabs] = useState<ITab[]>([{ name: "General", key: "general" }]);

    useEffect(() => {
        const tabs = [];
        // add tabs based on field type
        let new_selected_tab = selected_tab;
        if (field && field.type === "extract") {
            tabs.push({ name: "General", key: "general" });
            new_selected_tab = "general";
        } else if (field && field.type === "compute" && field.compute && field.compute.type === "lookup_map") {
            tabs.push({ name: "Lookup", key: "lookup" });
            new_selected_tab = "lookup";
        } else if (field && field.type === "compute" && field.compute && field.compute.type === "formula") {
            tabs.push({ name: "Formula", key: "formula" });
            new_selected_tab = "formula";
        } else if (field && field.type === "compute" && field.compute && field.compute.type === "document_name") {
            tabs.push({ name: "Document Name", key: "document_name" });
            new_selected_tab = "document_name";
        } else if (field && field.type === "compute" && field.compute && field.compute.type === "metadata") {
            tabs.push({ name: "Metadata", key: "metadata" });
            new_selected_tab = "metadata";
        }
        tabs.push({ name: "Rules", key: "rules" });
        tabs.push({ name: "Details", key: "details" });
        setTabs(tabs);
        // make sure selected tab is valid
        if (tabs.length > 0 && !tabs.find((tab) => tab.key === selected_tab)) {
            setSelectedTab(new_selected_tab);
        }
    }, [selected_tab, field, field_idx, lookup_tables]);

    const updateField = (key: "name" | "datatype" | "format" | "example" | "translate" | "confirm_name", value: string) => {
        if (field) {
            const new_field = { ...field };
            if (key === "name") {
                new_field.name = value;
            } else if (key === "datatype") {
                new_field.datatype = value as "string" | "number";
            } else if (key === "format") {
                new_field.extract = { ...new_field.extract, format: value };
            } else if (key === "example") {
                new_field.extract = { ...new_field.extract, examples: [value] };
            } else if (key === "translate") {
                new_field.extract = { ...new_field.extract, translate: value };
            } else if (key === "confirm_name") {
                new_field.confirm_name = value.length > 0 ? value : undefined;
            }
            setField(field_idx, new_field);
        }
    };

    const updateCheckbox = (key: "prioritize_handwritten" | "skip_on_confirm", value: boolean) => {
        if (field) {
            const new_field = { ...field, };
            if (key === "prioritize_handwritten") {
                new_field.extract = { ...new_field.extract, prioritize_handwritten: value };
            } else if (key === "skip_on_confirm") {
                new_field.skip_on_confirm = value;
            }
            setField(field_idx, new_field);
        }
    };

    // conditions

    const addCondition = (type: ContextFieldConditionType) => {
        if (field) {
            if (type === "only_with") {
                const conditions: t.IExtractCondition[] = [
                    ...(field?.conditions || []),
                    { type, field_uuid: fields[0].uuid }
                ];
                setField(field_idx, { ...field, conditions });
            } else if (type === "skip_if_match") {
                const conditions = [
                    ...(field?.conditions || []),
                    { type, field_value: "" }
                ];
                setField(field_idx, { ...field, conditions });
            } else if (type === "skip_if_includes") {
                if (lookup_tables.length > 0) {
                    const conditions = [
                        ...(field?.conditions || []),
                        {
                            type, range: {
                                lookup_table_uuid: lookup_tables[0].uuid,
                                header_idx: 0
                            }
                        }
                    ];
                    setField(field_idx, { ...field, conditions });
                }
            } else {
                throw new Error("Invalid condition type " + type);
            }
        }
    };

    const deleteCondition = (condition_idx: number) => {
        if (field && field.conditions && field.conditions.length > condition_idx) {
            const conditions = [
                ...field.conditions.splice(0, condition_idx),
                ...field.conditions.splice(condition_idx + 1)
            ];
            setField(field_idx, { ...field, conditions });
        }
    };

    const updateCondition = (condition_idx: number, key: "field_uuid" | "field_value", value: string) => {
        if (key === "field_uuid") {
            if (field && field.conditions && field.conditions.length > condition_idx) {
                const conditions = [...field.conditions];
                conditions[condition_idx].field_uuid = value;
                setField(field_idx, { ...field, conditions });
            }
        } else if (key === "field_value") {
            if (field && field.conditions && field.conditions.length > condition_idx) {
                const conditions = [...field.conditions];
                conditions[condition_idx].field_value = value;
                setField(field_idx, { ...field, conditions });
                console.log(field);
            }
        } else {
            throw new Error("Invalid key " + key + " in updateCondition");
        }
    }

    const updateConditionRangeTable = (condition_idx: number, lookup_table_uuid: string) => {
        if (field && field.conditions && field.conditions.length > condition_idx) {
            const conditions = [...field.conditions];
            if (conditions[condition_idx].type === "skip_if_includes") {
                const range = conditions[condition_idx].range;
                if (range !== undefined) {
                    range.lookup_table_uuid = lookup_table_uuid;
                    range.header_idx = 0;
                }
            }
            setField(field_idx, { ...field, conditions });
        }
    }

    const updateConditionRangeHeader = (condition_idx: number, header_idx: number) => {
        if (field && field.conditions && field.conditions.length > condition_idx) {
            const conditions = [...field.conditions];
            if (conditions[condition_idx].type === "skip_if_includes") {
                const range = conditions[condition_idx].range;
                if (range !== undefined) {
                    range.header_idx = header_idx;
                }
            }
            setField(field_idx, { ...field, conditions });
        }
    }

    /// verifications

    const addValidationRange = () => {
        if (field && lookup_tables.length > 0) {
            const range: t.IContextFieldVerification = {
                type: "range",
                range: {
                    lookup_table_uuid: lookup_tables[0].uuid,
                    header_idx: 0
                }
            };
            const verifications: t.IContextFieldVerification[] = [...(field.verifications || []), range];
            setField(field_idx, { ...field, verifications });
        }
    }

    const addValidationPivotRange = () => {
        if (field && fields.length > 0 && lookup_tables.length > 0) {
            const pivot_range: t.IContextFieldVerification = {
                type: "pivot_range",
                pivot_range: {
                    lookup_table_uuid: lookup_tables[0].uuid,
                    key_field_uuid: fields[0].uuid,
                    key_header_idx: 0,
                    value_header_idx: 0
                }
            };
            const verifications: t.IContextFieldVerification[] = [...(field.verifications || []), pivot_range];
            setField(field_idx, { ...field, verifications });
        }
    }

    const addValidationEqualsField = () => {
        if (field && fields.length > 0) {
            const equals_field: t.IContextFieldVerification = {
                type: "equals_field",
                equals_field: {
                    field_uuid: fields[0].uuid
                }
            };
            const verifications: t.IContextFieldVerification[] = [...(field.verifications || []), equals_field];
            setField(field_idx, { ...field, verifications });
        }
    }

    const addValidationRegex = () => {
        if (field) {
            const regex: t.IContextFieldVerification = {
                type: "regex",
                regex: {
                    pattern: "^.+$"
                }
            };
            const verifications: t.IContextFieldVerification[] = [...(field.verifications || []), regex];
            setField(field_idx, { ...field, verifications });
        }
    }

    const deleteValidation = (verification_idx: number) => {
        if (field && field.verifications && field.verifications.length > verification_idx) {
            const verifications = [
                ...field.verifications.splice(0, verification_idx),
                ...field.verifications.splice(verification_idx + 1)
            ];
            setField(field_idx, { ...field, verifications });
        }
    }

    const updateValidationRangeTable = (verification_idx: number, lookup_table_uuid: string) => {
        if (field && field.verifications && field.verifications.length > verification_idx) {
            const verifications = [...field.verifications];
            if (verifications[verification_idx].type === "range") {
                const range = verifications[verification_idx].range;
                if (range !== undefined) {
                    range.lookup_table_uuid = lookup_table_uuid;
                    range.header_idx = 0;
                }
            }
            setField(field_idx, { ...field, verifications });
        }
    }

    const updateValidation = (verification_idx: number, key: "required", value: boolean) => {
        if (field && field.verifications && field.verifications.length > verification_idx) {
            const verifications = [...field.verifications];
            const verification = verifications[verification_idx];
            if (key === "required") {
                verification.required = value;
            }
            setField(field_idx, { ...field, verifications });
        }
    }

    const updateValidationRange = (verification_idx: number, key: "header" | "compensate_leading_zeros", value: number | boolean) => {
        if (field && field.verifications && field.verifications.length > verification_idx) {
            const verifications = [...field.verifications];
            if (verifications[verification_idx].type === "range") {
                const range = verifications[verification_idx].range;
                if (range !== undefined) {
                    if (key === "header") { range.header_idx = value as number; }
                    if (key === "compensate_leading_zeros") { range.compensate_leading_zeros = value as boolean; }
                }
            }
            setField(field_idx, { ...field, verifications });
        }
    }

    const updateValidationPivotRangeTable = (verification_idx: number, lookup_table_uuid: string) => {
        if (field && field.verifications && field.verifications.length > verification_idx) {
            const verifications = [...field.verifications];
            if (verifications[verification_idx].type === "pivot_range") {
                const pivot_range = verifications[verification_idx].pivot_range;
                if (pivot_range !== undefined) {
                    pivot_range.lookup_table_uuid = lookup_table_uuid;
                    pivot_range.key_field_uuid = fields[0].uuid;
                    pivot_range.key_header_idx = 0;
                    pivot_range.value_header_idx = 0;
                    setField(field_idx, { ...field, verifications });
                }
            }
        }
    }

    const updateValidationPivotRangeValue = (verification_idx: number, key: "field" | "key" | "value" | "compensate_leading_zeros", val: string | number | boolean) => {
        if (field && field.verifications && field.verifications.length > verification_idx) {
            const verifications = [...field.verifications];
            if (verifications[verification_idx].type === "pivot_range") {
                const pivot_range = verifications[verification_idx].pivot_range;
                if (pivot_range !== undefined) {
                    if (key === "field") { pivot_range.key_field_uuid = val as string; }
                    if (key === "key") { pivot_range.key_header_idx = val as number; }
                    if (key === "value") { pivot_range.value_header_idx = val as number; }
                    if (key === "compensate_leading_zeros") { pivot_range.compensate_leading_zeros = val as boolean; }
                    setField(field_idx, { ...field, verifications });
                }
            }
            setField(field_idx, { ...field, verifications });
        }
    }

    const updateValidationEqualsField = (verification_idx: number, field_uuid: string) => {
        if (field && field.verifications && field.verifications.length > verification_idx) {
            const verifications = [...field.verifications];
            if (verifications[verification_idx].type === "equals_field") {
                const equals_field = verifications[verification_idx].equals_field;
                if (equals_field !== undefined) {
                    equals_field.field_uuid = field_uuid;
                    setField(field_idx, { ...field, verifications });
                }
            }
        }
    }

    const updateValidationRegex = (verification_idx: number, pattern: string) => {
        if (field && field.verifications && field.verifications.length > verification_idx) {
            const verifications = [...field.verifications];
            if (verifications[verification_idx].type === "regex") {
                const regex = verifications[verification_idx].regex;
                if (regex !== undefined) {
                    regex.pattern = pattern;
                    setField(field_idx, { ...field, verifications });
                }
            }
        }
    }

    /// suggestions

    const addSuggestionMap = () => {
        if (field && fields.length > 0 && lookup_tables.length > 0) {
            const suggestion: t.IContextFieldSuggestion = {
                type: "map",
                map: {
                    lookup_table_uuid: lookup_tables[0].uuid,
                    key_field_uuid: fields[0].uuid,
                    key_header_idx: 0,
                    value_header_idx: 0,
                    comparison: "exact" as "exact" | "approx"
                }
            };
            const suggestions: t.IContextFieldSuggestion[] = [...(field?.suggestions || []), suggestion];
            setField(field_idx, { ...field, suggestions });
        }
    }

    const deleteSuggestion = (suggestion_idx: number) => {
        if (field && field.suggestions && field.suggestions.length > suggestion_idx) {
            const suggestions = [
                ...field.suggestions.splice(0, suggestion_idx),
                ...field.suggestions.splice(suggestion_idx + 1)
            ];
            setField(field_idx, { ...field, suggestions });
        }
    }

    const updateSuggestionMapTable = (suggestion_idx: number, lookup_table_uuid: string) => {
        if (field && field.suggestions && field.suggestions.length > suggestion_idx) {
            const suggestions = [...field.suggestions];
            if (suggestions[suggestion_idx].type === "map") {
                const suggestion_map = suggestions[suggestion_idx].map;
                if (suggestion_map !== undefined) {
                    suggestion_map.lookup_table_uuid = lookup_table_uuid;
                    suggestion_map.key_field_uuid = fields[0].uuid;
                    suggestion_map.key_header_idx = 0;
                    suggestion_map.value_header_idx = 0;
                    setField(field_idx, { ...field, suggestions });
                }
            }
            setField(field_idx, { ...field, suggestions });
        }
    }

    const updateSuggestionMapValue = (suggestion_idx: number, key: "field" | "key" | "value" | "comparison", val: string | number) => {
        if (field && field.suggestions && field.suggestions.length > suggestion_idx) {
            const suggestions = [...field.suggestions];
            if (suggestions[suggestion_idx].type === "map") {
                const suggestion_map = suggestions[suggestion_idx].map;
                if (suggestion_map !== undefined) {
                    if (key === "field") { suggestion_map.key_field_uuid = val as string; }
                    if (key === "key") { suggestion_map.key_header_idx = val as number; }
                    if (key === "value") { suggestion_map.value_header_idx = val as number; }
                    if (key === "comparison") { suggestion_map.comparison = val as "exact" | "approx"; }
                    setField(field_idx, { ...field, suggestions });
                }
            }
        }
    }

    // compute field

    const createLookupField = () => {
        if (field === undefined) { return; }
        // find out name
        let lookup_field_name = `Lookup - ${field.name}`;
        let counter = 2;
        // eslint-disable-next-line
        while (fields.find((field) => field.name === lookup_field_name) !== undefined) {
            lookup_field_name = `Lookup - ${field.name} (${counter})`;
            counter++;
        }
        // create lookup field
        const lookup_compute_field: t.IContextField = {
            uuid: newUuid(),
            name: lookup_field_name,
            type: "compute",
            datatype: "string",
            compute: {
                type: "lookup_map",
                lookup_map: {
                    lookup_table_uuid: lookup_tables[0].uuid,
                    key_field_uuid: field.uuid,
                    key_header_idx: 0,
                    value_header_idx: 0
                }
            }
        };
        createField(field_idx, lookup_compute_field);
        if (tabs.length > 0) { setSelectedTab(tabs[0].key); }
    }

    const updateLookupFieldTable = (lookup_table_uuid: string) => {
        if (field) {
            const new_field = { ...field };
            if (new_field.type === "compute" && new_field.compute) {
                if (new_field.compute.type === "lookup_map" && new_field.compute.lookup_map) {
                    new_field.compute.lookup_map.lookup_table_uuid = lookup_table_uuid;
                    new_field.compute.lookup_map.key_header_idx = 0;
                    new_field.compute.lookup_map.value_header_idx = 0;
                    setField(field_idx, new_field);
                }
            }
        }
    }

    const updateLookupFieldValue = (key: "key" | "value" | "comparison" | "compensate_ocr_errors" | "compensate_leading_zeros" | "match_whole_word", val: string | number | boolean) => {
        if (field) {
            const new_field = { ...field };
            if (new_field.type === "compute" && new_field.compute) {
                if (new_field.compute.type === "lookup_map" && new_field.compute.lookup_map) {
                    if (key === "key") { new_field.compute.lookup_map.key_header_idx = val as number; }
                    if (key === "value") { new_field.compute.lookup_map.value_header_idx = val as number; }
                    if (key === "comparison") { new_field.compute.lookup_map.comparison = val as "exact" | "approx"; }
                    if (key === "compensate_ocr_errors") { new_field.compute.lookup_map.compensate_ocr_errors = val as boolean; }
                    if (key === "compensate_leading_zeros") { new_field.compute.lookup_map.compensate_leading_zeros = val as boolean; }
                    if (key === "match_whole_word") { new_field.compute.lookup_map.match_whole_word = val as boolean; }
                    setField(field_idx, new_field);
                }
            }
        }
    }

    const createFormulaField = () => {
        if (field === undefined) { return; }
        // find out name
        let formula_field_name = `Formula - ${field.name}`;
        let counter = 2;
        // eslint-disable-next-line
        while (fields.find((field) => field.name === formula_field_name) !== undefined) {
            formula_field_name = `Formula - ${field.name} (${counter})`;
            counter++;
        }
        // create lookup field
        const formula_compute_field: t.IContextField = {
            uuid: newUuid(),
            name: formula_field_name,
            type: "compute",
            datatype: "string",
            compute: {
                type: "formula",
                formula: `"X"`
            }
        };
        createField(field_idx, formula_compute_field);
        if (tabs.length > 0) { setSelectedTab(tabs[0].key); }
    }

    const updateFormulaFieldValue = (value: string) => {
        if (field) {
            const new_field = { ...field };
            if (new_field.type === "compute" && new_field.compute) {
                if (new_field.compute.type === "formula") {
                    new_field.compute.formula = value;
                    setField(field_idx, new_field);
                }
            }
        }
    }

    const createMetadataField = () => {
        if (field === undefined) { return; }
        const metadata_field: t.IContextField = {
            uuid: newUuid(),
            name: "Metadata",
            type: "compute",
            datatype: "string",
            compute: {
                type: "metadata",
                metadata: {
                    type: "document_name",
                }
            }
        };
        createField(field_idx, metadata_field);
        if (tabs.length > 0) { setSelectedTab(tabs[0].key); }
    }

    const updateMetadataFieldValue = (value: string) => {
        if (field === undefined) { return; }
        const new_field = { ...field };
        if (new_field.type === "compute" && new_field.compute && new_field.compute.type === "metadata") {
            new_field.compute.metadata = {
                type: value as "document_name" | "extract_ts" | "filename"
            };
            setField(field_idx, new_field);
        }
    }

    // prepare rules dropwdown items
    const rules_items: IDropdownMenuItem[] = [];
    const has_lookup_tables = lookup_tables.length > 0;
    if (has_lookup_tables) {
        rules_items.push({ title: "Add Lookup Constraint", onClick: addValidationRange });
        rules_items.push({ title: "Add Filtered Lookup Constraint", onClick: addValidationPivotRange });
    }
    rules_items.push({ title: "Add Field Equality Constraint", onClick: addValidationEqualsField });
    rules_items.push({ title: "Add Regex Constraint", separator: true, onClick: addValidationRegex });
    if (has_lookup_tables) {
        rules_items.push({ title: "Add Filtered Suggestion", separator: true, onClick: addSuggestionMap });
        rules_items.push({ title: "Add Lookup Field", separator: !is_admin, onClick: createLookupField });
    }
    rules_items.push({ title: "Add Metadata Field", onClick: createMetadataField });
    if (is_admin) {
        rules_items.push({ title: "Add Formula Field", separator: true, onClick: createFormulaField });
    }
    rules_items.push({ title: "Add Extract With Condition", onClick: () => { addCondition("only_with"); } });
    rules_items.push({ title: "Skip Row on Value Match", onClick: () => { addCondition("skip_if_match"); } });
    if (has_lookup_tables) {
        rules_items.push({ title: "Skip Row on Lookup Match", onClick: () => { addCondition("skip_if_includes"); } });
    }
    // prepare range lookup dropdown values
    const lookup_tables_values: string[] = lookup_tables.map((lookup_table) => lookup_table.name);
    const lookup_tables_ids: string[] = lookup_tables.map((lookup_table) => lookup_table.uuid);

    const getLookupTableHeaderValues = (lookup_table_uuid: string) => {
        const selected_lookup_table = lookup_tables
            .find((lookup_table) => lookup_table.uuid === lookup_table_uuid);
        const range_lookup_table_headers = selected_lookup_table?.headers || [];
        return range_lookup_table_headers.map((header, idx) => `${getExcelColumnName(idx)} - ${header}`);
    };
    const getLookupTableHeaderIds = (lookup_table_uuid: string) => {
        const selected_lookup_table = lookup_tables
            .find((lookup_table) => lookup_table.uuid === lookup_table_uuid);
        const range_lookup_table_headers = selected_lookup_table?.headers || [];
        return range_lookup_table_headers.map((_header, idx) => `${idx}`);
    };

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={setOpen}>
                <div className="fixed inset-0" />

                <div className="fixed inset-0 overflow-hidden">
                    <div className="absolute inset-0 overflow-hidden">
                        <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                            <Transition.Child
                                as={Fragment}
                                enter="transform transition ease-in-out duration-500 sm:duration-700"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-500 sm:duration-700"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <Dialog.Panel className="pointer-events-auto w-screen max-w-lg xl:max-w-xl 2xl:max-w-2xl">
                                    <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                                        <div className="px-4 sm:px-6">
                                            <div className="flex items-start justify-between">
                                                <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                                                    Detailed Field Settings
                                                </Dialog.Title>
                                                <div className="ml-3 flex h-7 items-center">
                                                    <button
                                                        type="button"
                                                        className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
                                                        onClick={() => setOpen(false)}
                                                    >
                                                        <span className="absolute -inset-2.5" />
                                                        <span className="sr-only">Close panel</span>
                                                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="relative mt-6 flex-1 px-4 sm:px-6">
                                            <div>
                                                <label htmlFor="title" className="my-2 block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                                    Name
                                                </label>
                                                <div className="my-2 sm:col-span-2 sm:mt-0">
                                                    <div className="flex w-full rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-sky-600 sm:max-w-md">
                                                        <input
                                                            type="text"
                                                            className="block flex-1 border-0 bg-transparent p-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                                            value={field?.name || ""}
                                                            onChange={(e) => updateField("name", e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="my-6 flex flex-row">
                                                <Button icon={ChevronDoubleLeftIcon} tooltip="Move to the start" onClick={() => moveField(field_idx, -field_idx)} disabled={field_idx === 0} />
                                                <Button icon={ChevronLeftIcon} tooltip="Move left" onClick={() => moveField(field_idx, -1)} disabled={field_idx === 0} />
                                                <Button icon={ChevronRightIcon} tooltip="Move right" onClick={() => moveField(field_idx, 1)} disabled={fields_count - field_idx <= 1} />
                                                <Button icon={ChevronDoubleRightIcon} tooltip="Move to the end" onClick={() => moveField(field_idx, fields_count - field_idx - 1)} disabled={fields_count - field_idx <= 1} />
                                                <div className="grow" />
                                                <Button text="Delete" icon={TrashIcon} onClick={() => deleteField(field_idx)} />
                                            </div>
                                            <div className="mt-12 mb-4">
                                                <p className="text-sm text-gray-400">
                                                    The following settings are optional
                                                </p>
                                            </div>
                                            <CompactTabs tabs={tabs} selected_tab_key={selected_tab} setSelectedTab={setSelectedTab} />
                                            {selected_tab === "general" && <div>
                                                <div className="my-4">
                                                    <label htmlFor="guidelines" className="my-2 block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                                        Guidelines
                                                    </label>
                                                    <div className="my-2 sm:col-span-2 sm:mt-0">
                                                        <div className="flex w-full rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-sky-600 sm:max-w-md">
                                                            <input
                                                                type="text"
                                                                className="block flex-1 border-0 bg-transparent p-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                                                value={field?.extract?.format || ""}
                                                                onChange={(e) => updateField("format", e.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="my-4">
                                                    <label htmlFor="title" className="my-2 block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                                        Format
                                                    </label>
                                                    <div className="my-2 sm:col-span-2 sm:mt-0">
                                                        <div className="flex w-full rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-sky-600 sm:max-w-md">
                                                            <Dropdown
                                                                values={["Text", "Number"]}
                                                                ids={context_datatypes}
                                                                selected={field?.datatype || ""}
                                                                onChange={(e) => updateField("datatype", e)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="my-4">
                                                    <label htmlFor="example" className="my-2 block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                                        Example
                                                    </label>
                                                    <div className="my-2 sm:col-span-2 sm:mt-0">
                                                        <div className="flex w-full rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-sky-600 sm:max-w-md">
                                                            <input
                                                                type="text"
                                                                className="block flex-1 border-0 bg-transparent p-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                                                value={field?.extract?.examples !== undefined ? field?.extract?.examples[0] || "" : ""}
                                                                onChange={(e) => updateField("example", e.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="my-4">
                                                    <label htmlFor="translate" className="my-2 block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                                        Translate to
                                                    </label>
                                                    <div className="my-2 sm:col-span-2 sm:mt-0">
                                                        <select
                                                            id="translate" name="translate" autoComplete="translate"
                                                            className={classNames(
                                                                "block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6",
                                                                field?.extract?.translate && field?.extract?.translate.length === 0 ? "text-gray-400" : "text-gray-900"
                                                            )}
                                                            value={field?.extract?.translate || ""}
                                                            onChange={(e) => { updateField("translate", e.target.value); }}
                                                        >
                                                            <option value="">[No translation]</option>
                                                            {languages.map((language) => {
                                                                return <option key={language} value={language}>{language}</option>;
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="my-6">
                                                    <fieldset>
                                                        <legend className="sr-only">Prioritize handwritten over printed text</legend>
                                                        <div className="space-y-5">
                                                            <div className="relative flex items-start">
                                                                <div className="flex h-6 items-center">
                                                                    <input
                                                                        id="prioritize_handwritten"
                                                                        aria-describedby="prioritize_handwritten-description"
                                                                        name="prioritize_handwritten"
                                                                        type="checkbox"
                                                                        checked={field?.extract?.prioritize_handwritten || false}
                                                                        onChange={(e) => updateCheckbox("prioritize_handwritten", e.target.checked)}
                                                                        className="h-4 w-4 rounded border-gray-300 text-sky-600 focus:ring-sky-600"
                                                                    />
                                                                </div>
                                                                <div className="ml-3 text-sm leading-6">
                                                                    <label htmlFor="prioritize_handwritten" className="font-medium text-gray-900">
                                                                        Prioritize handwritten over printed text
                                                                    </label>
                                                                    <p id="prioritize_handwritten-description" className="text-gray-500">
                                                                        Useful when you have a mix of handwritten and printed values for the same field and you want
                                                                        extraction of this field to prioritize handwritten text over printed text when both are present.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </fieldset>
                                                </div>
                                            </div>}

                                            {selected_tab === "rules" && <div>
                                                <div className="pt-4 flex flex-row items-center w-full">
                                                    <DropdownMenu title="Create new rule" items={rules_items} />
                                                </div>
                                                {field?.verifications && <div className="flex flex-col gap-y-4">
                                                    {field.verifications.map((verification, verification_idx) =>
                                                        <div className="">
                                                            <div className="pt-4 flex flex-row items-center">
                                                                <div className="text-sm font-semibold">
                                                                    {verification_idx + 1}.&nbsp;
                                                                    {verification.type === "range" && "Lookup Constraint"}
                                                                    {verification.type === "pivot_range" && "Filtered Lookup Constraint"}
                                                                    {verification.type === "equals_field" && "Field Equality Constraint"}
                                                                    {verification.type === "regex" && "Regex Constraint"}
                                                                </div>
                                                                <div className="grow" />
                                                                <div className="mr-3">
                                                                    <fieldset>
                                                                        <legend className="sr-only">Required to confirm</legend>
                                                                        <div className="relative flex items-start">
                                                                            <div className="flex h-6 items-center">
                                                                                <input
                                                                                    id="required"
                                                                                    aria-describedby="required-description"
                                                                                    name="required"
                                                                                    type="checkbox"
                                                                                    checked={verification.required || false}
                                                                                    onChange={(e) => updateValidation(verification_idx, "required", e.target.checked)}
                                                                                    className="h-4 w-4 rounded border-gray-300 text-sky-600 focus:ring-sky-600"
                                                                                />
                                                                            </div>
                                                                            <div className="ml-1 text-sm leading-6 flex flex-row gap-x-4">
                                                                                <label htmlFor="required" className="font-normal text-gray-900">
                                                                                    Required
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </fieldset>
                                                                </div>
                                                                <div className="ml-2"><Button icon={TrashIcon} onClick={() => deleteValidation(verification_idx)} /></div>
                                                            </div>

                                                            {verification.type === "range" && <div className="w-full flex flex-col gap-y-4">
                                                                <div className="">
                                                                    <label htmlFor="title" className="pt-2  pb-1 block text-sm text-gray-900">
                                                                        Lookup Table
                                                                    </label>
                                                                    <div className="w-full">
                                                                        <Dropdown
                                                                            values={lookup_tables_values}
                                                                            ids={lookup_tables_ids}
                                                                            selected={verification.range?.lookup_table_uuid || ""}
                                                                            onChange={(id: string) => updateValidationRangeTable(verification_idx, id)} />
                                                                    </div>
                                                                </div>
                                                                <div className="">
                                                                    <label htmlFor="title" className="pt-2 pb-1 block text-sm text-gray-900">
                                                                        Lookup Column
                                                                    </label>
                                                                    <div className="w-full">
                                                                        <Dropdown
                                                                            values={getLookupTableHeaderValues(verification.range?.lookup_table_uuid || "")}
                                                                            ids={getLookupTableHeaderIds(verification.range?.lookup_table_uuid || "")}
                                                                            selected={verification.range?.header_idx.toString() || "0"}
                                                                            onChange={(header_idx: string) => updateValidationRange(verification_idx, "header", parseInt(header_idx, 10))} />
                                                                    </div>
                                                                </div>
                                                                <div className="">
                                                                    <fieldset>
                                                                        <legend className="sr-only">Compensate leading zeros in ID numbers</legend>
                                                                        <div className="space-y-5">
                                                                            <div className="relative flex items-start">
                                                                                <div className="flex h-6 items-center">
                                                                                    <Checkbox
                                                                                        checked={verification.range?.compensate_leading_zeros || false}
                                                                                        setChecked={(checked) => updateValidationRange(verification_idx, "compensate_leading_zeros", checked)}
                                                                                        id={`range_compensate_leading_zeros_${verification_idx}`}
                                                                                    />
                                                                                </div>
                                                                                <div className="ml-3 text-sm leading-6">
                                                                                    <label htmlFor={`range_compensate_leading_zeros_${verification_idx}`} className="font-medium text-gray-900">
                                                                                        Compensate leading zeros in ID numbers
                                                                                    </label>
                                                                                    <p id={`range_compensate_leading_zeros_${verification_idx}`} className="text-gray-500">
                                                                                        Useful when you have ID numbers with leading zeros. This will help in matching the OCR extracted
                                                                                        value with the lookup table value even when the number of leading zeros is different.
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </fieldset>
                                                                </div>
                                                            </div>}

                                                            {verification.type === "pivot_range" && <div className="w-full flex flex-col gap-y-4">
                                                                <div className="">
                                                                    <label htmlFor="title" className="pt-2  pb-1 block text-sm text-gray-900">
                                                                        Lookup Table
                                                                    </label>
                                                                    <div className="w-full">
                                                                        <Dropdown
                                                                            values={lookup_tables_values}
                                                                            ids={lookup_tables_ids}
                                                                            selected={verification.pivot_range?.lookup_table_uuid || ""}
                                                                            onChange={(id: string) => updateValidationPivotRangeTable(verification_idx, id)} />
                                                                    </div>
                                                                </div>
                                                                <div className="grid grid-cols-2 gap-x-4">
                                                                    <div className="">
                                                                        <label htmlFor="title" className="pt-2 pb-1 block text-sm text-gray-900">
                                                                            Lookup Value
                                                                        </label>
                                                                        <div className="w-full">
                                                                            <Dropdown
                                                                                values={fields.map((field) => field.name)}
                                                                                ids={fields.map((field) => field.uuid)}
                                                                                selected={verification.pivot_range?.key_field_uuid.toString() || ""}
                                                                                onChange={(field_uuid: string) => updateValidationPivotRangeValue(verification_idx, "field", field_uuid)} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="">
                                                                        <label htmlFor="title" className="pt-2 pb-1 block text-sm text-gray-900">
                                                                            Lookup Column
                                                                        </label>
                                                                        <div className="w-full">
                                                                            <Dropdown
                                                                                values={getLookupTableHeaderValues(verification.pivot_range?.lookup_table_uuid || "")}
                                                                                ids={getLookupTableHeaderIds(verification.pivot_range?.lookup_table_uuid || "")}
                                                                                selected={verification.pivot_range?.key_header_idx.toString() || "0"}
                                                                                onChange={(key_header_idx: string) => updateValidationPivotRangeValue(verification_idx, "key", parseInt(key_header_idx, 10))} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="">
                                                                    <label htmlFor="title" className="pt-2 pb-1 block text-sm text-gray-900">
                                                                        Value Column
                                                                    </label>
                                                                    <div className="w-full">
                                                                        <Dropdown
                                                                            values={getLookupTableHeaderValues(verification.pivot_range?.lookup_table_uuid || "")}
                                                                            ids={getLookupTableHeaderIds(verification.pivot_range?.lookup_table_uuid || "")}
                                                                            selected={verification.pivot_range?.value_header_idx.toString() || "0"}
                                                                            onChange={(value_header_idx: string) => updateValidationPivotRangeValue(verification_idx, "value", parseInt(value_header_idx, 10))} />
                                                                    </div>
                                                                </div>
                                                                <div className="">
                                                                    <fieldset>
                                                                        <legend className="sr-only">Compensate leading zeros in ID numbers</legend>
                                                                        <div className="space-y-5">
                                                                            <div className="relative flex items-start">
                                                                                <div className="flex h-6 items-center">
                                                                                    <Checkbox
                                                                                        checked={verification.pivot_range?.compensate_leading_zeros || false}
                                                                                        setChecked={(checked) => updateValidationPivotRangeValue(verification_idx, "compensate_leading_zeros", checked)}
                                                                                        id={`pivot_ange_compensate_leading_zeros_${verification_idx}`}
                                                                                    />
                                                                                </div>
                                                                                <div className="ml-3 text-sm leading-6">
                                                                                    <label htmlFor={`pivot_ange_compensate_leading_zeros_${verification_idx}`} className="font-medium text-gray-900">
                                                                                        Compensate leading zeros in ID numbers
                                                                                    </label>
                                                                                    <p id={`pivot_ange_compensate_leading_zeros_${verification_idx}`} className="text-gray-500">
                                                                                        Useful when you have ID numbers with leading zeros. This will help in matching the OCR extracted
                                                                                        value with the lookup table value even when the number of leading zeros is different.
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </fieldset>
                                                                </div>
                                                            </div>}

                                                            {verification.type === "equals_field" && <div className="w-full flex flex-col gap-y-4">
                                                                <div className="">
                                                                    <label htmlFor="title" className="pt-2 pb-1 block text-sm text-gray-900">
                                                                        Field to compare
                                                                    </label>
                                                                    <div className="w-full">
                                                                        <Dropdown
                                                                            values={fields.map((field) => field.name)}
                                                                            ids={fields.map((field) => field.uuid)}
                                                                            selected={verification.equals_field?.field_uuid || ""}
                                                                            onChange={(field_uuid: string) => updateValidationEqualsField(verification_idx, field_uuid)} />
                                                                    </div>
                                                                </div>
                                                            </div>}

                                                            {verification.type === "regex" && <div className="w-full flex flex-col gap-y-4">
                                                                <div className="">
                                                                    <label htmlFor="title" className="pt-2 pb-1 flex flex-row">
                                                                        <span className="text-sm text-gray-900">Pattern</span>
                                                                        <span className="flex-grow" />
                                                                        <span className="text-sm text-gray-900">
                                                                            [
                                                                            <span onClick={() => updateValidationRegex(verification_idx, "^\\d+$")} className="cursor-pointer text-sky-600 hover:text-sky-800">number</span>,
                                                                            <span onClick={() => updateValidationRegex(verification_idx, "^(0?[1-9]|[12][0-9]|3[01])\\.(0?[1-9]|1[0-2])\\.(\\d{4})$")} className="cursor-pointer text-sky-600 hover:text-sky-800">date</span>,
                                                                            <span onClick={() => updateValidationRegex(verification_idx, "^.+$")} className="cursor-pointer text-sky-600 hover:text-sky-800">none-empty</span>
                                                                            ]
                                                                        </span>
                                                                    </label>
                                                                    <div className="w-full font-mono">
                                                                        <Textbox
                                                                            value={verification.regex?.pattern || ""}
                                                                            onChange={(pattern: string) => updateValidationRegex(verification_idx, pattern)} />
                                                                    </div>
                                                                </div>
                                                            </div>}
                                                        </div>)}
                                                </div>}

                                                {field?.suggestions && <div className="flex flex-col gap-y-4">
                                                    {field.suggestions.map((suggestion, suggestion_idx) =>
                                                        <div key={suggestion_idx} className="">
                                                            <div className="pt-4 flex flex-row items-center">
                                                                <div className="text-sm font-semibold">
                                                                    {(field?.verifications?.length || 0) + suggestion_idx + 1}.&nbsp;
                                                                    {suggestion.type === "map" && "Filtered Lookup Suggestion"}
                                                                </div>
                                                                <div className="grow" />
                                                                <div className="ml-2"><Button icon={TrashIcon} onClick={() => deleteSuggestion(suggestion_idx)} /></div>
                                                            </div>

                                                            {suggestion.type === "map" && <div className="w-full">
                                                                <div className="">
                                                                    <label htmlFor="title" className="pt-2  pb-1 block text-sm text-gray-900">
                                                                        Lookup Table
                                                                    </label>
                                                                    <div className="w-full">
                                                                        <Dropdown
                                                                            values={lookup_tables_values}
                                                                            ids={lookup_tables_ids}
                                                                            selected={suggestion.map?.lookup_table_uuid || ""}
                                                                            onChange={(id: string) => updateSuggestionMapTable(suggestion_idx, id)} />
                                                                    </div>
                                                                </div>
                                                                <div className="grid grid-cols-5 gap-x-4">
                                                                    <div className="col-span-2">
                                                                        <label htmlFor="title" className="pt-2 pb-1 block text-sm text-gray-900">
                                                                            Lookup Value
                                                                        </label>
                                                                        <div className="w-full">
                                                                            <Dropdown
                                                                                values={fields.map((field) => field.name)}
                                                                                ids={fields.map((field) => field.uuid)}
                                                                                selected={suggestion.map?.key_field_uuid.toString() || ""}
                                                                                onChange={(field_uuid: string) => updateSuggestionMapValue(suggestion_idx, "field", field_uuid)} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="">
                                                                        <label htmlFor="title" className="pt-2 pb-1 block text-sm text-gray-900">
                                                                            Type
                                                                        </label>
                                                                        <div className="w-full">
                                                                            <Dropdown
                                                                                values={["=", "≈"]}
                                                                                ids={["exact", "approx"]}
                                                                                selected={suggestion.map?.comparison || "exact"}
                                                                                onChange={(comparison: string) => updateSuggestionMapValue(suggestion_idx, "comparison", comparison)} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-span-2">
                                                                        <label htmlFor="title" className="pt-2 pb-1 block text-sm text-gray-900">
                                                                            Lookup Column
                                                                        </label>
                                                                        <div className="w-full">
                                                                            <Dropdown
                                                                                values={getLookupTableHeaderValues(suggestion.map?.lookup_table_uuid || "")}
                                                                                ids={getLookupTableHeaderIds(suggestion.map?.lookup_table_uuid || "")}
                                                                                selected={suggestion.map?.key_header_idx.toString() || "0"}
                                                                                onChange={(key_header_idx: string) => updateSuggestionMapValue(suggestion_idx, "key", parseInt(key_header_idx, 10))} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="gap-x-4">
                                                                    <div className="">
                                                                        <label htmlFor="title" className="pt-2 pb-1 block text-sm text-gray-900">
                                                                            Suggest Value Column
                                                                        </label>
                                                                        <div className="w-full">
                                                                            <Dropdown
                                                                                values={getLookupTableHeaderValues(suggestion.map?.lookup_table_uuid || "")}
                                                                                ids={getLookupTableHeaderIds(suggestion.map?.lookup_table_uuid || "")}
                                                                                selected={suggestion.map?.value_header_idx.toString() || "0"}
                                                                                onChange={(value_header_idx: string) => updateSuggestionMapValue(suggestion_idx, "value", parseInt(value_header_idx, 10))} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>}
                                                        </div>
                                                    )}
                                                </div>}
                                                {field?.conditions && <div className="my-6 flex flex-col gap-y-4">
                                                    {field.conditions.map((condition, condition_idx) => {
                                                        if (condition.type === "only_with") {
                                                            return (<div key={condition_idx}>
                                                                <div className="flex flex-row items-center">
                                                                    <div className="text-sm font-medium leading-6 text-gray-900">
                                                                        {(field?.verifications?.length || 0) + (field?.suggestions?.length || 0) + condition_idx + 1}.&nbsp;
                                                                        Extract only when field available
                                                                    </div>
                                                                    <div className="grow" />
                                                                    <div className="ml-2"><Button icon={TrashIcon} onClick={() => deleteCondition(condition_idx)} /></div>
                                                                </div>
                                                                <div className="w-full">
                                                                    <label htmlFor="title" className="pt-2  pb-1 block text-sm text-gray-600">
                                                                        Extract only when value of the following field is not empty
                                                                    </label>
                                                                    <div className="w-full">
                                                                        <Dropdown
                                                                            values={fields.map((field) => field.name)}
                                                                            ids={fields.map((field) => field.uuid)}
                                                                            selected={condition.field_uuid?.toString() || ""}
                                                                            onChange={(field_uuid: string) => updateCondition(condition_idx, "field_uuid", field_uuid)} />
                                                                    </div>
                                                                </div>
                                                            </div>);
                                                        } else if (condition.type === "skip_if_match") {
                                                            return (<div key={condition_idx}>
                                                                <div className="flex flex-row items-center">
                                                                    <div className="text-sm font-medium leading-6 text-gray-900">
                                                                        {(field?.verifications?.length || 0) + (field?.suggestions?.length || 0) + condition_idx + 1}.&nbsp;
                                                                        Skip row if value is matched
                                                                    </div>
                                                                    <div className="grow" />
                                                                    <div className="ml-2"><Button icon={TrashIcon} onClick={() => deleteCondition(condition_idx)} /></div>
                                                                </div>
                                                                <div className="w-full">
                                                                    <label htmlFor="title" className="pt-2  pb-1 block text-sm text-gray-600">
                                                                        Skip row if field value matches the following value
                                                                    </label>
                                                                    <div className="w-full">
                                                                        <div className="flex w-full rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-sky-600 sm:max-w-md">
                                                                            <input
                                                                                type="text"
                                                                                className="block flex-1 border-0 bg-transparent p-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                                                                placeholder="Leave empty to skip when empty"
                                                                                value={condition.field_value || ""}
                                                                                onChange={(e) => updateCondition(condition_idx, "field_value", e.target.value)}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>);
                                                        } else if (condition.type === "skip_if_includes") {
                                                            return (<div key={condition_idx}>
                                                                <div className="flex flex-row items-center">
                                                                    <div className="text-sm font-medium leading-6 text-gray-900">
                                                                        {(field?.verifications?.length || 0) + (field?.suggestions?.length || 0) + condition_idx + 1}.&nbsp;
                                                                        Skip row if lookup value is matched
                                                                    </div>
                                                                    <div className="grow" />
                                                                    <div className="ml-2"><Button icon={TrashIcon} onClick={() => deleteCondition(condition_idx)} /></div>
                                                                </div>
                                                                <div className="">
                                                                    <label htmlFor="title" className="pt-2  pb-1 block text-sm text-gray-900">
                                                                        Lookup Table
                                                                    </label>
                                                                    <div className="w-full">
                                                                        <Dropdown
                                                                            values={lookup_tables_values}
                                                                            ids={lookup_tables_ids}
                                                                            selected={condition.range?.lookup_table_uuid || ""}
                                                                            onChange={(id: string) => updateConditionRangeTable(condition_idx, id)} />
                                                                    </div>
                                                                </div>
                                                                <div className="">
                                                                    <label htmlFor="title" className="pt-2 pb-1 block text-sm text-gray-900">
                                                                        Lookup Column
                                                                    </label>
                                                                    <div className="w-full">
                                                                        <Dropdown
                                                                            values={getLookupTableHeaderValues(condition.range?.lookup_table_uuid || "")}
                                                                            ids={getLookupTableHeaderIds(condition.range?.lookup_table_uuid || "")}
                                                                            selected={condition.range?.header_idx.toString() || "0"}
                                                                            onChange={(header_idx: string) => updateConditionRangeHeader(condition_idx, parseInt(header_idx, 10))} />
                                                                    </div>
                                                                </div>
                                                            </div>);
                                                        } else {
                                                            return null;
                                                        }
                                                    })}
                                                </div>}
                                            </div>}

                                            {selected_tab === "lookup" && <div>
                                                <div className="py-2">
                                                    <label htmlFor="title" className="my-2 block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                                        Lookup Table
                                                    </label>
                                                    <div className="my-2 sm:mt-0">
                                                        <div className="w-full">
                                                            <Dropdown
                                                                values={lookup_tables_values}
                                                                ids={lookup_tables_ids}
                                                                selected={field?.compute?.lookup_map?.lookup_table_uuid || ""}
                                                                onChange={(id: string) => updateLookupFieldTable(id)} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="grid grid-cols-5 gap-x-4">
                                                    <div className="col-span-2">
                                                        <label htmlFor="title" className="my-2 block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                                            Lookup Value
                                                        </label>
                                                        <div className="my-2 sm:mt-0">
                                                            <div className="w-full">
                                                                <div className="flex w-full rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-sky-600 sm:max-w-md">
                                                                    <input
                                                                        type="text"
                                                                        className="block flex-1 border-0 bg-transparent p-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                                                        value={fields?.find((f) => f.uuid === field?.compute?.lookup_map?.key_field_uuid)?.name || ""}
                                                                        disabled={true}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="">
                                                        <label htmlFor="title" className="my-2 block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                                            Type
                                                        </label>
                                                        <div className="my-2 sm:mt-0">
                                                            <div className="w-full">
                                                                <Dropdown
                                                                    values={["=", "≈", "contains"]}
                                                                    ids={["exact", "approx", "contains"]}
                                                                    selected={field?.compute?.lookup_map?.comparison || "exact"}
                                                                    onChange={(comparison: string) => updateLookupFieldValue("comparison", comparison)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-span-2">
                                                        <label htmlFor="title" className="my-2 block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                                            Lookup Column
                                                        </label>
                                                        <div className="my-2 sm:mt-0">
                                                            <div className="w-full">
                                                                <Dropdown
                                                                    values={getLookupTableHeaderValues(field?.compute?.lookup_map?.lookup_table_uuid || "")}
                                                                    ids={getLookupTableHeaderIds(field?.compute?.lookup_map?.lookup_table_uuid || "")}
                                                                    selected={field?.compute?.lookup_map?.key_header_idx.toString() || "0"}
                                                                    onChange={(key_header_idx: string) => updateLookupFieldValue("key", parseInt(key_header_idx, 10))} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="py-2">
                                                    <label htmlFor="title" className="my-2 block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                                        Value Column
                                                    </label>
                                                    <div className="my-2 sm:mt-0">
                                                        <div className="w-full">
                                                            <Dropdown
                                                                values={getLookupTableHeaderValues(field?.compute?.lookup_map?.lookup_table_uuid || "")}
                                                                ids={getLookupTableHeaderIds(field?.compute?.lookup_map?.lookup_table_uuid || "")}
                                                                selected={field?.compute?.lookup_map?.value_header_idx.toString() || "0"}
                                                                onChange={(value_header_idx: string) => updateLookupFieldValue("value", parseInt(value_header_idx, 10))} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="my-6">
                                                    <fieldset>
                                                        <legend className="sr-only">Compensate for OCR errors</legend>
                                                        <div className="space-y-5">
                                                            <div className="relative flex items-start">
                                                                <div className="flex h-6 items-center">
                                                                    <Checkbox
                                                                        checked={field?.compute?.lookup_map?.compensate_ocr_errors || false}
                                                                        setChecked={(checked) => updateLookupFieldValue("compensate_ocr_errors", checked)}
                                                                        id="compensate_ocr_errors"
                                                                    />
                                                                </div>
                                                                <div className="ml-3 text-sm leading-6">
                                                                    <label htmlFor="compensate_ocr_errors" className="font-medium text-gray-900">
                                                                        Compensate for OCR errors
                                                                    </label>
                                                                    <p id="compensate_ocr_errors-description" className="text-gray-500">
                                                                        Useful when you have OCR errors like confusing 0 and O, 1 and I, etc. This will help in matching
                                                                        the OCR extracted value with the lookup table value.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </fieldset>
                                                </div>
                                                {field?.compute?.lookup_map?.comparison !== "contains" && <div className="my-6">
                                                    <fieldset>
                                                        <legend className="sr-only">Compensate leading zeros in ID numbers</legend>
                                                        <div className="space-y-5">
                                                            <div className="relative flex items-start">
                                                                <div className="flex h-6 items-center">
                                                                    <Checkbox
                                                                        checked={field?.compute?.lookup_map?.compensate_leading_zeros || false}
                                                                        setChecked={(checked) => updateLookupFieldValue("compensate_leading_zeros", checked)}
                                                                        id="compensate_leading_zeros"
                                                                    />
                                                                </div>
                                                                <div className="ml-3 text-sm leading-6">
                                                                    <label htmlFor="compensate_leading_zeros" className="font-medium text-gray-900">
                                                                        Compensate leading zeros in ID numbers
                                                                    </label>
                                                                    <p id="compensate_leading_zeros-description" className="text-gray-500">
                                                                        Useful when you have ID numbers with leading zeros. This will help in matching the OCR extracted
                                                                        value with the lookup table value even when the number of leading zeros is different.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </fieldset>
                                                </div>}
                                                {field?.compute?.lookup_map?.comparison === "contains" && <div className="my-6">
                                                    <fieldset>
                                                        <legend className="sr-only">Match whole words</legend>
                                                        <div className="space-y-5">
                                                            <div className="relative flex items-start">
                                                                <div className="flex h-6 items-center">
                                                                    <Checkbox
                                                                        checked={field?.compute?.lookup_map?.match_whole_word || false}
                                                                        setChecked={(checked) => updateLookupFieldValue("match_whole_word", checked)}
                                                                        id="match_whole_word"
                                                                    />
                                                                </div>
                                                                <div className="ml-3 text-sm leading-6">
                                                                    <label htmlFor="match_whole_word" className="font-medium text-gray-900">
                                                                        Match whole word
                                                                    </label>
                                                                    <p id="match_whole_word-description" className="text-gray-500">
                                                                        The lookup value has to be contained within the extracted value as a whole word (not as a part of a word).
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </fieldset>
                                                </div>}
                                            </div>}

                                            {selected_tab === "formula" && <div>
                                                <div className="py-4">
                                                    <div className="my-2 sm:mt-0">
                                                        <div className="w-full shadow border">
                                                            <CodeMirror
                                                                value={field?.compute?.formula || ""}
                                                                height="500px"
                                                                theme="light"
                                                                extensions={[javascript()]}
                                                                readOnly={!is_admin}
                                                                onChange={(value) => updateFormulaFieldValue(value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="py-2">
                                                    <label htmlFor="title" className="flex flex-col my-2 text-sm">
                                                        <span className="font-medium leading-6 text-gray-900">Available Fields</span>
                                                        <span className="text-sm text-gray-400">Can only refer to preceding fields</span>
                                                    </label>
                                                    <ul className="text-sm">
                                                        {fields.slice(0, field_idx).map((field, idx) => <li key={idx} className="flex flex-row items-center">
                                                            <span className="text-gray-600"><code>{getExcelColumnName(idx)}</code></span>
                                                            <span className="mx-2">-</span>
                                                            <span className="text-gray-400">{field.name}</span>
                                                        </li>)}
                                                        <li className="flex flex-row items-center">
                                                            <span className="text-gray-600"><code>INPUT_TEXT</code></span>
                                                            <span className="mx-2">-</span>
                                                            <span className="text-gray-400">Input document as string</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>}

                                            {selected_tab === "document_name" && <div>
                                                <div className="py-4 text-sm text-gray-500">
                                                    This field will be automatically populated with the document name.
                                                </div>
                                            </div>}

                                            {selected_tab === "metadata" && <div>
                                                <div className="py-4 text-sm text-gray-500">
                                                    <div className="py-2">
                                                        <label htmlFor="title" className="my-2 block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                                            Select which metadata to populate this field with
                                                        </label>
                                                        <div className="my-2 sm:mt-0">
                                                            <div className="w-full">
                                                                <Dropdown
                                                                    values={["Document Name", "Extraction time", "File Name(s)"]}
                                                                    ids={["document_name", "extract_ts", "filename"]}
                                                                    selected={field?.compute?.metadata?.type.toString() || "document_name"}
                                                                    onChange={(new_type: string) => updateMetadataFieldValue(new_type)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>}

                                            {selected_tab === "details" && <div>
                                                <div className="my-4">
                                                    <label htmlFor="confirm_name" className="my-2 block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                                        Confirmation page name
                                                        <p id="confirm_name" className="font-normal text-gray-500">
                                                            Use different name when displaying results on the confirmation page. Useful when the field name is too technical or long.
                                                        </p>
                                                    </label>
                                                    <div className="my-2 sm:col-span-2 sm:mt-0">
                                                        <div className="flex w-full rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-sky-600 sm:max-w-md">
                                                            <input
                                                                type="text"
                                                                className="block flex-1 border-0 bg-transparent p-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                                                value={field?.confirm_name || ""}
                                                                onChange={(e) => updateField("confirm_name", e.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="my-6">
                                                    <fieldset>
                                                        <legend className="sr-only">Hide on confirmation page</legend>
                                                        <div className="space-y-5">
                                                            <div className="relative flex items-start">
                                                                <div className="flex h-6 items-center">
                                                                    <input
                                                                        id="skip_on_confirm"
                                                                        aria-describedby="skip_on_confirm-description"
                                                                        name="skip_on_confirm"
                                                                        type="checkbox"
                                                                        checked={field?.skip_on_confirm || false}
                                                                        onChange={(e) => updateCheckbox("skip_on_confirm", e.target.checked)}
                                                                        className="h-4 w-4 rounded border-gray-300 text-sky-600 focus:ring-sky-600"
                                                                    />
                                                                </div>
                                                                <div className="ml-3 text-sm leading-6">
                                                                    <label htmlFor="skip_on_confirm" className="font-medium text-gray-900">
                                                                        Hide on confirmation page
                                                                    </label>
                                                                    <p id="skip_on_confirm-description" className="text-gray-500">
                                                                        Useful to hide fields that do not require confirmation on the confirmation page. By hiding the field,
                                                                        the user will not be distracted by fields that do not require confirmation.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </fieldset>
                                                </div>
                                            </div>}
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Transition.Root >
    )
}

type FieldsTableProps = {
    fields: t.IContextField[];
    records?: t.IRecord[];
    disabled?: boolean;
    is_editable?: boolean;
    show_delete?: boolean;
    show_settings?: boolean;
    focus_on_col?: number;
    is_demo?: boolean;
    lookup_tables?: t.ILookupTableBase[];
    indicate_array?: boolean;
    setFields?: (fields: t.IContextField[]) => void;
    setInvalidate?: () => void;
}

export function FieldsTable(props: FieldsTableProps) {
    const {
        fields: init_fields,
        records,
        disabled,
        is_editable,
        show_delete,
        show_settings,
        focus_on_col,
        is_demo,
        lookup_tables,
        indicate_array,
        setFields: setInitFields,
        setInvalidate
    } = props;

    const [fields, setFields] = useState<t.IContextField[]>(init_fields || []);
    const [new_name, setNewName] = useState<string | undefined>(undefined);
    const [field_refs, setFieldRefs] = useState<RefObject<HTMLTableCellElement>[]>(fields.map(() => createRef()));
    const [current_field_idx, setCurrentFieldIdx] = useState<number>(-1);
    const [is_field_settings_open, setIsFieldSettingsOpen] = useState<boolean>(false);
    const [field_settings_idx, setFieldSettingsIdx] = useState<number>(-1);

    useEffect(() => {
        setFields(init_fields);
    }, [init_fields]);

    useEffect(() => {
        setFieldRefs(fields.map(() => createRef()));
    }, [fields, fields.length]);

    useEffect(() => {
        if (current_field_idx !== -1 && field_refs[current_field_idx]) {
            const element = field_refs[current_field_idx]?.current;
            if (element) {
                element.focus();
                window.getSelection()?.selectAllChildren(element);
                window.getSelection()?.collapseToEnd();
            }
        }
    }, [fields, fields.length, field_refs, field_refs.length, current_field_idx]);

    useEffect(() => {
        if (focus_on_col !== undefined && focus_on_col !== -1) {
            setCurrentFieldIdx(focus_on_col);
        }
    }, [focus_on_col]);

    const update = (new_fields: t.IContextField[]) => {
        setFields(new_fields);
        if (setInitFields) { setInitFields(new_fields); }
        if (setInvalidate) { setInvalidate(); }
    };

    const handleBlur = (e: any, idx: number) => {
        setCurrentFieldIdx(-1);
        const new_fields = [...fields];
        if (new_name !== undefined && new_fields[idx].name !== new_name) {
            new_fields[idx].name = new_name;
            setNewName(undefined);
            update(new_fields);
        }
    };

    const handleInput = (e: any, idx: number) => {
        setNewName(e.target.innerText);
        if (setInvalidate) { setInvalidate(); }
    };

    const handleKeyDown = (e: any, idx: number) => {
        if (idx === fields.length - 1) {
            if (e.key === "Tab" && !e.shiftKey) {
                e.preventDefault();
                addField();
            } else if (e.key === "Enter") {
                e.preventDefault();
                addField();
            }
        }
    }

    const handleFocus = (e: any, idx: number) => {
        setCurrentFieldIdx(idx);
    }

    const addField = () => {
        if (!disabled) {
            const new_fields = [...fields];
            new_fields.push({ uuid: newUuid(), name: "", datatype: "string", type: "extract" });
            update(new_fields);
            setCurrentFieldIdx(new_fields.length - 1);
        }
    };

    const updateField = (field_idx: number, field: t.IContextField) => {
        const new_fields = [...fields];
        new_fields[field_idx] = field;
        update(new_fields);
    };

    const moveField = (curr_idx: number, diff_idx: number) => {
        const field = fields[curr_idx];
        const new_field_idx = curr_idx + diff_idx;
        const new_fields = [
            ...fields.slice(0, curr_idx),
            ...fields.slice(curr_idx + 1)
        ];
        new_fields.splice(new_field_idx, 0, field);
        update(new_fields);
        setFieldSettingsIdx(new_field_idx);
    }

    const deleteField = (field_idx: number) => {
        setIsFieldSettingsOpen(false);
        const new_fields = [...fields];
        new_fields.splice(field_idx, 1);
        update(new_fields);
    }

    const createField = (after_field_idx: number, field: t.IContextField) => {
        const new_fields = [...fields];
        new_fields.splice(after_field_idx + 1, 0, field);
        update(new_fields);
        setFieldSettingsIdx(after_field_idx + 1);
    }

    const field_col_span = 1 + (show_settings ? 1 : 0) + (show_delete ? 1 : 0);

    return <Fragment>
        <table className="py-4 text-xs md:text-base">
            <thead>
                <tr>
                    <th className="w-5 bg-gray-100 border border-gray-300"></th>
                    {fields.map((field, idx) =>
                        <Fragment key={idx}>
                            <th key={idx}
                                className={classNames("py-1 px-4 bg-gray-100 border border-gray-300 cursor-text hover:bg-sky-100 text-left text-sm font-normal align-top w-32 focus:ring-1 focus:ring-sky-500",
                                    (is_demo === true ? "min-w-[80px] max-w-[150px]" : "min-w-[150px] max-w-[250px]"),
                                    (field.type === "compute" ? "italic" : ""),
                                    (field.name.length === 0 && current_field_idx !== idx) ? "text-gray-400" : "text-gray-900")}
                                contentEditable={disabled !== true && is_editable === true}
                                onBlur={e => handleBlur(e, idx)}
                                onInput={e => handleInput(e, idx)}
                                onFocus={e => handleFocus(e, idx)}
                                onKeyDown={e => handleKeyDown(e, idx)}
                                ref={field_refs[idx]}
                                dangerouslySetInnerHTML={{ __html: (field.name.length === 0 && current_field_idx !== idx) ? "click to edit" : field.name }}
                            />
                            {show_delete && <th key={idx + fields.length}
                                className="py-1 px-2 bg-gray-100 text-gray-400 border border-gray-300 cursor-pointer hover:bg-sky-300 hover:text-white w-4"
                                onClick={() => { deleteField(idx); }}
                            >
                                <MinusIcon className="h-4 w-4 " />
                            </th>}
                            {show_settings && <th key={idx + fields.length}
                                className={classNames(
                                    "py-1 px-2 bg-gray-200 text-gray-400 border border-gray-300  hover:text-white w-4",
                                    disabled ? "cursor-not-allowed" : "cursor-pointer hover:bg-sky-300"
                                )}
                                onClick={() => { if (!disabled) { setFieldSettingsIdx(idx); setIsFieldSettingsOpen(true); } }}
                            >
                                <Cog6ToothIcon className="h-4 w-4 " />
                            </th>}
                        </Fragment>)}
                    {is_editable && <th className="py-1 px-4 bg-gray-50 hover:bg-sky-300 border border-gray-300 cursor-pointer" onClick={addField}>+</th>}
                </tr>
            </thead>
            <tbody className="text-sm text-gray-600">
                {records && records.map(({ val: record }, idx) => <tr key={idx}>
                    <td className="w-5 py-1 px-4 bg-gray-100 border border-gray-300 text-center font-semibold align-top">{idx + 1}</td>
                    {fields.map((field, idx) => <td key={idx} colSpan={field_col_span} className="py-1 px-4 border text-left text-sm align-top">{record[field.name]}</td>)}
                    {is_editable && <td className="py-1 px-4 border border-dashed"></td>}
                </tr>)}
                {(records === undefined || indicate_array === true) && <tr>
                    <td className="w-5 py-1 px-4 bg-gray-50 border border-gray-300">...</td>
                    {fields.map((field, idx) => <td key={idx} colSpan={field_col_span} className="py-1 px-4 border border-dashed"></td>)}
                    {is_editable && <td className="py-1 px-4 border border-dashed"></td>}
                </tr>}
            </tbody>
        </table>
        {is_editable && <FieldSettings
            open={is_field_settings_open}
            field_idx={field_settings_idx}
            fields_count={fields.length}
            field={fields[field_settings_idx]}
            fields={fields}
            lookup_tables={lookup_tables || []}
            setOpen={setIsFieldSettingsOpen}
            setField={updateField}
            moveField={moveField}
            deleteField={deleteField}
            createField={createField} />}
    </Fragment>;
}

type HierarchicalFieldsTableProps = {
    fields: t.IContextField[];
}

export function HierarchicalFieldsTable(props: HierarchicalFieldsTableProps) {
    const { fields } = props;

    const { example } = getHierarchicalContextExample(fields);

    return <div className="">
        <div className="pb-2 text-base text-gray-900 ">
            Template Schema
        </div>
        <HierarchicalRecord val={example} input_documents={[]} show_all={true} />
    </div >;
}