import { Fragment } from "react";
import { useSelector } from "react-redux";

import { classNames } from "../lib/utils";
import { selectIsSidebarLarge } from "../lib/scraper.slice";

import { Backend } from "../lib/backend";
import { Button } from "../components/Button";

export function AdminBackgroundJobs() {
    const is_sidebar_large = useSelector(selectIsSidebarLarge);

    return <Fragment>
        <div className={classNames("flex-row lg:fixed lg:right-0 lg:inset-y-0 overflow-y-auto", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
            <div className="m-16 mb-6">
                <h2 className="text-xl font-semibold leading-7 text-gray-600 sm:truncate sm:text-3xl sm:tracking-tight">
                    Background Jobs
                </h2>
                <div className="pt-5 border-b-4 border-sky-600" />
            </div>

            <div className="flex flex-col px-10 py-5">
                <div className="w-full">
                    <table className="min-w-full divide-y divide-gray-300">
                        <thead className="bg-gray-50">
                            <tr>
                                <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Job
                                </th>
                                <th className="w-60 px-10 py-3.5 text-center text-sm font-semibold text-gray-900">
                                </th>
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                            <tr>
                                <td className="py-5 pl-4 pr-3 text-sm sm:pl-0">
                                    Recalculate all embeddings for non-deleted context examples and items.
                                </td>
                                <td className="py-5 pl-4 pr-3 text-right text-sm sm:pl-0">
                                    <Button onClick={() => { Backend.recalcEmbeddings(); }} text="Execute" />
                                </td>
                            </tr>
                            <tr>
                                <td className="py-5 pl-4 pr-3 text-sm sm:pl-0">
                                    Close old running jobs and set their error message to "Timeout".
                                </td>
                                <td className="py-5 pl-4 pr-3 text-right text-sm sm:pl-0">
                                    <Button onClick={() => { Backend.runBackgroundJobs({ job_name: "close_timeout_extract_jobs" }); }} text="Execute" />
                                </td>
                            </tr>
                            <tr>
                                <td className="py-5 pl-4 pr-3 text-sm sm:pl-0">
                                    Process pending "send email digest" jobs.
                                </td>
                                <td className="py-5 pl-4 pr-3 text-right text-sm sm:pl-0">
                                    <Button onClick={() => { Backend.runBackgroundJobs({ job_name: "forward_email_digests" }); }} text="Execute" />
                                </td>
                            </tr>
                            <tr>
                                <td className="py-5 pl-4 pr-3 text-sm sm:pl-0">
                                    Run validation of context fields in database against schema.
                                </td>
                                <td className="py-5 pl-4 pr-3 text-right text-sm sm:pl-0">
                                    <Button href="/api/admin/validation_job_results?type=field" open_in_new_tab={true} text="Get Results" />
                                    <Button onClick={() => { Backend.runBackgroundJobs({ job_name: "validate_db_context_fields" }); }} text="Execute" />
                                </td>
                            </tr>
                            <tr>
                                <td className="py-5 pl-4 pr-3 text-sm sm:pl-0">
                                    Run validation of context extract params in database against schema.
                                </td>
                                <td className="py-5 pl-4 pr-3 text-right text-sm sm:pl-0">
                                    <Button href="/api/admin/validation_job_results?type=extract_params" open_in_new_tab={true} text="Get Results" />
                                    <Button onClick={() => { Backend.runBackgroundJobs({ job_name: "validate_db_context_extract_params" }); }} text="Execute" />
                                </td>
                            </tr>
                            <tr>
                                <td className="py-5 pl-4 pr-3 text-sm sm:pl-0">
                                    Run validation of scrape records in database against schema.
                                </td>
                                <td className="py-5 pl-4 pr-3 text-right text-sm sm:pl-0">
                                    <Button href="/api/admin/validation_job_results?type=record" open_in_new_tab={true} text="Get Results" />
                                    <Button onClick={() => { Backend.runBackgroundJobs({ job_name: "validate_db_scrape_records" }); }} text="Execute" />
                                </td>
                            </tr>
                            <tr>
                                <td className="py-5 pl-4 pr-3 text-sm sm:pl-0">
                                    Run validation of endpoint output sheets in database against schema.
                                </td>
                                <td className="py-5 pl-4 pr-3 text-right text-sm sm:pl-0">
                                    <Button href="/api/admin/validation_job_results?type=output_sheet" open_in_new_tab={true} text="Get Results" />
                                    <Button onClick={() => { Backend.runBackgroundJobs({ job_name: "validate_db_endpoint_output_sheets" }); }} text="Execute" />
                                </td>
                            </tr>
                            <tr>
                                <td className="py-5 pl-4 pr-3 text-sm sm:pl-0">
                                    Run validation of endpoint details in database against schema.
                                </td>
                                <td className="py-5 pl-4 pr-3 text-right text-sm sm:pl-0">
                                    <Button href="/api/admin/validation_job_results?type=endpoint_details" open_in_new_tab={true} text="Get Results" />
                                    <Button onClick={() => { Backend.runBackgroundJobs({ job_name: "validate_db_endpoint_details" }); }} text="Execute" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>


            </div>
        </div>
    </Fragment >;
};
