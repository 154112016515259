import { Fragment, useEffect, useState } from "react";

import {
    IContextBase,
    IEndpointOutputNameElement
} from "../lib/types";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { DropdownMenu } from "./DropdownMenu";

type EndpointOutputNameProps = {
    object_contexts: IContextBase[];
    elements: IEndpointOutputNameElement[];
    addElement: (context_uuid: string, field_uuid: string) => void;
    removeElement: (element_idx: number) => void;
    title?: string;
}

interface IOutputNameField {
    context_uuid: string;
    field_uuid: string;
    field_name: string;
}

export function EndpointOutputName(props: EndpointOutputNameProps) {
    const { object_contexts, elements, addElement, removeElement, title } = props;

    const [fields, setFields] = useState<IOutputNameField[]>([]);
    const [filename_fields, setFilenameFields] = useState<IOutputNameField[]>([]);

    useEffect(() => {
        // initialize fields
        const new_fields: IOutputNameField[] = object_contexts.flatMap(c => c.fields.map(f => ({
            context_uuid: c.uuid,
            field_uuid: f.uuid,
            field_name: f.name
        })));
        setFields(new_fields);
    }, [object_contexts]);

    useEffect(() => {
        const new_filename_fields: (IOutputNameField | undefined)[] = elements.map(e => {
            return fields.find(f => f.context_uuid === e.context_uuid && f.field_uuid === e.field_uuid);
        });
        setFilenameFields(new_filename_fields.filter(f => f !== undefined) as IOutputNameField[]);
    }, [fields, elements]);

    return <div className="w-full flex flex-col">
        <div className="flex flex-row items-center">
            <label className="flex-grow text-sm font-medium text-gray-700">{title ? title : "Custom filename"}</label>
            <DropdownMenu title="Add field" items={fields.map(f => ({
                title: f.field_name,
                onClick: () => { addElement(f.context_uuid, f.field_uuid) }
            }))} />
        </div>
        <div className="mt-3 px-2 py-1 flex flex-row flex-wrap items-center rounded-md shadow-sm ring-1 ring-inset ring-gray-300">
            {filename_fields.map((f, idx) => {
                return <Fragment key={idx}>
                    {idx > 0 && <span className="text-sm h-4 w-3 border-b-2 border-gray-400"></span>}
                    <div className="p-1 flex flex-row items-center">
                        <span className="flex items-center justify-center p-1 h-6 text-sm bg-sky-100 border border-sky-200">
                            {f.field_name}
                        </span>
                        <span
                            className="flex items-center justify-center p-0.5 h-6 text-sm bg-sky-200 cursor-pointer hover:bg-sky-400"
                            onClick={() => removeElement(idx)}>
                            <XMarkIcon className="h-4 w-4 text-sky-600" />
                        </span>
                    </div>
                </Fragment>
            })}
            {filename_fields.length === 0 && <div className="p-1"><span className="p-1 h-6 text-sm text-gray-400">No fields available</span></div>}
        </div>
        <div className="mt-3">
        </div>
    </div>
}