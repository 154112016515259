import {
    useEffect,
    useState,
    Fragment
} from "react";
import {
    useNavigate,
    useParams
} from "react-router-dom";
import { useSelector } from "react-redux";

import { ArrowRightIcon } from "@heroicons/react/24/outline";

import {
    classNames,
    setDocumentTitle
} from "../lib/utils";
import {
    selectEnv,
    selectIsSidebarLarge
} from "../lib/scraper.slice";
import {
    IContextBase,
    IEndpoint,
    IEndpointBase,
    IEndpointOutputColumn,
    ILookupTableBase
} from "../lib/types";
import {
    Backend,
    BackendObj
} from "../lib/backend";

import { Button } from "../components/Button";
import { Dropdown } from "../components/Dropdown";
import { EndpointOutputColumns } from "../components/EndpointOutputColumns";
import { LoadingSpinner, LoadingSpinnerLimit } from "../components/LoadingSpinner";

export function NewEndpointToLookupTableMapping() {
    const navigate = useNavigate();

    const { source_uuid, mapping_uuid } = useParams<{
        source_uuid: string | undefined,
        mapping_uuid: string | undefined
    }>();

    const is_sidebar_large = useSelector(selectIsSidebarLarge);
    const env = useSelector(selectEnv);

    const [is_endpoints_enabled, setIsEndpointsEnabled] = useState<boolean>(false);
    const [endpoints, setEndpoints] = useState<IEndpointBase[] | undefined>(undefined);
    const [selected_endpoint_uuid, setSelectedEndpointUuid] = useState<string | undefined>(undefined);
    const [selected_endpoint, setSelectedEndpoint] = useState<IEndpoint | undefined>(undefined);
    const [is_lookup_tables_enabled, setIsLookupTablesEnabled] = useState<boolean>(false);
    const [lookup_tables, setLookupTables] = useState<ILookupTableBase[] | undefined>(undefined);
    const [selected_lookup_table_uuid, setSelectedLookupTableUuid] = useState<string | undefined>(undefined);
    const [selected_lookup_table, setSelectedLookupTable] = useState<ILookupTableBase | undefined>(undefined);
    const [all_contexts, setAllContexts] = useState<IContextBase[] | undefined>(undefined);
    const [contexts, setContexts] = useState<IContextBase[] | undefined>(undefined);
    const [selected_context_uuid, setSelectedContextUuid] = useState<string | undefined>(undefined);
    const [selected_context, setSelectedContext] = useState<IContextBase | undefined>(undefined);
    const [columns, setColumns] = useState<IEndpointOutputColumn[]>([]);
    const [is_loading, setIsLoading] = useState<boolean>(false);

    const is_edit = mapping_uuid !== undefined;

    useEffect(() => {
        const init = async () => {
            // get lists for dropdowns
            const endpoints = await Backend.getEndpoints();
            setEndpoints(endpoints);
            const lookup_tables = await Backend.getLookupTables();
            setLookupTables(lookup_tables);
            const contexts = await Backend.getContexts();
            setAllContexts(contexts);

            // check if any preselected and what allow user to select
            if (is_edit) {
                setIsEndpointsEnabled(false);
                setIsLookupTablesEnabled(false);
                const { mapping } = await BackendObj.extractions.getEndpointToLookupTableMapping({ endpoint_to_lookup_table_uuid: mapping_uuid });
                setSelectedEndpointUuid(mapping?.endpoint_uuid);
                setSelectedEndpoint(mapping?.endpoint);
                setSelectedLookupTableUuid(mapping?.lookup_table_uuid);
                setSelectedLookupTable(mapping?.lookup_table);
                setContexts(contexts.filter(c => mapping?.endpoint?.context_uuids.includes(c.uuid)));
                setSelectedContextUuid(mapping?.mapping.context_uuid);
                setSelectedContext(contexts.find(c => c.uuid === mapping?.mapping.context_uuid));
                setColumns(mapping?.mapping.columns || []);
            } else {
                let load_endpoint_uuid: string | undefined = undefined;
                let load_lookup_table_uuid: string | undefined = undefined;
                if (endpoints.find(e => e.uuid === source_uuid)) {
                    setIsEndpointsEnabled(false);
                    load_endpoint_uuid = source_uuid;
                    setIsLookupTablesEnabled(true);
                    load_lookup_table_uuid = lookup_tables[0].uuid;
                } else if (lookup_tables.find(lt => lt.uuid === source_uuid)) {
                    setIsEndpointsEnabled(true);
                    load_endpoint_uuid = endpoints[0].uuid;
                    setIsLookupTablesEnabled(false);
                    load_lookup_table_uuid = source_uuid;
                }

                if (load_endpoint_uuid) {
                    const endpoint = await Backend.getEndpoint({ endpoint_uuid: load_endpoint_uuid });
                    setSelectedEndpointUuid(load_endpoint_uuid);
                    setSelectedEndpoint(endpoint);
                    // filter contexts based on endpoint
                    const endpoint_contexts = contexts.filter(c => endpoint?.context_uuids.includes(c.uuid));
                    setContexts(endpoint_contexts);
                    setSelectedContextUuid(endpoint_contexts[0].uuid);
                    setSelectedContext(endpoint_contexts[0]);
                }
                if (load_lookup_table_uuid) {
                    const lookup_table = lookup_tables.find(lt => lt.uuid === load_lookup_table_uuid) || undefined;
                    setSelectedLookupTableUuid(load_lookup_table_uuid);
                    setSelectedLookupTable(lookup_table);
                    setColumns(lookup_table?.headers.map((header, idx) => {
                        return { name: header, type: "const" as "const" | "field", const_value: "" };
                    }) || []);
                }
            }
        };
        init();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (mapping_uuid === undefined) {
            setDocumentTitle("Create Endpoint to Lookup Table Connection", env);
        } else {
            setDocumentTitle(`Edit Endpoint to Lookup Table Connection`, env);
        }
    }, [mapping_uuid, env]);

    const onCreate = async () => {
        if (selected_endpoint_uuid && selected_lookup_table_uuid && selected_context_uuid) {
            setIsLoading(true);
            await BackendObj.extractions.createEndpointToLookupTableMapping({
                endpoint_uuid: selected_endpoint_uuid,
                lookup_table_uuid: selected_lookup_table_uuid,
                mapping: {
                    name: `${selected_endpoint?.name} to ${selected_lookup_table?.name}`,
                    context_uuid: selected_context_uuid,
                    columns
                }
            });
            navigate(`/endpoints/${source_uuid}`);
        }
    };

    const onUpdate = () => {
        if (mapping_uuid && selected_endpoint_uuid && selected_lookup_table_uuid && selected_context_uuid) {
            setIsLoading(true);
            BackendObj.extractions.updateEndpointToLookupTableMapping({
                endpoint_to_lookup_table_uuid: mapping_uuid,
                mapping: {
                    name: `${selected_endpoint?.name} to ${selected_lookup_table?.name}`,
                    context_uuid: selected_context_uuid,
                    columns
                }
            }).then(() => {
                navigate(`/endpoints/${source_uuid}`);
            });
        }
    };

    const setEndpoint = async (endpoint_uuid: string) => {
        const endpoint = await Backend.getEndpoint({ endpoint_uuid });
        setSelectedEndpointUuid(endpoint_uuid);
        setSelectedEndpoint(endpoint);
        // reset context
        const endpoint_contexts = all_contexts ? all_contexts.filter(c => endpoint?.context_uuids.includes(c.uuid)) : [];
        setContexts(endpoint_contexts);
        setSelectedContextUuid(endpoint_contexts[0].uuid);
        setSelectedContext(endpoint_contexts[0]);
        setColumns(selected_lookup_table?.headers.map((header, idx) => {
            return { name: header, type: "const" as "const" | "field", const_value: "" };
        }) || []);
    }

    const setLookupTable = (lookup_table_uuid: string) => {
        const lookup_table = lookup_tables?.find(lt => lt.uuid === lookup_table_uuid) || undefined;
        setSelectedLookupTableUuid(lookup_table_uuid);
        setSelectedLookupTable(lookup_table);
        setColumns(lookup_table?.headers.map((header, idx) => {
            return { name: header, type: "const" as "const" | "field", const_value: "" };
        }) || []);
    }

    const setContext = (context_uuid: string) => {
        setSelectedContextUuid(context_uuid);
        setSelectedContext(all_contexts?.find(c => c.uuid === context_uuid));
        setColumns(selected_lookup_table?.headers.map((header, idx) => {
            return { name: header, type: "const" as "const" | "field", const_value: "" };
        }) || []);
    }

    const setColumn = (column_idx: number, column: IEndpointOutputColumn) => {
        const new_columns = [...columns];
        new_columns[column_idx] = column;
        setColumns(new_columns);
    };

    if (endpoints === undefined || lookup_tables === undefined || all_contexts === undefined) {
        return <div className={classNames("hidden lg:fixed lg:right-0 lg:inset-y-0 lg:flex lg:flex-row", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
            <LoadingSpinner />
        </div>;
    }

    return <div className={classNames("flex-row lg:fixed lg:right-0 lg:inset-y-0 overflow-y-auto", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
        <div className={classNames("h-16 bg-white border-b-gray-200 border-b lg:fixed lg:right-0", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
            <div className="container px-10 py-4 max-w-5xl">
                <div className="flex flex-row items-center">
                    <h2 className="text-xl font-semibold leading-7 text-gray-900 sm:truncate sm:text-2xl sm:tracking-tight">
                        {is_edit ? "Edit" : "Create"} Connection
                    </h2>
                    <div className="flex-grow" />
                    {!is_edit && <Button
                        text="Create"
                        highlight={true}
                        loading={is_loading}
                        onClick={onCreate} />}
                    {is_edit && <Button
                        text="Update"
                        highlight={true}
                        loading={is_loading}
                        onClick={onUpdate} />}
                </div>
            </div>
        </div>
        <div className="flex flex-col w-full lg:pt-24">
            <div className="px-10 max-w-5xl ">
                <div className="pb-6 grid grid-cols-7 items-center w-full border-b border-gray-900/10">
                    <div className="col-span-3">
                        <Dropdown
                            values={endpoints?.map((e) => e.name) ?? []}
                            ids={endpoints?.map((e) => e.uuid) ?? []}
                            selected={selected_endpoint_uuid ?? ""}
                            disabled={!is_endpoints_enabled}
                            onChange={setEndpoint} />
                    </div>
                    <div className="flex flex-col items-center">
                        <ArrowRightIcon className="h-6 w-6 text-gray-500" />
                    </div>
                    <div className="col-span-3">
                        <Dropdown
                            values={lookup_tables?.map((lt) => lt.name) ?? []}
                            ids={lookup_tables?.map((lt) => lt.uuid) ?? []}
                            selected={selected_lookup_table_uuid ?? ""}
                            disabled={!is_lookup_tables_enabled}
                            onChange={setLookupTable} />
                    </div>
                </div>
            </div>
            {selected_context && <Fragment>
                <div className="px-10 max-w-5xl">
                    <div className="pt-6 pb-3 grid grid-cols-7 items-center">
                        <div className="text-sm">Template:</div>
                        <div className="col-span-3">
                            <Dropdown
                                values={contexts?.map((c) => c.name) ?? []}
                                ids={contexts?.map((c) => c.uuid) ?? []}
                                selected={selected_context_uuid ?? ""}
                                onChange={setContext} />
                        </div>
                    </div>
                </div>
                <div className="px-10 py-3 max-w-5xl">
                    {selected_context && <EndpointOutputColumns
                        context={selected_context}
                        object_contexts={contexts?.filter(c => c.output_type === "object" && c.uuid !== selected_context.uuid) ?? []}
                        columns={columns}
                        show_hash_key={true}
                        setColumn={setColumn}
                    />}
                </div>
            </Fragment>}
            {selected_context === undefined && <div className="px-10 py-3 max-w-5xl">
                <LoadingSpinnerLimit />
            </div>}
        </div>
    </div >;
}