import {
    Fragment,
    useEffect,
    useState
} from "react";
import { useNavigate } from "react-router-dom";

import { classNames, redirectToExternalPage } from "../lib/utils";
import Notification from "./Notification";

type ButtonProps = {
    icon?: any;
    icon_text?: string;
    text?: string;
    tooltip?: string;
    disabled?: boolean;
    disabled_warning?: string;
    loading?: boolean;
    onClick?: () => void;
    href?: string;
    highlight?: boolean;
    open_in_new_tab?: boolean;
}

export function Button(props: ButtonProps) {
    const navigate = useNavigate();

    const { icon, icon_text, text, tooltip, disabled, disabled_warning, loading, onClick, href, highlight, open_in_new_tab } = props;

    const [show_disabled_warning, setShowDisabledWarning] = useState(false);

    useEffect(() => {
        if (!disabled) { setShowDisabledWarning(false); }
    }, [disabled]);

    const onClickHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        if (disabled) {
            setShowDisabledWarning(true);
            return;
        }
        if (href !== undefined) {
            if (open_in_new_tab) {
                redirectToExternalPage(href, true);
            } else if (href.startsWith("mailto:")) {
                redirectToExternalPage(href);
            } else if (href.startsWith("http")) {
                redirectToExternalPage(href);
            } else {
                navigate(href);
            }
        } else if (onClick !== undefined) {
            onClick();
        }
    };

    return <Fragment>
        <button type="button" onClick={onClickHandler} title={tooltip}
            className={classNames(
                "rounded-md mx-1 px-2.5 py-1.5 text-sm font-semibold shadow-sm ring-1 ring-inset whitespace-nowrap",
                disabled ? "bg-gray-100 text-gray-500 ring-gray-300 cursor-not-allowed" :
                    highlight ? "bg-sky-600 text-white ring-sky-600 hover:bg-sky-500" :
                        "bg-white text-gray-700 ring-gray-300 hover:bg-gray-50"
            )}>
            {icon && <props.icon className={classNames(
                "w-5 h-5 inline-block",
                text !== undefined && text.length > 0 ? "mr-1" : "",
                highlight && !disabled ? "text-white" :
                    (text !== undefined ? "text-gray-400" :
                        highlight ? "text-white" :
                            "text-gray-700")
            )} />}
            {icon_text && <span className="text-gray-400 mr-1">{icon_text}</span>}
            {loading && <i className="fas fa-spinner fa-spin mt-1" />}{text !== undefined ? <>&nbsp;</> : null}{text}
        </button>
        {disabled && disabled_warning && show_disabled_warning &&
            <Notification title="Warning" message={disabled_warning} show={true} setShow={setShowDisabledWarning} />}
    </Fragment>;
}

export type GroupButtonProps = {
    icon?: any;
    text: string;
    href?: string;
    onClick?: () => void;
    open_in_new_tab?: boolean;
    skip?: boolean;
}

function ButtonIcon(props: { icon?: any, has_text: boolean }) {
    if (props.icon === undefined) { return null; }
    return <props.icon className={classNames("w-5 h-5 inline-block", props.has_text ? "mr-1 text-gray-400" : "text-gray-700")} />;
}

type ButtonGroupProps = {
    buttons: GroupButtonProps[];
    disabled?: boolean;
}

export function ButtonGroup(props: ButtonGroupProps) {
    const navigate = useNavigate();

    const { buttons: all_buttons, disabled } = props;

    const buttons = all_buttons.filter(button => !button.skip);

    const onClickHandler = (button: GroupButtonProps) => {
        if (disabled) { return; }
        const { href, onClick, open_in_new_tab } = button;
        if (href !== undefined) {
            if (open_in_new_tab) {
                redirectToExternalPage(href, true);
            } else if (href.startsWith("mailto:")) {
                redirectToExternalPage(href);
            } else if (href.startsWith("http")) {
                redirectToExternalPage(href);
            } else {
                navigate(href);
            }
        } else if (onClick !== undefined) {
            onClick();
        }
    };

    const onClickWrapper = (button: GroupButtonProps, event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        onClickHandler(button);
    };

    if (buttons.length === 0) {
        return null;
    }

    if (buttons.length === 1) {
        return <Button icon={buttons[0].icon} text={buttons[0].text} onClick={() => onClickHandler(buttons[0])} />;
    }

    return <span className="mx-1 isolate inline-flex rounded-md shadow-sm">
        <button
            type="button"
            className={classNames(
                "relative inline-flex items-center rounded-l-md px-3 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10",
                disabled ? "bg-gray-100 text-gray-500 cursor-not-allowed" : "bg-white text-gray-700 hover:bg-gray-50"
            )}
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => onClickWrapper(buttons[0], e)}
        >
            <ButtonIcon icon={buttons[0].icon} has_text={buttons[0].text.length > 0} />
            {buttons[0].text}
        </button>
        {buttons.slice(1, -1).map((button, idx) => (<button
            key={idx}
            type="button"
            className={classNames(
                "relative -ml-px inline-flex items-center px-3 py-2 text-sm font-semibold ring-1 ring-inset ring-gray-300 focus:z-10",
                disabled ? "bg-gray-100 text-gray-500 cursor-not-allowed" : "bg-white text-gray-700 hover:bg-gray-50"
            )}
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => onClickWrapper(button, e)}
        >
            <ButtonIcon icon={button.icon} has_text={buttons[0].text.length > 0} />
            {button.text}
        </button>))}
        <button
            type="button"
            className={classNames(
                "relative -ml-px inline-flex items-center rounded-r-md px-3 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10",
                disabled ? "bg-gray-100 text-gray-500 cursor-not-allowed" : "bg-white text-gray-700 hover:bg-gray-50"
            )}
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => onClickWrapper(buttons[buttons.length - 1], e)}
        >
            <ButtonIcon icon={buttons[buttons.length - 1].icon} has_text={buttons[0].text.length > 0} />
            {buttons[buttons.length - 1].text}
        </button>
    </span>;
}