import {
    IContextBase,
    IEndpointBase,
    IItemBase,
    IItemSlim,
    IOrganization
} from "./types";

export function parseOrganization(o: any): IOrganization {
    return {
        uuid: o.uuid,
        name: o.name,
        tags: o.tags,
        type: o.type,
        models_overrides: o.models_overrides
    }
}

export function parseContextBase(c: any): IContextBase {
    return {
        uuid: c.uuid,
        name: c.name,
        org_uuid: c.org_uuid,
        facts: c.facts,
        fields: c.fields,
        postprocess: c.postprocess,
        extract_params: {
            prompt_output_format: c.extract_params.prompt_output_format,
            remove_duplicate_records: c.extract_params.remove_duplicate_records,
            default_decimal_separator: c.extract_params.default_decimal_separator,
            detect_decimal_separator: c.extract_params.detect_decimal_separator,
            extraction_strategy: c.extract_params.extraction_strategy,
            try_auto_heal: c.extract_params.try_auto_heal,
            preprocess_excel_strategy: c.extract_params.preprocess_excel_strategy,
            preprocess_ocr_strategy: c.extract_params.preprocess_ocr_strategy,
            orientation_segments_strategy: c.extract_params.orientation_segments_strategy,
            max_partial_responses: c.extract_params.max_partial_responses,
            models_overrides: c.extract_params.models_overrides,
            admin_prompts: c.extract_params.admin_prompts
        },
        output_type: c.output_type,
        email_address: c.email_address,
        created_at: c.created_at
    };
}

export function parseItemBase(i: any): IItemBase {
    return {
        uuid: i.uuid,
        name: i.name,
        documents: i.documents,
        created_at: i.created_at,
        endpoint_uuid: i.endpoint_uuid,
        details: i.details,
        extract_confirmations_uuid: i.extract_confirmations_uuid,
        extract_confirmations_status: i.extract_confirmations_status
    };
}

export function parseItemSlim(i: any): IItemSlim {
    return {
        uuid: i.uuid,
        name: i.name,
        created_at: i.created_at,
        context_or_endpoint_name: i.context_or_endpoint_name,
        extract_confirmations_status: i.extract_confirmations_status
    };
}

export function parseEndpointBase(e: any): IEndpointBase {
    return {
        uuid: e.uuid,
        org_uuid: e.org_uuid,
        user_uuid: e.user_uuid,
        type: e.type,
        name: e.name,
        description: e.description,
        ip_whitelist: e.ip_whitelist,
        details: {
            store_extractions: e.details.store_extractions,
            require_confirmation: e.details.require_confirmation,
            require_confirmation_users: e.details.require_confirmation_users,
            join_object_extractions: e.details.join_object_extractions,
            only_process_attachments: e.details.only_process_attachments,
            unit_of_processing: e.details.unit_of_processing,
            reply_to_sender: e.details.reply_to_sender,
            reply_file_format: e.details.reply_file_format,
            reply_file_extension: e.details.reply_file_extension,
            reply_file_mimetype: e.details.reply_file_mimetype,
            reply_include_input: e.details.reply_include_input,
            forward_email_address: e.details.forward_email_address,
            forward_email_frequency: e.details.forward_email_frequency,
            forward_email_digest_hour_utc: e.details.forward_email_digest_hour_utc,
            forward_email_digest_group_field_uuid: e.details.forward_email_digest_group_field_uuid,
            passthrough_email_address: e.details.passthrough_email_address,
            passthrough_email_subject: e.details.passthrough_email_subject,
            webhook_url: e.details.webhook_url,
            webhook_include_input: e.details.webhook_include_input,
            webhook_version: e.details.webhook_version,
            webhook_retry_count: e.details.webhook_retry_count,
            webhook_store_payload: e.details.webhook_store_payload,
            truncate_long_text: e.details.truncate_long_text,
            handlebar_template: e.details.handlebar_template
        },
        output_sheets: e.output_sheets,
        output_name: e.output_name,
        created_at: e.created_at
    };
}
