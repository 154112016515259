import {
    createRef,
    Fragment,
    RefObject,
    useEffect,
    useState
} from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import {
    MinusIcon
} from "@heroicons/react/24/outline";

import {
    selectEnv,
    selectIsSidebarLarge,
    selectMemberships
} from "../lib/scraper.slice";
import {
    ORG_ROLES,
    ORG_TYPES
} from "../lib/consts";
import {
    IExcelArraySheet
} from "../lib/types";
import {
    classNames,
    getExcelColumnName,
    setDocumentTitle
} from "../lib/utils";
import { Backend } from "../lib/backend";

import {
    LoadingSpinner
} from "../components/LoadingSpinner";
import { Button } from "../components/Button";
import { OrgPill } from "../components/OrgPill";
import { Dropdown } from "../components/Dropdown";
import { Sheet } from "../components/Sheets";
import { LookupTableDropArea } from "./UploadLookupTable";

export function NewLookupTable() {
    const navigate = useNavigate();

    const is_sidebar_large = useSelector(selectIsSidebarLarge);
    const env = useSelector(selectEnv);

    const memberships = useSelector(selectMemberships);
    // get list of admin or editor orgs, since only admin can create or edit templates
    const admin_orgs = memberships.filter((m) => (m.role === ORG_ROLES.admin)).map((m) => m.org);
    // default is personal org, if not available, use first org
    const default_org_uuid = admin_orgs.find((org) => org.type === ORG_TYPES.personal)?.uuid || admin_orgs[0].uuid || "";

    const { lookup_table_uuid } = useParams<{ lookup_table_uuid: string | undefined }>();

    const [is_init, setIsInit] = useState<boolean>(false);
    const [filename, setFilename] = useState<string | undefined>(undefined);
    const [name, setName] = useState<string>("");
    const [org_uuid, setOrgUuid] = useState<string>(default_org_uuid);
    const [headers, setHeaders] = useState<string[]>([""]);
    const [new_header, setNewHeader] = useState<string | undefined>(undefined);
    const [header_refs, setHeaderRefs] = useState<RefObject<HTMLTableCellElement>[]>([]);
    const [current_header_idx, setCurrentHeaderIdx] = useState<number>(-1);
    const [sheets, setSheets] = useState<IExcelArraySheet[] | undefined>(undefined);
    const [selected_sheet_idx, setSelectedSheetIdx] = useState<number>(-1);
    const [selected_sheet, setSelectedSheet] = useState<IExcelArraySheet | undefined>(undefined);
    const [is_saving, setIsSaving] = useState<boolean>(false);

    const is_edit = lookup_table_uuid !== undefined;

    useEffect(() => {
        if (is_edit) {
            setIsInit(false);
            Backend.getLookupTable({ lookup_table_uuid }).then((lookup_table) => {
                setFilename(undefined);
                setName(lookup_table?.name || "");
                setOrgUuid(lookup_table?.org_uuid || default_org_uuid);
                setHeaders(lookup_table?.headers || []);
                setNewHeader(undefined);
                setCurrentHeaderIdx(-1);
                setSheets(undefined);
                setSelectedSheetIdx(-1);
                setSelectedSheet(undefined);
                setIsSaving(false);
                setIsInit(true);
            });
        } else {
            // reset state
            setIsInit(true);
            setFilename(undefined);
            setName("");
            setOrgUuid(default_org_uuid);
            setHeaders([""]);
            setNewHeader(undefined);
            setCurrentHeaderIdx(-1);
            setSheets(undefined);
            setSelectedSheetIdx(-1);
            setSelectedSheet(undefined);
            setIsSaving(false);
        }
    }, [lookup_table_uuid, default_org_uuid, is_edit]);

    useEffect(() => {
        setHeaderRefs(headers.map(() => createRef()));
    }, [headers, headers.length]);

    useEffect(() => {
        if (current_header_idx !== -1 && header_refs[current_header_idx]) {
            const element = header_refs[current_header_idx]?.current;
            if (element) {
                element.focus();
                window.getSelection()?.selectAllChildren(element);
                window.getSelection()?.collapseToEnd();
            }
        }
    }, [headers, headers.length, header_refs, header_refs.length, current_header_idx]);

    useEffect(() => {
        if (lookup_table_uuid === undefined) {
            setDocumentTitle("Create Lookup Table", env);
        } else {
            setDocumentTitle(`Edit Lookup Table - ${name}`, env);
        }
    }, [lookup_table_uuid, name, env]);

    useEffect(() => {
        if (name.length === 0 && filename !== undefined) {
            setName(filename);
        }
    }, [filename, name]);

    useEffect(() => {
        if (sheets && selected_sheet_idx !== -1) {
            setSelectedSheet(sheets[selected_sheet_idx]);
        } else {
            setSelectedSheet(undefined);
        }
    }, [sheets, selected_sheet_idx]);

    const handleParse = (filename: string, sheets: IExcelArraySheet[]) => {
        setFilename(filename);
        setSheets(sheets);
        if (!is_edit) {
            setHeaders(sheets[0].data[0] || []);
        }
        setSelectedSheetIdx(0);
    };

    const handleBlur = (e: any, idx: number) => {
        setCurrentHeaderIdx(-1);
        const new_headers = [...headers];
        if (new_header !== undefined && new_headers[idx] !== new_header) {
            new_headers[idx] = new_header;
            setNewHeader(undefined);
        }
        setHeaders(new_headers);
    };

    const handleInput = (e: any, idx: number) => {
        setNewHeader(e.target.innerText.trim());
    };

    const handleKeyDown = (e: any, idx: number) => {
        if (idx === headers.length - 1) {
            if (e.key === "Tab" && !e.shiftKey) {
                e.preventDefault();
                addHeader();
            } else if (e.key === "Enter") {
                e.preventDefault();
                addHeader();
            }
        }
    }

    const addHeader = () => {
        const new_header = [...headers, ""];
        setHeaders(new_header);
        setCurrentHeaderIdx(new_header.length - 1);
    }

    const deleteHeader = (idx: number) => {
        const new_headers = [...headers];
        new_headers.splice(idx, 1);
        setHeaders(new_headers);
    }

    const handleFocus = (e: any, idx: number) => {
        setCurrentHeaderIdx(idx);
    }

    const handleCreate = () => {
        if (!is_edit) {
            setIsSaving(true);
            Backend.createLookupTable(
                { org_uuid, name, headers, sheet: selected_sheet }
            ).then(({ lookup_table_uuid }) => {
                navigate(`/endpoints/${lookup_table_uuid}`);
            });
        }
    };

    const handleUpdate = () => {
        if (is_edit && lookup_table_uuid !== undefined) {
            setIsSaving(true);
            Backend.updateLookupTable(
                { lookup_table_uuid, org_uuid, name, headers }
            ).then(() => {
                navigate(`/endpoints/${lookup_table_uuid}`);
            });
        }
    };

    const can_create = name.length > 0 && org_uuid.length > 0 && !is_saving;

    if (is_edit && !is_init) {
        return <div className={classNames("hidden lg:fixed lg:right-0 lg:inset-y-0 lg:flex lg:flex-row", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
            <LoadingSpinner />
        </div>;
    }

    return <div className={classNames("w-full lg:fixed lg:right-0 lg:inset-y-0 overflow-y-auto", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
        <div className={classNames("h-16 bg-white border-b-gray-200 border-b lg:fixed lg:right-0", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
            <div className="container px-10 py-4 max-w-5xl">
                <div className="flex flex-row items-center">
                    <h2 className="text-xl font-semibold leading-7 text-gray-900 sm:truncate sm:text-2xl sm:tracking-tight">
                        {!is_edit && "Create Lookup Table"}
                        {is_edit && "Edit Lookup Table"}
                    </h2>
                    <div className="flex-grow" />
                    {!is_edit && <Button
                        text="Create"
                        highlight={true}
                        disabled={!can_create}
                        loading={is_saving}
                        onClick={() => handleCreate()} />}
                    {is_edit && <Button
                        text="Save Changes"
                        highlight={true}
                        disabled={!can_create}
                        loading={is_saving}
                        onClick={() => handleUpdate()} />}
                </div>
            </div>
        </div>

        <div className="flex flex-col w-full py-6 lg:pt-24">
            <div className="px-10 max-w-5xl">
                <div className="grid grid-cols-4 items-center w-full">
                    <div className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">Name</div>
                    <div className="col-span-3">
                        <input
                            type="text"
                            value={name}
                            className="border border-gray-300 rounded-md w-full px-3 py-2 text-gray-900"
                            onChange={(e) => setName(e.target.value)} />
                    </div>
                </div>
                <div className="mt-5 grid grid-cols-4 items-center w-full">
                    <div className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">Organization</div>
                    <div className="col-span-3 flex flex-row">
                        {admin_orgs.map((org, idx) => <div key={idx} className="pr-2"><OrgPill
                            key={org.uuid}
                            name={org.name}
                            type={org.type}
                            selected={org.uuid === org_uuid}
                            onClick={() => setOrgUuid(org.uuid)} /></div>)}
                    </div>
                </div>
                <div className="mt-5 pb-10 flow flow-col items-center w-full border-b border-gray-100">
                    <div className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">Table Headers</div>
                    <div className="mt-5">
                        <table className="py-4 text-xs md:text-base">
                            <thead>
                                <tr>
                                    {headers.map((_header, idx) => <th key={idx}
                                        className="py-1 px-4 bg-gray-50 border border-gray-300 text-gray-900 text-xs font-normal align-top w-32 focus:ring-1 focus:ring-sky-500 min-w-[150px] max-w-[300px]"
                                        colSpan={2}>
                                        {getExcelColumnName(idx)}
                                    </th>)}
                                </tr>
                                <tr>
                                    {headers.map((header, idx) => <Fragment key={idx}>
                                        <th
                                            className={classNames(
                                                "py-1 px-4 bg-gray-100 border border-gray-300 cursor-text hover:bg-sky-100 text-left text-sm font-normal align-top w-32 focus:ring-1 focus:ring-sky-500 min-w-[150px] max-w-[300px]",
                                                header.length === 0 && current_header_idx !== idx ? "text-gray-400" : "text-gray-900",
                                            )}
                                            contentEditable={true}
                                            onBlur={e => handleBlur(e, idx)}
                                            onInput={e => handleInput(e, idx)}
                                            onFocus={e => handleFocus(e, idx)}
                                            onKeyDown={e => handleKeyDown(e, idx)}
                                            ref={header_refs[idx]}
                                            dangerouslySetInnerHTML={{ __html: header.length === 0 && current_header_idx !== idx ? "click to edit" : header }}
                                        />
                                        <th key={idx + headers.length}
                                            className="py-1 px-2 bg-gray-100 text-gray-400 border border-gray-300 cursor-pointer hover:bg-sky-300 hover:text-white w-4"
                                            onClick={() => { deleteHeader(idx); }}
                                        >
                                            <MinusIcon className="h-4 w-4 " />
                                        </th>
                                    </Fragment>)}
                                    <th className="py-1 px-4 bg-gray-50 hover:bg-sky-300 border border-gray-300 cursor-pointer" onClick={addHeader}>+</th>
                                </tr>
                            </thead>
                        </table>
                    </div>
                </div>
            </div>
            {!is_edit && <div className="px-10 pt-10 pb-5 max-w-5xl">
                <div className="flex flex-row items-center w-full">
                    <div className="flex-1 flex flex-col">
                        <div className="text-sm font-medium leading-6 text-gray-900">
                            Upload Initial Data
                        </div>
                        <div className="text-sm text-gray-500">
                            You can initialize the lookup table with data from an Excel file.
                        </div>
                    </div>
                </div>
                {sheets === undefined && <div className="mt-3"><LookupTableDropArea setSheets={handleParse} /></div>}
                {sheets !== undefined && <div className="mt-3 grid grid-cols-4 items-center w-full">
                    <div className="text-sm text-gray-500">Select Sheet</div>
                    <div className="col-span-3">
                        <Dropdown
                            values={sheets.map((sheet) => sheet.name)}
                            ids={sheets.map((_, idx) => `${idx}`)}
                            selected={`${selected_sheet_idx}`}
                            onChange={(idx) => setSelectedSheetIdx(parseInt(idx, 10))} />
                    </div>
                </div>}
                {sheets !== undefined && selected_sheet && <div className="pb-5 w-full">
                    <div className="outer-div pt-5">
                        <Sheet data={selected_sheet.data} show_header={true} />
                    </div>
                </div>}
            </div>}
        </div>
    </div>;
}
