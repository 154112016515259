import {
    useEffect,
    useState
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { Backend, BackendObj } from "../lib/backend";
import { ButtonStripeSubscribe } from "../components/ButtonStripeSubscribe";
import { selectIsSidebarLarge } from "../lib/scraper.slice";
import { classNames } from "../lib/utils";
import { CheckIcon } from "@heroicons/react/24/outline";
import { LoadingSpinnerLimit } from "../components/LoadingSpinner";

////////////////////////////////////////////////////////////////////////////////////////////
// This screen is used to show available subscription tiers for new subscriber.
// when user selects one, it will redirect to Stripe to handle the payment process.
////////////////////////////////////////////////////////////////////////////////////////////

interface ITier {
    name: string;
    id: string;
    price: string;
    unit?: string;
    description: string;
    features: string[];
    featured: boolean;
    cta: string;
    href?: string;
    lookup_key?: string;
}

const professional_tier: ITier = {
    name: "Professional",
    id: "tier-professional",
    price: "$[[price]]",
    unit: "/month",
    description: "Rapid results and ease of use, perfect for individuals and small teams.",
    features: [
        "[[credits]] credits for extraction per month",
        "Up to 20 templates",
        "Extraction from text, PDFs, Excel files, and more",
        "Easy integration via email",
        "Connect into workflows using webhooks",
        "Can top up credits at any time",
        "Email support"
    ],
    featured: false,
    cta: "Buy plan",
};

const professional_extra_credits_tier: ITier = {
    name: "Top-up with [[credits]] credits",
    id: "tier-professional-extra-credits",
    price: "$[[price]]",
    description: "Need more credits? Purchase a one-time pack of [[credits]] credits to keep your workflow going.",
    features: [
        "[[credits]] credits for extraction",
        "Credits never expire"
    ],
    featured: false,
    cta: "Buy Credits",
};

const enterprise_tier: ITier = {
    name: "Enterprise",
    id: "tier-enterprise",
    href: "https://meetings-eu1.hubspot.com/tomaz/book-a-demo",
    price: "Custom",
    description: "Robust security, comprehensive data privacy, and the flexibility to scale with your needs.",
    features: [
        "Tailored number of credits for extraction per month",
        "Unlimited number of templates",
        "Extraction from text, PDFs, Excel files, and more",
        "Easy integration via email",
        "Connect into workflows using webhooks",
        "Can top up credits at any time",
        "Dedicated support team",
        "Sharing templates with team members",
        "Rest APIs for custom integrations",
        "Azure managed application",
        "Custom SLA"
    ],
    featured: true,
    cta: "Contact sales",
}

function injectStripeDetails(tier: ITier, price: number, credits: number, lookup_key: string): ITier {
    return {
        ...tier,
        price: tier.price.replace("[[price]]", (price - Math.round(price) === 0) ? price.toString() : price.toFixed(2)),
        name: tier.name.replace("[[credits]]", credits.toString()),
        description: tier.description.replace("[[credits]]", credits.toString()),
        features: tier.features.map((feature) => feature.replace("[[credits]]", credits.toString())),
        lookup_key: lookup_key
    };
}

export function StripeSubscribe() {
    const navigate = useNavigate();

    const { org_uuid } = useParams<{ org_uuid: string }>();

    const is_sidebar_large = useSelector(selectIsSidebarLarge);

    const [tiers, setTiers] = useState<ITier[] | undefined>(undefined);
    const [has_subscription, setHasSubscription] = useState<boolean | undefined>(undefined);

    // internal async method for getting all data
    const getData = async (org_uuid_local: string) => {
        const subscriptions = await Backend.getUserSubscriptions();
        const stripe_options = await BackendObj.stripe.getStripeOptions({ org_uuid: org_uuid_local });
        return { subscriptions, stripe_options };
    }

    useEffect(() => {
        if (org_uuid === undefined) {
            return;
        }

        getData(org_uuid).then(res => {
            const existing_subscriptions = res.subscriptions
                .filter(x => x.org.uuid === org_uuid && x.subscription !== undefined);
            const new_tiers: ITier[] = [];
            if (existing_subscriptions.length === 0) {
                // no existing subscription, we show the plans
                if (res.stripe_options.tier1.lookup_key !== "ERROR") {
                    new_tiers.push(injectStripeDetails(
                        professional_tier,
                        res.stripe_options.tier1.price,
                        res.stripe_options.tier1.credits,
                        res.stripe_options.tier1.lookup_key
                    ));
                }
                if (res.stripe_options.tier2.lookup_key !== "ERROR") {
                    console.error("Stripe has tier 2 plan but not implemented");
                }
                new_tiers.push(enterprise_tier);
                setHasSubscription(true);
            } else {
                // existing subscription, show option to buy more credits
                const subscription = existing_subscriptions[0].subscription;
                if (subscription?.type === "tier1") {
                    new_tiers.push(injectStripeDetails(
                        professional_extra_credits_tier,
                        res.stripe_options.tier1.onetime_price,
                        res.stripe_options.tier1.onetime_credits,
                        res.stripe_options.tier1.onetime_lookup_key
                    ));
                }
                // user can also contact us for upgrade!
                new_tiers.push(enterprise_tier);
                setHasSubscription(false);
            }
            setTiers(new_tiers);
        }).catch(err => {
            console.error(err);
            navigate("/");
        });
    }, [org_uuid, navigate]);

    // we need org, if not available redirect to home
    if (org_uuid === undefined) {
        navigate("/");
    }

    return (
        <div className={classNames("flex-row lg:fixed lg:right-0 lg:inset-y-0 overflow-y-auto", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
            <div className="mx-16 mt-16 mb-12 max-w-6xl">
                <h2 className="text-xl font-semibold leading-7 text-gray-600 sm:truncate sm:text-3xl sm:tracking-tight">
                    Upgrade your account
                </h2>

                <div className="pt-5 border-b-4 border-sky-600" />
            </div>
            {tiers === undefined && <div className="max-w-6xl mx-10 my-20"><LoadingSpinnerLimit /></div>}
            {tiers !== undefined && <div className="max-w-6xl mx-10">
                {has_subscription === true && <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600">
                    Find the perfect fit for your needs with our tailored pricing plans, from individual users to large enterprises.
                </p>}
                {has_subscription === false && <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600">
                    Your subscription is active, but you can always top up your credits or contact us to upgrade your plan.
                </p>}
                <div className="isolate mx-auto mt-12 grid max-w-md grid-cols-1 gap-12 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                    {tiers.map((tier) => (
                        <div key={tier.id} className={classNames(tier.featured ? "bg-gray-900 ring-gray-900" : "ring-gray-200", "rounded-3xl p-8 ring-1 xl:p-10")}>
                            <h3 id={tier.id} className={classNames(tier.featured ? "text-white" : "text-gray-900", "text-lg font-semibold leading-8")} >
                                {tier.name}
                            </h3>
                            <p className={classNames(tier.featured ? "text-gray-300" : "text-gray-600", "mt-4 text-sm leading-6 h-16")}>
                                {tier.description}
                            </p>
                            <p className="mt-6 flex items-baseline gap-x-1">
                                <span className={classNames(tier.featured ? "text-white" : "text-gray-900", "text-4xl font-bold tracking-tight")}>
                                    {tier.price}
                                </span>
                                {tier.unit && <span className={classNames(tier.featured ? "text-gray-300" : "text-gray-600", "text-sm font-semibold leading-6")}>
                                    {tier.unit}
                                </span>}
                            </p>
                            {tier.lookup_key && <ButtonStripeSubscribe lookup_key={tier.lookup_key} org_uuid={org_uuid || ""} text={tier.cta} subscribe_page={true} />}
                            {tier.href && <a href={tier.href} aria-describedby={tier.id} className={classNames(
                                tier.featured
                                    ? "bg-white/10 text-white hover:bg-white/20 focus-visible:outline-white"
                                    : "bg-sky-600 text-white shadow-sm hover:bg-sky-500 focus-visible:outline-sky-600",
                                "mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                            )}>
                                {tier.cta}
                            </a>}
                            <ul className={classNames(tier.featured ? "text-gray-300" : "text-gray-600", "mt-8 space-y-3 text-sm leading-6 xl:mt-10")}>
                                {tier.features.map((feature) => (
                                    <li key={feature} className="flex gap-x-3">
                                        <CheckIcon className={classNames(tier.featured ? "text-white" : "text-sky-600", "h-6 w-5 flex-none")} aria-hidden="true" />
                                        {feature}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>
            </div>}
        </div>
    )
};
