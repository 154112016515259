import {
    useEffect,
    useState
} from "react";
import { useSelector } from "react-redux";

import * as hi from "@heroicons/react/24/outline";
import * as xlsx from "xlsx";
import { saveAs } from "file-saver";

import * as t from "../lib/types";
import { Backend } from "../lib/backend";
import {
    classNames,
    escapeCsvField,
    getNextOnboardingStep,
    prepareExcelSheet,
    prettyDateTime
} from "../lib/utils";
import { selectIsSidebarLarge } from "../lib/scraper.slice";

import { LoadingSpinner } from "../components/LoadingSpinner";
import { EmptyItemList } from "./Items";
import { ONBOARDING_STEPS } from "../lib/consts";

type DownloadProps = {
    cols_lg: number;
    skip_empty_list: boolean;
    max_items?: number;
    onDownload?: () => void;
}

export function Download(props: DownloadProps) {
    const { cols_lg, skip_empty_list, max_items, onDownload } = props;

    const is_sidebar_large = useSelector(selectIsSidebarLarge);

    const [download_info, setDownloadInfo] = useState<t.IContextDownloadInfo[] | undefined>(undefined);
    const [is_downloading, setIsDownloading] = useState<string | undefined>();

    useEffect(() => {
        Backend.downloadContexts().then((contexts) => {
            if (max_items !== undefined) {
                contexts = contexts.slice(0, max_items);
            }
            setDownloadInfo(contexts);
        });
    }, [max_items]);

    const download_excel = (context_uuid: string) => {
        setIsDownloading(context_uuid + "excel");
        Backend.downloadContext({ context_uuid }).then((context): void => {
            // prepare records
            const context_name = context.context.name;
            const records = context.data.map((d, pos) => d.scrape.records.map(({ val }) => ({ pos: pos + 1, name: d.item.name, val }))).flat();
            // create workbook
            const workbook = xlsx.utils.book_new();
            // transform to excel sheet
            const sheet = prepareExcelSheet(context_name, context.context.fields, records, true);
            const worksheet = xlsx.utils.aoa_to_sheet(sheet.data);
            xlsx.utils.book_append_sheet(workbook, worksheet, sheet.name);
            // save workbook
            const blob = new Blob([xlsx.write(workbook, { bookType: "xlsx", type: "array" })], { type: "application/octet-stream" });
            saveAs(blob, `${context_name}.xlsx`);
            setIsDownloading(undefined);
            // update onboarding status
            Backend.updateOnboardingStep({
                step: `${getNextOnboardingStep(ONBOARDING_STEPS.download)}`
            }).then(() => {
                if (onDownload !== undefined) { onDownload(); }
            });
        });
    };

    const download_csv = (context_uuid: string) => {
        setIsDownloading(context_uuid + "csv");
        Backend.downloadContext({ context_uuid }).then((context) => {
            // prepare records
            const context_name = context.context.name;
            const records = context.data.map((d, pos) => d.scrape.records.map(({ val }) => ({ pos: pos + 1, name: d.item.name, val }))).flat();
            const sheet = prepareExcelSheet(context_name, context.context.fields, records, true);
            const csv = sheet.data.map((row) => row.map(escapeCsvField).join(",")).join("\n");
            const blob = new Blob([csv], { type: "text/csv" });
            saveAs(blob, `${context_name}.csv`);
            setIsDownloading(undefined);
            // update onboarding status
            Backend.updateOnboardingStep({
                step: `${getNextOnboardingStep(ONBOARDING_STEPS.download)}`
            }).then(() => {
                if (onDownload !== undefined) { onDownload(); }
            });
        });
    }

    const download_json = (context_uuid: string) => {
        setIsDownloading(context_uuid + "json");
        Backend.downloadContext({ context_uuid }).then((context) => {
            // prepare records
            const context_name = context.context.name;
            const records = context.data.map((d) => d.scrape.records.map(({ val: r }) => ({ $uuid: d.scrape.uuid, $name: d.item.name, ...r }))).flat();
            const dataStr = JSON.stringify(records, null, 2);
            const dataUri = 'data:application/json;charset=utf-8,' + encodeURIComponent(dataStr);

            let downloadAnchorNode = document.createElement('a');
            downloadAnchorNode.setAttribute("href", dataUri);
            downloadAnchorNode.setAttribute("download", `${context_name}.json`);
            document.body.appendChild(downloadAnchorNode);
            downloadAnchorNode.click();
            downloadAnchorNode.remove();
            setIsDownloading(undefined);
            // update onboarding status
            Backend.updateOnboardingStep({
                step: `${getNextOnboardingStep(ONBOARDING_STEPS.download)}`
            }).then(() => {
                if (onDownload !== undefined) { onDownload(); }
            });
        });
    }

    if (download_info === undefined) {
        return <div className={classNames("hidden lg:fixed lg:right-0 lg:inset-y-0 lg:flex lg:flex-row", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
            <LoadingSpinner />
        </div>;
    }

    if (download_info.length === 0 && !skip_empty_list) {
        return <EmptyItemList />;
    }

    const is_download_disabled = (is_downloading !== undefined);

    return <ul className={`grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-${cols_lg} max-w-6xl`}>
        {download_info.map((info, idx) => (
            <li key={idx} className="col-span-1 divide-y divide-gray-200 rounded-lg shadow bg-zinc-100 border-gray-200 border">
                <div className="flex w-full items-center justify-between space-x-6 p-6">
                    <div className="flex-1 truncate">
                        <div className="flex items-center space-x-3">
                            <h3 className="truncate text-sm font-medium text-gray-900">{info.context_name}</h3>
                        </div>
                        <div className="flex flex-row mt-1">
                            <div className="flex-1 truncate text-xs text-gray-500">
                                {info.no_extractions} {info.no_extractions !== 1 ? "extractions" : "extraction"}
                            </div>
                            <div className="flex truncate text-xs text-gray-500 text-right">{prettyDateTime(info.last_extraction_ts)}</div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="-mt-px flex divide-x divide-gray-200">
                        <div className="flex w-0 flex-1">
                            <button
                                onClick={() => download_excel(info.context_uuid)}
                                disabled={is_download_disabled}
                                className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900 hover:bg-zinc-100"
                            >
                                {is_downloading === (info.context_uuid + "excel") ?
                                    <i className="fas fa-spinner fa-spin text-gray-400" /> :
                                    <hi.TableCellsIcon className="h-5 w-5 text-sky-300" aria-hidden="true" />}
                                Excel
                            </button>
                        </div>
                        <div className="-ml-px flex w-0 flex-1">
                            <button
                                onClick={() => download_csv(info.context_uuid)}
                                disabled={is_download_disabled}
                                className={classNames(
                                    "relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900 hover:bg-zinc-100",
                                    is_download_disabled ? "cursor-not-allowed" : ""
                                )}
                            >
                                {is_downloading === (info.context_uuid + "json") ?
                                    <i className="fas fa-spinner fa-spin text-gray-400" /> :
                                    <span className="text-sky-300">{"A,1"}</span>
                                }
                                CSV
                            </button>
                        </div>
                        <div className="-ml-px flex w-0 flex-1">
                            <button
                                onClick={() => download_json(info.context_uuid)}
                                disabled={is_download_disabled}
                                className={classNames(
                                    "relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900 hover:bg-zinc-100",
                                    is_download_disabled ? "cursor-not-allowed" : ""
                                )}
                            >
                                {is_downloading === (info.context_uuid + "json") ?
                                    <i className="fas fa-spinner fa-spin text-gray-400" /> :
                                    <span className="text-sky-300">{"{ : }"}</span>
                                }
                                JSON
                            </button>
                        </div>
                    </div>
                </div>
            </li>
        ))}
    </ul>;
}

export function DownloadWrapper() {
    const is_sidebar_large = useSelector(selectIsSidebarLarge);

    return <div className={classNames("flex-row lg:fixed lg:right-0 lg:inset-y-0 overflow-y-auto m-10", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
        <div className="pb-10">
            <h2 className="text-xl font-semibold leading-7 text-gray-600 sm:truncate sm:text-3xl sm:tracking-tight">
                Download extractions
            </h2>
        </div>
        <Download cols_lg={3} skip_empty_list={false} />
    </div>;
}
